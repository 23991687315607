import { uuid } from 'uuidv4';
import { sortBy } from 'lodash';

import {
    FLORIDA,
    ILLINOIS,
    OHIO,
    FL_BOOLEAN_QUALIFICATION_PROBLEM_CHOICE_IDS,
    OH_SINGLE_QUALIFICATION_PROBLEM_CHOICE_IDS,
} from '../constants';

import type { IManagedScale, IPayScale, Qualification } from '../types';

export function loadInitialScales(defaultPayScales: IPayScale[], payScales: IPayScale[]): IManagedScale[] {
    const emptyPayScales =
        !payScales?.length || payScales.every((payScale: IPayScale) => !payScale.qualifications.length);

    if (defaultPayScales.length > 0 && emptyPayScales) {
        const scales = sortBy(
            defaultPayScales.map((x: IPayScale) => ({ ...x, id: uuid() })),
            'rate',
        );

        return scales;
    }

    if (!emptyPayScales) {
        return sortBy(
            payScales.map((x: IPayScale) => ({ ...x, id: uuid() })),
            'rate',
        );
    }

    return [
        {
            id: uuid(),
            description: '13',
            rate: 13,
            qualifications: [],
        },
    ];
}

export function detectState(qualifications: Qualification[]) {
    const ids = qualifications.map((qualification: Qualification) => qualification.id);

    if (FL_BOOLEAN_QUALIFICATION_PROBLEM_CHOICE_IDS.some((id: number) => ids.includes(id))) {
        return FLORIDA;
    } else if (OH_SINGLE_QUALIFICATION_PROBLEM_CHOICE_IDS.some((id: number) => ids.includes(id))) {
        return OHIO;
    }

    return ILLINOIS;
}
