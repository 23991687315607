import React from 'react';
import { styled } from '@mui/material';

import { CredentialPill } from '../components/CredentialPill';

import type { GenericChoice, Pills, Qualification } from '../types';

export function AdditionalPills({ pills }: { pills: Pills }) {
    function multiOptions(pill: { qualification: Qualification; choices: GenericChoice[] }) {
        const { qualification, choices } = pill;
        return `${qualification.name}: ${choices.map((choice) => choice.value).join(', ')}`;
    }

    return (
        <Box>
            {pills.multi.map((pill: { qualification: Qualification; choices: GenericChoice[] }) => (
                <CredentialPill key={pill.qualification.id} label={multiOptions(pill)} selected={true} />
            ))}
            {pills.single.map((pill: { qualification: Qualification; choice: GenericChoice }) => (
                <CredentialPill
                    key={pill.qualification.id}
                    label={`${pill.qualification.name}: ${pill.choice.value}`}
                    selected={true}
                />
            ))}
            {pills.boolean.map((pill: GenericChoice) => (
                <CredentialPill key={pill.key} label={pill.value} selected={true} />
            ))}
        </Box>
    );
}

const Box = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
});
