import React from 'react';
import { format12HourTime, formatShortWeekdayMonthDay } from 'shared/Dates';
import { ProviderMatch, JobMatch, AppointmentModel } from '../substituteModels';
import AdminTable from 'internal/shared/tables/AdminTable';
import Row from 'internal/shared/tables/Row';
import { TableCell } from '@mui/material';
import GroupAdd from '@mui/icons-material/GroupAdd';
import { Checkbox, Colors, Text } from 'library';
import AdminButton from 'internal/shared/AdminButton';
import { Close, Star } from '@mui/icons-material';
import { markMatchesAsDeclined } from './matchingListApi';
import { adminLog } from 'shared';

const columns = ['appointment', 'expires', 'accepted', 'declined', 'hiring group created', 'select'];
export default function SentMatchesTable({
    matches,
    header,
    userId,
    refresh,
}: {
    matches: ProviderMatch[] | JobMatch[];
    header: React.ReactNode;
    userId?: number;
    refresh: () => void;
}) {
    const [selectedMatches, setSelectedMatches] = React.useState<Set<number>>(new Set());

    function toggleSelect(id: number) {
        if (selectedMatches.has(id)) {
            selectedMatches.delete(id);
            setSelectedMatches(new Set(selectedMatches));
        } else {
            selectedMatches.add(id);
            setSelectedMatches(new Set(selectedMatches));
        }
    }

    function setDeclined() {
        markMatchesAsDeclined(Array.from(selectedMatches)).then(refresh).catch(adminLog);
    }

    function Header() {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderTop: `3px solid ${Colors.darkNavy}`,
                    paddingRight: 10,
                }}
            >
                {header}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <AdminButton text="Decline Selected" color={Colors.aqua} icon={Close} onClick={setDeclined} />
                    <Checkbox
                        style={{ width: undefined }}
                        label="Select All"
                        checked={selectedMatches.size === matches.length}
                        onChange={() => {
                            if (selectedMatches.size === matches.length) {
                                setSelectedMatches(new Set());
                            } else {
                                setSelectedMatches(new Set(matches.map((x) => x.id)));
                            }
                        }}
                    />
                </div>
            </div>
        );
    }

    function getPreferred(preferred: AppointmentModel['preferred_sitters']) {
        const preferredSitter = preferred?.preferred_sitters.find((item) => item.sitter === userId);
        if (preferredSitter && preferredSitter.spotlight) {
            return (
                <Star
                    titleAccess="Spotlight Worker"
                    style={{
                        color: Colors.terraCotta,
                        marginLeft: 8,
                    }}
                />
            );
        } else if (preferredSitter) {
            return (
                <GroupAdd
                    titleAccess="Preferred provider"
                    style={{
                        color: Colors.mediumRed,
                        marginLeft: 8,
                    }}
                />
            );
        }
        return '';
    }

    return (
        <AdminTable columns={columns} header={<Header />}>
            {matches.map((row) => (
                <Row key={row.id}>
                    <TableCell>
                        {formatShortWeekdayMonthDay(row.appointment.start_date)}{' '}
                        {format12HourTime(row.appointment.start_date)} - {format12HourTime(row.appointment.end_date)} (
                        {row.appointment.id}){getPreferred(row.appointment.preferred_sitters)}
                        <Text variant="body2">
                            Created by: {row.created_by?.first_name} {row.created_by?.last_name} ({row.created_by?.id})
                        </Text>
                    </TableCell>
                    <TableCell>
                        {row.expires_at
                            ? `${formatShortWeekdayMonthDay(row.expires_at)} ${format12HourTime(row.expires_at)}`
                            : ''}
                    </TableCell>
                    <TableCell>
                        {row.accepted_at
                            ? `${formatShortWeekdayMonthDay(row.accepted_at)} ${format12HourTime(row.accepted_at)}`
                            : ''}
                    </TableCell>
                    <TableCell>
                        {row.declined_at
                            ? `${formatShortWeekdayMonthDay(row.declined_at)} ${format12HourTime(row.declined_at)}`
                            : ''}
                    </TableCell>
                    <TableCell>
                        {row.hiring_group_opened_at
                            ? `${formatShortWeekdayMonthDay(row.hiring_group_opened_at)} ${format12HourTime(row.hiring_group_opened_at)}`
                            : ''}
                    </TableCell>
                    <TableCell>
                        <Checkbox
                            label=""
                            checked={selectedMatches.has(row.id)}
                            onChange={() => toggleSelect(row.id)}
                        />
                    </TableCell>
                </Row>
            ))}
        </AdminTable>
    );
}
