import { IManagedScale } from '../types';

export function ensureRatesExist(scales: IManagedScale[]): IManagedScale[] {
    return scales.map((scale: IManagedScale, index: number) => {
        if (!scale.rate) {
            scale.rate = index;
        }

        return scale;
    });
}

export function updateScales(
    scale: IManagedScale,
    scales: IManagedScale[],
    setScales: (scales: IManagedScale[]) => void,
    setInitialScale: (scale: IManagedScale) => void,
): IManagedScale[] {
    const updatedScales: IManagedScale[] = scales;

    const index = updatedScales.findIndex((x: IManagedScale) => x.id === scale.id);
    index === -1 ? updatedScales.push(scale) : (updatedScales[`${index}`] = scale);

    setScales(updatedScales);
    setInitialScale(updatedScales[0]);

    return updatedScales;
}

export function deleteScale(
    scaleId: string,
    scales: IManagedScale[],
    setScales: (scales: IManagedScale[]) => void,
    setInitialScale: (scale: IManagedScale) => void,
): IManagedScale[] {
    const updatedScales = scales.filter((x: IManagedScale) => x.id !== scaleId);

    setScales(updatedScales);
    setInitialScale(updatedScales[0]);

    return updatedScales;
}
