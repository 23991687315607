import React, { useState } from 'react';
import { Colors, PrimaryButton, SecondaryButton, Text } from 'library';
import { Grid } from '@material-ui/core';
import { format12HourTime, formatShortWeekdayMonthDay } from 'shared/Dates';
import { approveReport, getHourReportViewStatus } from '../HourReportMethods';
import { consoleLogInDev } from 'shared';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { styled } from '@mui/material';
import DidNotWorkButton from './DidNotWorkButton';
import { IHourReportView } from 'internal/payments/HourReportModels';

const ActionRequired = () => {
    const Container = styled('div')({
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        marginTop: '16px',
    });

    const ExclamationIcon = styled(PriorityHighIcon)({
        borderRadius: '50%',
        backgroundColor: Colors.terraCotta,
        color: Colors.white,
        fontSize: 'large',
        padding: '2px',
    });

    return (
        <Container>
            <ExclamationIcon />
            <Text bold variant="body2" textStyle={{ color: Colors.terraCotta }}>
                Action Required
            </Text>
        </Container>
    );
};

export default function Report({
    report,
    setChangeReport,
    updateHoursReported,
}: {
    report: IHourReportView;
    setChangeReport: (reportId: number) => void;
    updateHoursReported: () => void;
}) {
    const [loading, setLoading] = useState(false);

    function approveAction() {
        setLoading(true);
        approveReport(report.id, { business_approved_date: new Date() })
            .catch(consoleLogInDev)
            .finally(() => {
                updateHoursReported();
                setLoading(false);
            });
    }

    const status = getHourReportViewStatus(report);

    return (
        <Grid
            direction="column"
            container
            item
            justify="space-between"
            style={{
                borderRadius: 18,
                padding: 20,
                margin: 10,
                backgroundColor: Colors.white,
                boxShadow: '0px 3px 6px 0px #00000029',
                gap: 10,
            }}
        >
            <Text bold>
                {formatShortWeekdayMonthDay(report.shift_start)} - {report.provider.user.first_name}{' '}
                {report.provider.user.last_name}
            </Text>
            <Grid container item justify="space-between">
                {status !== 'Late Cancel' && (
                    <>
                        <Text>
                            {format12HourTime(report.shift_start, report.timezone)} -{' '}
                            {report.shift_end ? format12HourTime(report.shift_end, report.timezone, true) : ''}
                        </Text>

                        <Text>Break: {report.approved_break ? `${report.approved_break} minutes` : 'No Break'}</Text>
                    </>
                )}
                <Text>Total Hours: {report.hours_worked}</Text>
            </Grid>
            <Grid container item direction="row" style={{ marginTop: 10 }}>
                {status === 'Awaiting Business' || status === 'Disputed' ? (
                    <SecondaryButton
                        disabled={loading}
                        onClick={() => {
                            setChangeReport(report.id);
                        }}
                        buttonStyle={{ margin: 5, width: 160 }}
                    >
                        Adjust Times
                    </SecondaryButton>
                ) : null}{' '}
                {status === 'Awaiting Business' ? (
                    <>
                        <PrimaryButton
                            disabled={loading}
                            onClick={approveAction}
                            buttonStyle={{ margin: 5, width: 160 }}
                        >
                            Approve
                        </PrimaryButton>
                        {report.manual_reason ? <ActionRequired /> : null}
                        {!report.is_late_cancel ? (
                            <DidNotWorkButton
                                name={report.provider.user.first_name}
                                hourReportId={report.id}
                                onComplete={updateHoursReported}
                            />
                        ) : null}
                    </>
                ) : (
                    <Text textStyle={{ width: '100%' }}>
                        <Text bold>Status:</Text> {status}
                        {status === 'Late Cancel' && (
                            <Text textStyle={{ marginTop: 5 }}>
                                fee for cancelling a filled shift within 2 hours of the start time.
                            </Text>
                        )}
                    </Text>
                )}
            </Grid>
        </Grid>
    );
}
