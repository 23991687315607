import { Grid, TableCell } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { client, consoleLogInDev } from 'shared';
import { PointHistory } from '../types';
import { PagingResult } from 'models';
import { Colors, PrimaryButton, Text } from 'library';
import PaginatedTable from '../../tables/PaginatedAdminTable';
import Row from '../../tables/Row';
import { format } from 'date-fns';
import { useCreate } from '../hooks';
import { NoShowData } from './noShowModels';
import RevertNoShowForm from './RevertNoShowForm';

type PointHistoryProps = {
    userId: number;
    totalPoints: number;
    suspendedBy: string | null;
    ninetyDayProbationCount: number;
};

export default function PointHistoryList({
    userId,
    totalPoints,
    suspendedBy,
    ninetyDayProbationCount,
}: PointHistoryProps) {
    const [page, setPage] = useState(1);
    const [history, setHistory] = useState<PointHistory[]>([]);
    const [data, setData] = useState<PagingResult<PointHistory>>();
    const [loading, setLoading] = useState(false);
    const [revertId, setRevertId] = useState<number | null>(null);
    const [revertNoShowData, setRevertNoShowData] = useState<NoShowData>({
        category: '',
        comment: '',
        additional_action: '',
    });

    const {
        data: createData,
        loading: createLoading,
        error,
        status,
        send,
    } = useCreate({
        revertData: { ...revertNoShowData, point_history_id: revertId },
        options: { sendImmediately: false },
    });

    function getPointHistory() {
        setLoading(true);
        client(`api/point-history/${userId}/?page=${page}`)
            .then((res) => {
                setData(res);
                setHistory(res.results ?? []);
            })
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    }

    useEffect(getPointHistory, [userId, page]);

    useEffect(() => {
        if (!createLoading && status === 200) {
            getPointHistory();
            setRevertId(null);
            setRevertNoShowData({ category: '', comment: '', additional_action: '' });
        }
    }, [createLoading, status]);

    if (!data) return <Text>No History Found</Text>;

    function onSubmit() {
        send({
            body: {
                ...revertNoShowData,
                point_history_id: revertId,
            },
        });
    }

    const columns = ['Date', 'Name', 'Description', 'Value'];

    type HeaderTextArguments = Pick<PointHistoryProps, 'totalPoints' | 'suspendedBy' | 'ninetyDayProbationCount'>;
    function getHeaderText({ totalPoints, suspendedBy, ninetyDayProbationCount }: HeaderTextArguments) {
        const suspendedByText = suspendedBy ? `Suspended by: ${suspendedBy}` : '';
        const probationText =
            ninetyDayProbationCount > 0 ? `Ninety Day Probation Count: ${ninetyDayProbationCount}` : '';
        const totalPointsText = totalPoints ? `Current Points: ${totalPoints}` : 'PROBATION';

        return [totalPointsText, probationText, suspendedByText].filter(Boolean).join(' | ');
    }

    return (
        <Grid container item style={{ marginTop: 20, gap: 20 }}>
            <PaginatedTable
                data={data}
                page={page}
                updatePage={setPage}
                columns={columns}
                loading={loading}
                header={getHeaderText({ totalPoints, suspendedBy, ninetyDayProbationCount })}
            >
                {history.map((h) => (
                    <Row key={h.id} oddRowColor={Colors.lightTurq}>
                        <TableCell>{format(new Date(h.added_at), 'MM/dd/yyyy p')}</TableCell>
                        <TableCell>{h.point_value.name}</TableCell>
                        <TableCell>{h.point_value.description}</TableCell>
                        <TableCell>{h.point_value.value}</TableCell>
                        <TableCell>
                            {h.point_value.name === 'NO_SHOW' && (
                                <>
                                    <PrimaryButton
                                        onClick={() => (revertId === h.id ? setRevertId(null) : setRevertId(h.id))}
                                        buttonStyle={{ width: 'fit-content' }}
                                    >
                                        Revert
                                    </PrimaryButton>
                                    {revertId === h.id && (
                                        <RevertNoShowForm
                                            revertId={revertId}
                                            revertNoShowData={revertNoShowData}
                                            setRevertNoShowData={setRevertNoShowData}
                                            onSubmit={onSubmit}
                                            error={error ? createData?.message : ''}
                                        />
                                    )}
                                </>
                            )}
                        </TableCell>
                    </Row>
                ))}
            </PaginatedTable>
        </Grid>
    );
}
