import React from 'react';
import { BasicDialog, Text } from 'library';

import { AdditionalQualificationsSection } from './AdditionalQualificationsSection';

import type { IManagedScale } from '../types';

export function AdditionalModal({
    open,
    onClose,
    scale,
}: {
    open: boolean;
    onClose: () => void;
    scale: IManagedScale;
}) {
    return (
        <BasicDialog isOpen={open} onClose={onClose} closeButton>
            <Text bold variant="h2" textStyle={{ paddingBottom: 20 }}>
                Add Additional Qualifications
            </Text>
            <AdditionalQualificationsSection scale={scale} />
        </BasicDialog>
    );
}
