import React from 'react';
import { IRequirementOptions } from '../models';
import { Grid } from '@material-ui/core';
import { Checkbox, Colors, Text } from 'library';
import { format, parseISO } from 'date-fns';

export default function ComplianceRequirementSelect({
    requirementOptions,
    updateRequirementOptions,
}: {
    requirementOptions?: IRequirementOptions[];
    updateRequirementOptions: (req_type: string, completed: boolean) => void;
}) {
    if (!requirementOptions || requirementOptions.length == 0) return null;
    const completedRequirements = requirementOptions
        .filter((req) => req.alreadySatisfied)
        .reduce((prev: string[], current) => {
            const expDate = current.expirationDate
                ? `(Exp. ${format(parseISO(current.expirationDate), 'MM/dd/yyyy')})`
                : '';
            return [...prev, `${current.requirement_type} ${expDate}`];
        }, []);
    return (
        <Grid>
            <Text bold textStyle={{ margin: 15 }}>
                Select requirements met by document to upload
            </Text>
            {!!completedRequirements.length && (
                <Text variant="caption" textStyle={{ margin: 15, color: Colors.red }}>
                    Requirements Already Met: {completedRequirements.join(', ')}
                </Text>
            )}
            {requirementOptions.map((req) => (
                <Checkbox
                    key={req.requirement_type}
                    checked={req.completed}
                    label={req.requirement_type}
                    onChange={() => updateRequirementOptions(req.requirement_type, !req.completed)}
                />
            ))}
        </Grid>
    );
}
