import React, { useEffect, useState } from 'react';
import { AdminProvider, BusinessJobWithAppointments, BusinessJobWithUser, IPairing } from 'models';
import ModalPage from 'parent-portal/payments/Components/ConnectPaymentModal/ModalPage';
import { PrimaryButton, Text, OutlinedTextInput, Colors, TextButton } from 'library';
import { Checkbox, Grid, InputAdornment } from '@material-ui/core';
import { ChangeRequest, IAppointment, TimeChange } from './jobChangeModels';
import { getExistingJobChangeRequests, submitChanges } from './jobChangeApi';
import CheckboxLabel from 'reusableComponents/checkboxLabel';
import { client } from 'shared';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format12HourTime, formatMonthYearWith12HourTime } from 'shared/Dates';
import JobTimeChange from './JobTimeChange';
import GenerateAppointments from './GenerateAppointments';
import ProviderOutlawedWarning from 'internal/substitutes/ProviderOutlawedWarning';
import { areIntervalsOverlapping, isBefore, isSameDay } from 'date-fns';
import { JobQualifiedRateDTO, getJobQualifiedRate } from 'api/JobQualifiedRateApi';
import { DailyBusinessApplicantJob } from 'internal/applicants/ApplicantModels';
export default function JobChangeConfirmation({
    provider,
    job,
    onPress,
    viewAdminDetails,
    bookings,
}: {
    provider: AdminProvider;
    job: BusinessJobWithAppointments | BusinessJobWithUser | DailyBusinessApplicantJob;
    onPress: () => void;
    viewAdminDetails?: () => void;
    bookings?: IPairing[];
}) {
    const [jobChange, setJobChange] = useState<ChangeRequest | undefined>();
    const [loading, setLoading] = useState(false);
    const [businessComment, setBusinessComment] = useState('');
    const [removedTimeSlots, setRemovedTimeSlots] = useState<number[]>([]);
    const [generateBefore, setGenerateBefore] = useState<number[]>([]);
    const [generateAfter, setGenerateAfter] = useState<number[]>([]);
    const [appointments, setAppointments] = useState<IAppointment[]>([]);
    const [requestedRate, setRequestedRate] = useState<number>();
    const [notifyBusiness, setNotifyBusiness] = useState(false);
    const [editAllTimes, setEditAllTimes] = useState(true);
    const [qualifiedRate, setQualifiedRate] = useState<JobQualifiedRateDTO>();
    const [noTimeSlotsSelected, setNoTimeSlotsSelected] = useState(false);

    const selectedShifts = jobChange?.time_changes
        .filter((t) => !removedTimeSlots.includes(t.appointment))
        .sort((shift1, shift2) => (isBefore(new Date(shift1.start), new Date(shift2.start)) ? -1 : 1));
    const invalidShiftTimes = selectedShifts?.some((shift) => !isBefore(new Date(shift.start), new Date(shift.end)));
    const overlappingShifts = selectedShifts?.some((shift, index) => {
        const shiftStart = new Date(shift.start);
        const shiftEnd = new Date(shift.end);
        return (
            index < selectedShifts.length - 1 &&
            isBefore(shiftStart, shiftEnd) &&
            areIntervalsOverlapping(
                { start: new Date(shift.start), end: new Date(shift.end) },
                { start: new Date(selectedShifts[index + 1].start), end: new Date(selectedShifts[index + 1].end) },
            )
        );
    });
    const overlappedWithBookedShift = selectedShifts?.some((shift) => {
        return bookings?.some((booking) => {
            const shiftStart = new Date(shift.start);
            const shiftEnd = new Date(shift.end);
            return (
                isBefore(shiftStart, shiftEnd) &&
                areIntervalsOverlapping(
                    { start: shiftStart, end: shiftEnd },
                    { start: new Date(booking.appointment.start_date), end: new Date(booking.appointment.end_date) },
                )
            );
        });
    });
    const matchPayRate = appointments.find((appt) => !!appt.match_pay_rate)?.match_pay_rate;

    useEffect(() => {
        createTimeChanges();
    }, []);

    useEffect(() => {
        const availableSlots = jobChange?.time_changes.length;
        const unSelectedSlots = removedTimeSlots.length;

        setNoTimeSlotsSelected(availableSlots === unSelectedSlots);
    }, [removedTimeSlots, jobChange]);

    function updatePayRate(newRate: number) {
        if (jobChange) {
            setJobChange({ ...jobChange, pay: newRate });
        }
    }

    function updateJobChangeTime(d: Date | MaterialUiPickersDate, index: number, field: keyof TimeChange) {
        if (!jobChange) return;
        const date = d as Date;
        let changes = Array.from(jobChange.time_changes);
        const changed = changes[index as number];
        if (editAllTimes) {
            changes = changes.map((c) => {
                const changeDate = new Date(c[field as keyof TimeChange] as string);
                changeDate.setHours(date.getHours());
                changeDate.setMinutes(date.getMinutes());
                return { ...c, [field]: changeDate.toISOString() };
            });
        } else {
            changes[index as number] = { ...changed, [field]: (d as Date).toISOString() };
        }
        setJobChange({ ...jobChange, time_changes: changes });
    }

    function apptToTimeChange(appt: IAppointment): TimeChange {
        return {
            start: appt.provider_last_minute_arrival || appt.start_date,
            end: appt.end_date,
            approved: false,
            change_request: -1,
            last_hire_date: appt.last_hire_date,
            appointment: appt.id,
        };
    }

    function approveChanges() {
        if (jobChange) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, added_at, last_updated, ...change } = jobChange;
            const activeTimeChanges = change.time_changes.filter(
                (t: TimeChange) => !removedTimeSlots.includes(t.appointment),
            );
            const body = {
                ...change,
                user: provider.user.id,
                time_changes: activeTimeChanges.map((a) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { id, change_request, added_at, last_updated, ...timeChange } = a;
                    timeChange.start = new Date(a.start).toISOString();
                    timeChange.end = new Date(a.end).toISOString();
                    return timeChange;
                }),
                generate_remaining_before: activeTimeChanges
                    .filter((t: TimeChange) => generateBefore.includes(t.appointment))
                    .map((t) => t.appointment),
                generate_remaining_after: activeTimeChanges
                    .filter((t: TimeChange) => generateAfter.includes(t.appointment))
                    .map((t) => t.appointment),
                comment: businessComment,
                ongoing_request: job.id,
                business_confirmed: true,
                notify_business: notifyBusiness,
            };
            setLoading(true);
            submitChanges(body)
                .then(() => setLoading(false))
                .finally(onPress);
        }
    }

    function populateDefaultRemovedShifts(appts: IAppointment[]) {
        setRemovedTimeSlots(appts.filter((a) => !a.applied).reduce((p: number[], c: IAppointment) => [...p, c.id], []));
    }

    async function createTimeChanges() {
        const appts: IAppointment[] = await client(
            `api/appointment/${job.id}/appointments-by-job/?provider_user_id=${provider.user.id}`,
        );
        const qualifiedRateResponse = await getJobQualifiedRate(job.id, provider.user.id);
        setQualifiedRate(qualifiedRateResponse);
        setAppointments(appts);
        let change: ChangeRequest = await getExistingJobChangeRequests(job.id, provider.user.id);

        if (!change) {
            change = {
                id: -1,
                time_changes: [],
                pay: 0,
                comment: '',
                bring_child: false,
                approved: false,
                ongoing_request: job.id,
            };
        }

        const timeChanges: TimeChange[] = appts.reduce((p: TimeChange[], c: IAppointment) => {
            let existingTimeChange = change && change.time_changes.find((t) => t.appointment === c.id);
            if (!existingTimeChange) {
                existingTimeChange = change.time_changes.find(
                    (t) =>
                        !appts.some((appt: IAppointment) => appt.id === t.appointment) &&
                        isSameDay(new Date(t.start), new Date(c.start_date)),
                );
            }
            const timeToAdd: TimeChange = existingTimeChange
                ? {
                      ...existingTimeChange,
                      start: c.provider_last_minute_arrival || existingTimeChange.start,
                      appointment: c.id,
                  }
                : apptToTimeChange(c);

            if (existingTimeChange && !c.applied) {
                c.applied = true;
            }
            return [...p, timeToAdd];
        }, []);

        populateDefaultRemovedShifts(appts);

        timeChanges.sort((a: TimeChange, b: TimeChange) => (new Date(a.start) > new Date(b.start) ? 1 : -1));
        change.time_changes = timeChanges;

        if (change.pay) {
            setRequestedRate(change.pay);
        }

        change.pay =
            qualifiedRateResponse.qualified_rate ?? qualifiedRateResponse.provider_rate ?? job.rate_min ?? job.pay;

        setJobChange(change);
    }

    if (!jobChange) {
        return <></>;
    }

    function headerText() {
        if (viewAdminDetails) {
            provider = provider as AdminProvider;
            return (
                <Grid style={{ paddingLeft: 30 }}>
                    <TextButton onClick={viewAdminDetails}>
                        Confirm job details for {provider.user.first_name} {provider.user.last_name} ({provider.user.id}
                        ).
                    </TextButton>
                    <ProviderOutlawedWarning
                        business_locations_outlawed={provider.business_locations_outlawed ?? []}
                        businesses_outlawed={provider.businesses_outlawed}
                        business_id={'address' in job ? job.address.business_location?.business : job.business[0].id}
                        business_location_id={
                            'address' in job ? job.address.business_location?.id : job.business_location?.id
                        }
                    />
                </Grid>
            );
        }
        return `Confirm job details for ${provider.user.first_name}.`;
    }

    function getErrorText() {
        if (!jobChange?.pay) {
            return 'No job change pay';
        }

        if (overlappingShifts) {
            return 'Overlapping shifts chosen';
        }
        if (overlappedWithBookedShift) {
            return 'Overlap with booked shift';
        }
        if (invalidShiftTimes) {
            return 'Shift times are invalid';
        }
        if (noTimeSlotsSelected) {
            return 'No time slots selected';
        }

        return '';
    }

    return (
        <ModalPage>
            {headerText()}
            {bookings?.length ? (
                <Grid direction="column" container style={{ padding: 20 }}>
                    <Text bold textStyle={{ color: Colors.error }}>
                        PROVIDER BOOKED:
                    </Text>
                    <Grid container item direction="column">
                        {bookings?.map((booking: IPairing) => (
                            <Text key={booking.id} textStyle={{ color: Colors.error }}>
                                {formatMonthYearWith12HourTime(booking.appointment.start_date)} -{' '}
                                {format12HourTime(booking.appointment.end_date)}
                            </Text>
                        ))}
                    </Grid>
                </Grid>
            ) : null}

            <Grid container item direction="column" justify="flex-start" style={{ padding: 20, gap: 20 }}>
                <Text>
                    <Text variant="body1" bold>
                        Provider Comments:
                    </Text>
                    {jobChange.comment}
                </Text>
                <Grid container item direction="row" justify="space-around" alignContent="center">
                    <Grid container item xs={6} direction="column">
                        <OutlinedTextInput
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            value={jobChange.pay}
                            style={{ backgroundColor: 'white', padding: 0, borderRadius: 15, maxWidth: '50%' }}
                            placeholder="Pay Rate"
                            onChange={(e) => updatePayRate(Number(e.target.value))}
                            error={!jobChange.pay}
                            helperText="Pay Rate"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {requestedRate ? (
                            <Text variant="caption">Provider requested a pay rate of ${requestedRate.toFixed(2)}</Text>
                        ) : null}
                        {qualifiedRate?.qualified_rate ? (
                            <Text variant="caption">
                                Qualified pay rate: ${qualifiedRate.qualified_rate.toFixed(2)}
                            </Text>
                        ) : null}
                        {qualifiedRate?.provider_rate ? (
                            <Text variant="caption">
                                Latest provider rate: ${qualifiedRate.provider_rate.toFixed(2)}
                            </Text>
                        ) : null}
                        {matchPayRate ? (
                            <Text variant="caption">Match pay rate: ${matchPayRate.toFixed(2)}</Text>
                        ) : null}
                    </Grid>
                </Grid>
                <Grid item container direction="column">
                    <Text variant="body1" bold>
                        Shifts:
                    </Text>
                    <Text variant="caption">
                        Confirm shift times, if the provider asked for alterations, we have prepopulated them below. If
                        you negotiated any changes, please update the times. To remove a shift press the X on the right
                        side.
                    </Text>
                    <CheckboxLabel
                        value={editAllTimes}
                        control={
                            <Checkbox
                                checked={editAllTimes}
                                color="primary"
                                onChange={(e, checked: boolean) => setEditAllTimes(checked)}
                            />
                        }
                        label={<Text>Edit All Times</Text>}
                    />
                    {appointments &&
                        jobChange.time_changes.map((t: TimeChange, index: number) => (
                            <Grid
                                container
                                item
                                key={index}
                                direction="column"
                                style={{
                                    padding: 10,
                                    borderRadius: 18,
                                    backgroundColor: Colors.lightBlue,
                                    marginTop: 10,
                                }}
                            >
                                <JobTimeChange
                                    removedTimeSlots={removedTimeSlots}
                                    timeChange={t}
                                    updateJobChangeTime={updateJobChangeTime}
                                    index={index}
                                    setRemovedTimeSlots={setRemovedTimeSlots}
                                />
                                {!removedTimeSlots.includes(t.appointment) ? (
                                    <GenerateAppointments
                                        appt={appointments.find((a) => (a.id = t.appointment))}
                                        generateAfter={generateAfter}
                                        setGenerateAfter={setGenerateAfter}
                                        generateBefore={generateBefore}
                                        setGenerateBefore={setGenerateBefore}
                                        timeChange={t}
                                    />
                                ) : null}
                            </Grid>
                        ))}
                </Grid>
                <OutlinedTextInput
                    value={businessComment}
                    style={{ backgroundColor: 'white', padding: 0, borderRadius: 15, margin: '10px 0px' }}
                    placeholder="Business Comments"
                    onChange={(e) => setBusinessComment(e.target.value)}
                    helperText="Add any comments you'd like for the Tandem team"
                    fullWidth
                />
                <CheckboxLabel
                    value={!notifyBusiness}
                    control={
                        <Checkbox
                            checked={notifyBusiness || false}
                            color="primary"
                            onChange={(e) => setNotifyBusiness(e.target.value === 'true')}
                        />
                    }
                    label={<Text>Notify Business?</Text>}
                />
                {overlappingShifts || overlappedWithBookedShift || invalidShiftTimes ? (
                    <Text variant="caption" color={Colors.error} textStyle={{ textAlign: 'end' }}>
                        {overlappingShifts
                            ? 'Shifts cannot overlap'
                            : overlappedWithBookedShift
                              ? 'Shifts overlap with booked shifts'
                              : 'Shifts cannot end before they start'}
                    </Text>
                ) : null}
                <PrimaryButton
                    disabled={
                        loading ||
                        !jobChange.pay ||
                        overlappingShifts ||
                        overlappedWithBookedShift ||
                        invalidShiftTimes ||
                        noTimeSlotsSelected
                    }
                    loading={loading}
                    buttonStyle={{ maxWidth: '50%', alignSelf: 'flex-end' }}
                    onClick={approveChanges}
                >
                    Approve
                </PrimaryButton>
                <Text textStyle={{ color: Colors.error }}>{getErrorText()}</Text>
            </Grid>
        </ModalPage>
    );
}
