import React from 'react';
import { Link } from 'react-router-dom';
import { Colors, Text } from 'library';
import { Provider } from 'models';
import useGoToProfile from '../actions/goToProfile';

const ListingName = ({ provider }: { provider: Provider }) => {
    const { getProfilePath } = useGoToProfile();

    return (
        <Link to={getProfilePath(provider.user.id)} style={{ color: Colors.darkNavy, textDecoration: 'underline' }}>
            <Text
                variant="display"
                textStyle={{
                    fontSize: 22,
                    maxWidth: '100%',
                    fontWeight: 900,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: '36px',
                }}
            >
                {provider.user.first_name} {provider.user.last_name}
            </Text>
        </Link>
    );
};

export default ListingName;
