import React from 'react';
import { Colors, Text } from 'library';
import { styled } from '@mui/material';

import type { StyledComponentProps } from '@mui/material';

export function CredentialPill({
    label,
    selected,
    onClick,
    tooHigh = false,
    style,
}: {
    label: string;
    selected: boolean;
    onClick?: () => void;
    tooHigh?: boolean;
    style?: StyledComponentProps;
}) {
    return (
        <Pill selected={selected.toString()} styles={style ?? {}} onClick={onClick} tooHigh={tooHigh.toString()}>
            <Text
                textStyle={{ color: selected ? Colors.white : Colors.darkNavy, cursor: 'pointer', fontSize: 13 }}
                bold
            >
                {label}
            </Text>
        </Pill>
    );
}

const Pill = styled('div')(
    ({ selected, tooHigh, styles }: { selected: string; tooHigh: string; styles: StyledComponentProps }) => ({
        backgroundColor: selected === 'true' ? Colors.darkNavy : Colors.lightTurq,
        border: selected === 'true' && tooHigh === 'true' ? `1px solid ${Colors.error}` : 'none',
        borderRadius: 30,
        cursor: 'pointer',
        marginRight: 10,
        marginTop: 8,
        padding: '3px 10px',
        width: 'fit-content',
        height: 'fit-content',
        ...styles,
    }),
);
