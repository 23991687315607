import React from 'react';
import { sortBy } from 'lodash';
import { Grid } from '@mui/material';
import { PrimaryButton, Text } from 'library';
import useLoading from 'library/hooks/useLoading';
import { client, consoleLogInDev } from 'shared';

import { InnerPay } from 'parent-portal/forms/components/pages/RequirementsAndPayPage/payComponents/InnerPay';
import { useRequirementsAndPayContext } from 'parent-portal/forms/components/pages/RequirementsAndPayPage/context';

export default function InnerEditPayModal({
    id,
    onSaveComplete,
    onClose,
}: {
    id: number | undefined;
    onSaveComplete: () => void;
    onClose: () => void;
}) {
    const { scales, disabled, onClick } = useRequirementsAndPayContext();
    const { loading, setLoading } = useLoading();

    const sortedScales = sortBy(scales, 'rate');
    const min = sortedScales[0].rate;
    const max = sortedScales.at(-1)?.rate ?? sortedScales[0].rate;

    function submit() {
        setLoading(true);
        client(`api/ongoing/${id}/`, {
            method: 'PATCH',
            body: {
                pay: min === max ? min : null,
                rate_min: sortedScales[0].rate,
                rate_max: sortedScales.at(-1)?.rate ?? sortedScales[0].rate,
                pay_scales: sortedScales,
            },
        })
            .then(() => onSaveComplete())
            .catch(consoleLogInDev)
            .finally(() => {
                setLoading(false);
                onClose();
            });
    }

    return (
        <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
            <Text variant="h1">Update Pay</Text>
            <Grid container justifyContent="center">
                <InnerPay editing={true} />
            </Grid>
            <PrimaryButton
                loading={loading}
                disabled={loading || disabled}
                onClick={() => onClick(() => submit())}
                buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
            >
                Save
            </PrimaryButton>
        </Grid>
    );
}
