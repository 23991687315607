import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Text, Avatar, Paper } from 'library';
import { useHistory } from 'react-router';
import theme from 'theme';
import { getPrioritizedCredentials } from '../provider-profile/helpers/Helpers';
import { DayTimeslots, ISubAvailability, StartEnd } from './models';
import { ApiTimeslot } from 'internal/substitutes/substituteModels';
import TimeslotEntry from './TimeslotEntry';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
const defaultProfileImage = require('assets/images/default-sitter.png');

interface IAvailabilityCard {
    sub: ISubAvailability;
}

const AvailabilityCard = ({ sub }: IAvailabilityCard) => {
    const history = useHistory();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));

    const groupTimeSlots = (availability: ApiTimeslot[]) => {
        let newTimeSlots: DayTimeslots[] = [];
        availability.forEach((a: ApiTimeslot) => {
            let index = -1;
            index = newTimeSlots.findIndex((slot) => slot.date.getDate() === new Date(a.timeslot.lower).getDate());
            if (index === -1) {
                newTimeSlots.push({
                    date: new Date(a.timeslot.lower),
                    timeslots: [{ lower: a.timeslot.lower, upper: a.timeslot.upper, booked: a.booked }],
                });
            } else {
                let updatedSlots = [...newTimeSlots[index].timeslots];
                updatedSlots.push({ lower: a.timeslot.lower, upper: a.timeslot.upper, booked: a.booked });
                newTimeSlots[index].timeslots = updatedSlots;
            }
        });
        return newTimeSlots;
    };

    const prioritizedCredentials = getPrioritizedCredentials(sub.qualifications);
    return (
        <Paper
            style={{
                marginTop: '2%',
                width: smDown ? '95%' : '80%',
                display: 'flex',
                flexDirection: smDown ? 'column' : 'row',
            }}
        >
            <Grid container item md={2} sm={4} xs={12} justify={xsOnly ? 'space-between' : 'center'}>
                <Avatar
                    src={(sub.profile_picture && sub.profile_picture.image) || defaultProfileImage}
                    style={{
                        width: xsOnly ? 65 : 115,
                        height: xsOnly ? 65 : 115,
                    }}
                />
            </Grid>
            <Grid item md={10} sm={8} xs={12}>
                <Grid container item xs={12} direction="row" spacing={3}>
                    <Grid item sm={9} xs={12}>
                        <Text
                            variant={xsOnly ? 'h2' : 'h1'}
                            bold
                            onClick={() => history.push(`/provider/${sub.id}`)}
                            textStyle={{ textDecoration: 'underline', cursor: 'pointer' }}
                        >
                            {sub.first_name} {sub.last_name}
                        </Text>
                        <Grid item xs={12} style={{ marginTop: '1%' }}>
                            <Text variant="body2" textStyle={{ fontStyle: 'italic' }}>
                                {sub.distance?.miles} miles away
                            </Text>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1%' }}>
                            <Text variant="body2">{sub.bio}</Text>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '1%' }}>
                            <Text variant="body2">
                                <b>Notable Credentials: </b> Tandem Center Certified
                                {prioritizedCredentials ? `, ${prioritizedCredentials}` : prioritizedCredentials}
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '1.5%' }}>
                    <Text variant={smDown ? 'body1' : 'h2'} bold>
                        Availability
                    </Text>
                </Grid>
                <Grid container item xs={12} direction="row" spacing={smDown ? 2 : 4}>
                    {groupTimeSlots(sub.availability).map((availability) => (
                        <Grid item style={{ marginTop: '1%' }}>
                            <Grid item xs={12}>
                                <Text variant="body1" bold>
                                    {formatShortWeekdayMonthDay(availability.date)}
                                </Text>
                            </Grid>
                            {availability.timeslots.map((timeslot: StartEnd) => (
                                <TimeslotEntry timeslot={timeslot} />
                            ))}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default AvailabilityCard;
