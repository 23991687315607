import { useMediaQuery } from '@material-ui/core';
import { SizeableRoundedDialog } from 'library';
import { useUserContext } from 'UserContext';
import React, { useEffect, useState } from 'react';
import ConnectBankAccountPage from './pages/ConnectBankAccountPage';
import ConnectCreditCardPage from './pages/ConnectCreditCardPage/ConnectCreditCardPage';

import IntroPage from './pages/IntroPage';
import LoadingInstantVerificationPage from './pages/LoadingInstantVerificationPage';
import ManualVerificationPage from './pages/ManualVerificationPage';
import PlaidDialog from './PlaidDialog';
import { StripeContextProvider } from './StripeContext';
import SuccessPage from './pages/SuccessPage';
import theme from 'theme';
import { track } from 'shared/Tracking';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';

interface ConnectPaymentModalProps {
    isOpen: boolean;
    onClose: () => void;
    fromRoute: string;
    onSuccess?: () => void;
    refreshUser?: () => void;
    subtitle?: string;
}

enum ModalPage {
    intro = 'intro',
    connectCreditCard = 'connect credit card',
    connectBankAccount = 'connect bank acccount',
    manualVerification = 'manual verification',
    instantVerification = 'instant verification',
    success = 'success',
}

export default function ConnectPaymentModal({
    isOpen,
    onClose,
    fromRoute,
    onSuccess,
    refreshUser,
    subtitle,
}: ConnectPaymentModalProps) {
    const [currentPage, setCurrentPage] = useState<ModalPage>(ModalPage.intro);
    const [showPlaidDialog, setShowAccountSelection] = useState(false);
    const { refreshAlreadyLoggedInUser } = useUserContext();
    const { trackSession } = usePublicSessionTracking('Connect Payment Modal');

    useEffect(() => {
        if (isOpen) {
            track('Payment Connection: Went to modal page', { newPage: currentPage, fromRoute });
            trackSession('DIALOG_OPEN', 'Payment Connection: Went to modal page', PublicSessionEventType.User, {
                newPage: currentPage,
                fromRoute,
            });
        }
    }, [isOpen]);

    function goToConnectBankAccount() {
        goToPage(ModalPage.connectBankAccount);
    }
    function goToConnectCreditCard() {
        goToPage(ModalPage.connectCreditCard);
    }

    function goToManualVerification() {
        goToPage(ModalPage.manualVerification);
    }

    function goToInstantVerification() {
        goToPage(ModalPage.instantVerification);
    }

    function goToIntro() {
        goToPage(ModalPage.intro);
    }

    function goToSuccess() {
        goToPage(ModalPage.success);
    }

    function showInstantVerification() {
        setShowAccountSelection(true);
    }

    function onAccountSelected() {
        setShowAccountSelection(false);
        goToSuccess();
    }

    function onAccountSelectionAborted() {
        setShowAccountSelection(false);
        goToIntro();
    }

    function goToPage(page: ModalPage) {
        track('Payment Connection: Went to modal page', {
            previousPage: currentPage,
            newPage: page,
            fromRoute,
        });
        setCurrentPage(page);
    }

    function close() {
        if (currentPage === ModalPage.success) {
            if (onSuccess) {
                onSuccess();
            }
            trackSession('SUBMIT', 'Payment Connection: Success message', PublicSessionEventType.User);
        }
        refreshUser ? refreshUser() : refreshAlreadyLoggedInUser();
        track('Payment Connection: Modal closed', { page: currentPage, fromRoute });
        trackSession('DIALOG_CLOSE', 'Payment Connection: Modal closed', PublicSessionEventType.User, {
            page: currentPage,
            fromRoute,
        });
        onClose();
    }

    const allPages = {
        [ModalPage.intro]: () => (
            <IntroPage
                goToConnectBankAccount={goToConnectBankAccount}
                goToConnectCreditCard={goToConnectCreditCard}
                subtitle={subtitle}
            />
        ),
        [ModalPage.connectCreditCard]: () => <ConnectCreditCardPage goBack={goToIntro} goToSuccess={goToSuccess} />,
        [ModalPage.connectBankAccount]: () => (
            <ConnectBankAccountPage
                goToInstantVerification={goToInstantVerification}
                goToManualVerification={goToManualVerification}
                goBack={goToIntro}
            />
        ),
        [ModalPage.manualVerification]: () => (
            <ManualVerificationPage goToSuccess={goToSuccess} goBack={goToConnectBankAccount} />
        ),
        [ModalPage.success]: () => <SuccessPage />,
        [ModalPage.instantVerification]: () => <LoadingInstantVerificationPage />,
    };

    const small = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            {currentPage === ModalPage.instantVerification ? (
                <PlaidDialog
                    onReady={showInstantVerification}
                    onAccountSelected={onAccountSelected}
                    onAbort={onAccountSelectionAborted}
                />
            ) : null}
            <SizeableRoundedDialog
                closeButton
                open={isOpen && !showPlaidDialog}
                onClose={close}
                style={{ height: small ? 794 : 715, width: '80%' }}
            >
                <StripeContextProvider>{allPages[currentPage]()}</StripeContextProvider>
            </SizeableRoundedDialog>
        </>
    );
}
