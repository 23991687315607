import React from 'react';
import { Grid } from '@material-ui/core';
import { Text } from 'library';
import { GeneralDocumentVerifyProps } from '../models';
import ComplianceRequirementSelect from './ComplianceRequirementSelect';
import RequiredFormInfoForDocType from './RequiredFormInfoForDocType';

const FORM_BASED_EXTRA_INFO = {
    medical_statement_document: [
        '✔ All vaccination section boxes are checked',
        '✔ Has signature',
        '✔ TB Section has checkmarks for "no" if empty',
        '✔ Signed in the past year',
    ],
};

export default function GeneralDocumentVerify({
    requirementOptions,
    updateRequirementOptions,
    documentType,
    formInformation,
    updateFormInformation,
}: GeneralDocumentVerifyProps) {
    const formBasedExtraInfo = FORM_BASED_EXTRA_INFO[documentType as keyof typeof FORM_BASED_EXTRA_INFO] || [];

    return (
        <Grid container item direction="column">
            <Text bold textStyle={{ margin: 5 }}>
                Before verifying, check the following...
            </Text>
            {formBasedExtraInfo.map((step, index) => (
                <Text key={index} textStyle={{ margin: 5 }}>
                    {step}
                </Text>
            ))}
            <Text textStyle={{ margin: 5 }}>✔ The document has correct providers name</Text>
            <ComplianceRequirementSelect
                requirementOptions={requirementOptions}
                updateRequirementOptions={updateRequirementOptions}
            />
            <RequiredFormInfoForDocType
                documentType={documentType}
                formInformation={formInformation}
                updateFormInformation={updateFormInformation}
            />
        </Grid>
    );
}
