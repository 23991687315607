import React from 'react';
import NewAddressForm from './NewAddressForm';
import { Checkbox, Colors, Text } from 'library';
import { useUserContext } from 'UserContext';
import { formatAddress } from 'shared';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { WarningRounded } from '@mui/icons-material';

export default function BusinessAddressSelection({
    isAddingAddress,
    setIsAddingAddress,
}: {
    isAddingAddress: boolean;
    setIsAddingAddress: (value: boolean) => void;
}) {
    const { user } = useUserContext();
    const user_addresses = user?.address;
    const { form } = useJobFormContext();
    const { address } = form.fieldStates;
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const showWarning = address.value.length > 0 && address.value?.some((x) => x.timezone !== browserTimezone);

    function onAddressChange(addressId: number, businessLocationId?: number, timezone?: string) {
        const addressValue = address.value || [];

        if (addressValue.some((x) => x.id === addressId)) {
            address.setValue(addressValue.filter((x) => x.id !== addressId));
        } else {
            address.setValue([...addressValue, { id: addressId, businessLocationId, timezone }]);
        }
    }

    return (
        <>
            {!isAddingAddress
                ? user_addresses?.map((x) => (
                      <Checkbox
                          key={x.id}
                          label={formatAddress(x)}
                          checked={address.value.some((val) => val.id === x.id)}
                          onChange={() => onAddressChange(x.id, x.business_location?.id, x.business_location?.timezone)}
                      />
                  ))
                : null}
            {showWarning && (
                <Text variant="body2" color={Colors.mediumRed} textStyle={{ marginTop: 10 }}>
                    <WarningRounded fontSize="small" /> It looks like you are in a timezone that does not match the
                    timezone of at least one of the centers where you are creating this job. Please ensure you have
                    entered the times for your job in the timezone where the center is located.
                </Text>
            )}

            <NewAddressForm isAddingAddress={isAddingAddress} setIsAddingAddress={setIsAddingAddress} />
        </>
    );
}
