import React from 'react';
import Divider from 'parent-portal/forms/components/Divider';
import DocumentsSection from '../../documents/DocumentsSection';
import MedStatus from '../../MedStatus';
import Form718bStatus from '../../Form718bStatus';
import { LoadingIndicator, Text } from 'library';
import { AdminProvider } from 'models';
import BspDialog from 'internal/providers/BspDialog';
import ILFingerprintCheckDialog from 'internal/providers/ILFingerprintCheckDialog';
import {
    ProviderLabels,
    FingerPrintAppointmentInfo,
    WorkerOccrraStatus,
    WorkerQualifications,
    WorkerPastExperience,
    WorkerNotes,
    WorkerBasicInfo,
    WorkerProfileHeader,
} from './components';
import OutreachTextBtns from '../../OutreachTextBtns';
import SesameAppointmentInfo from '../../SesameAppointmentInfo';
import ComplianceWarnings from '../ComplianceWarnings/ComplianceWarnings';
import AutomatedReferences from './components/AutomatedReferences';
import RegistryAccountStatus from './components/RegistryAccountStatus';

export default function ProfileInfo({
    provider,
    refreshProvider,
    userRefreshError,
}: {
    provider?: AdminProvider;
    refreshProvider: () => void;
    userRefreshError?: string;
}) {
    if (!provider)
        if (userRefreshError) {
            return (
                <Text
                    textStyle={{ padding: 20 }}
                >{`Could not find details about this provider. ${userRefreshError}`}</Text>
            );
        } else {
            return (
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingIndicator size={36} />
                </div>
            );
        }

    return (
        <div>
            <WorkerProfileHeader provider={provider} refreshProvider={refreshProvider} />
            <Divider />
            <BspDialog provider={provider} />
            {provider.user.compliance_state === 'Illinois' && <ILFingerprintCheckDialog provider={provider} />}
            <WorkerBasicInfo provider={provider} />
            <RegistryAccountStatus provider={provider} refreshProvider={refreshProvider} />
            <Divider />
            <WorkerNotes provider={provider} refreshProvider={refreshProvider} />
            <Divider />
            <WorkerPastExperience provider={provider} />
            <Divider />
            <WorkerQualifications provider={provider} />
            <Divider />
            <ComplianceWarnings
                warnings={provider.employment_and_training_summary.compliance_warnings}
                refreshProvider={refreshProvider}
            />
            <Divider />
            <DocumentsSection provider={provider} refreshProvider={refreshProvider} />
            <Divider />
            <WorkerOccrraStatus provider={provider} refreshProvider={refreshProvider} />
            <AutomatedReferences provider={provider} />
            <MedStatus provider={provider} onRefresh={refreshProvider} />
            {provider.user?.address.state === 'Illinois' && (
                <>
                    <Divider />
                    <Form718bStatus provider={provider} />
                </>
            )}
            <Divider style={{ marginTop: 10 }} />
            <SesameAppointmentInfo provider={provider} />
            <Divider />
            <FingerPrintAppointmentInfo provider={provider} />
            <Divider />
            <ProviderLabels provider={provider} />
            <Divider />
            <OutreachTextBtns provider={provider} getProvider={refreshProvider} />
        </div>
    );
}
