import React, { useState } from 'react';
import theme from 'theme';
import { sortBy } from 'lodash';
import { Colors, DropDown, Link, Text } from 'library';
import { InputAdornment, TextField, styled, useMediaQuery } from '@mui/material';

import { AdditionalPills } from './AdditionalPills';
import { AdditionalModal } from './AdditionalModal';
import { educationChoice, experienceChoice } from '../utils/choices';
import { gatherDisplayPills } from '../utils/pills';
import { updateScale } from '../utils/scale';
import { minumumWageForState } from '../utils/problems';

import { useRequirementsAndPayContext } from '../context';

import type { IManagedScale, GenericChoice, Pills, Qualification } from '../types';

export function PayRow({ scale, editing }: { scale: IManagedScale; editing?: boolean }) {
    const small = useMediaQuery(theme.breakpoints.down('md'));

    const {
        problems: allProblems,
        scales,
        initialScale,
        qualifications,
        state,
        updateScales,
        deleteScale,
    } = useRequirementsAndPayContext();

    const initialRate = isNaN(scale.rate) ? '0' : parseFloat(scale.rate.toFixed(2));
    const problems = allProblems[scale.id];

    const [rate, setRate] = useState<string>(initialRate.toString());
    const [open, setOpen] = useState<boolean>(false);

    const pills: Pills = gatherDisplayPills(scale, qualifications, state);

    function update(
        value: number | number[],
        choices: GenericChoice[],
        qualification: Qualification | null,
        category: 'education' | 'experience' | 'multiple' | 'single' | 'boolean' | 'rate',
    ) {
        const newScale = updateScale(scale, value, choices, qualification, category);

        updateScales(newScale);
    }

    function coerceRate(rate: string): number {
        const parsedRate = parseFloat(rate);
        if (!isNaN(parsedRate)) {
            const coercedRate = parsedRate.toFixed(2);

            setRate(coercedRate);
            return parseFloat(coercedRate);
        }

        setRate(rate);
        return parsedRate;
    }

    return (
        <Row small={small.toString()}>
            {(scale.id !== initialScale.id || editing) && scales?.length > 1 && (
                <DeleteRow small={small.toString()}>
                    <Text
                        textStyle={{ color: Colors.error, textDecorationLine: 'underline', cursor: 'pointer' }}
                        variant="caption"
                        onClick={() => deleteScale(scale.id)}
                    >
                        Delete
                    </Text>
                </DeleteRow>
            )}
            <InnerRow small={small.toString()}>
                <PayBox small={small.toString()}>
                    <TextField
                        value={rate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRate(e.target.value)}
                        onBlur={() => update(coerceRate(rate), [], null, 'rate')}
                        style={{ backgroundColor: Colors.white, minWidth: 120 }}
                        defaultValue="USD"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Text>$</Text>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Text>/hr</Text>
                                </InputAdornment>
                            ),
                            style: { fontFamily: 'inherit', color: Colors.darkNavy },
                        }}
                        error={!!problems?.rate}
                    />
                </PayBox>
                <div style={{ minWidth: small ? '100%' : '70%' }}>
                    <StyledDropDown small={small.toString()}>
                        <Text
                            bold
                            textStyle={{
                                width: '55%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                paddingRight: 10,
                            }}
                            variant="body2"
                        >
                            Education Requirment
                        </Text>
                        <DropDown
                            selected={educationChoice(scale, qualifications.education)}
                            fields={sortBy(qualifications.education, 'order')}
                            onChange={({ target: { value } }: { target: { value: number } }) =>
                                update(value, qualifications.education, null, 'education')
                            }
                            MenuOverrideProps={{ getContentAnchorEl: () => null as unknown as Element }}
                        />
                    </StyledDropDown>
                    <StyledDropDown small={small.toString()}>
                        <Text
                            bold
                            textStyle={{
                                width: '55%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                paddingRight: 10,
                            }}
                            variant="body2"
                        >
                            Years of Experience
                        </Text>
                        <DropDown
                            selected={experienceChoice(scale, qualifications.experience)}
                            fields={qualifications.experience}
                            onChange={({ target: { value } }: { target: { value: number } }) =>
                                update(value, qualifications.experience, null, 'experience')
                            }
                            MenuOverrideProps={{ getContentAnchorEl: () => null as unknown as Element }}
                        />
                    </StyledDropDown>
                    <AdditionalQualifications small={small}>
                        <Text variant="body2" bold>
                            <b>Additional Qualifications:</b> {}
                        </Text>
                        <LinkContainer>
                            <Link
                                color={Colors.darkNavy}
                                style={{ fontSize: 12, fontWeight: 'normal', width: 'max-content', marginLeft: 8 }}
                                onClick={() => setOpen(true)}
                            >
                                + Add Qualifications
                            </Link>
                        </LinkContainer>
                        <AdditionalModal open={open} onClose={() => setOpen(false)} scale={scale} />
                    </AdditionalQualifications>
                    <AdditionalPills pills={pills} />
                </div>
            </InnerRow>
            {!!problems?.rate && problems?.rate === 'bad' && (
                <Text variant="caption" color={Colors.error} textStyle={{ paddingTop: 10 }}>
                    Please input a valid rate
                </Text>
            )}
            {!!problems?.rate && problems?.rate === 'lower' && (
                <Text variant="caption" color={Colors.error} textStyle={{ paddingTop: 10 }}>
                    Pay cannot be lower than previous rates
                </Text>
            )}
            {!!problems?.rate && problems?.rate === 'toolow' && (
                <Text variant="caption" color={Colors.error} textStyle={{ paddingTop: 10 }}>
                    The lowest pay allowed is ${minumumWageForState(state)}
                </Text>
            )}
            {!!problems?.noQualifications && problems?.noQualifications && (
                <Text variant="caption" color={Colors.error} textStyle={{ paddingTop: 10 }}>
                    Please enter some qualifications for this pay rate
                </Text>
            )}
            {!!problems?.duplicate && problems?.duplicate && (
                <Text variant="caption" color={Colors.error} textStyle={{ paddingTop: 10 }}>
                    These pay rate qualifications are a duplicate of another, please change at least one qualification
                </Text>
            )}
        </Row>
    );
}

const Row = styled('div')(({ small }: { small: string }) => ({
    backgroundColor: Colors.lightTurq,
    borderRadius: 10,
    padding: small === 'true' ? '20px 20px' : '10px 20px',
    marginBottom: 20,
}));

const DeleteRow = styled('div')(({ small }: { small: string }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: small === 'true' ? 10 : 0,
}));

const InnerRow = styled('div')(({ small }: { small: string }) => ({
    display: small === 'true' ? 'block' : 'flex',
}));

const PayBox = styled('div')(({ small }: { small: string }) => ({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: small === 'true' ? 0 : 10,
    paddingBottom: small === 'true' ? 10 : 0,
    width: small === 'true' ? '100%' : 'inherit',
}));

const StyledDropDown = styled('div')(({ small, warning }: { small: string; warning?: boolean }) => ({
    marginLeft: 10,
    display: small === 'true' ? 'block' : 'flex',
    border: warning ? `1px solid ${Colors.terraCotta}` : 'inherit',
    borderRadius: 16,
}));

const AdditionalQualifications = styled('div')(({ small }: { small: boolean }) => ({
    display: 'flex',
    flexDirection: small ? 'column' : 'row',
    justifyContent: 'space-between',
    marginLeft: 8,
    paddingTop: 10,
}));

const LinkContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
});
