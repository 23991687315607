import { JobDetail, JobDetailValueWithOption } from 'models';
import { FormFieldProps } from './components/FormTypes';

export function addOrUpdateDetail(
    val: JobDetailValueWithOption,
    jobDetails: FormFieldProps<JobDetailValueWithOption[]>,
) {
    const newValues = Array.from(jobDetails.value);
    const existingValue = newValues.find((existing) => existing.detail === val.detail);
    if (existingValue) {
        existingValue.value = val.value;
        existingValue.option = val.option;
        existingValue.option_id = val.option_id;
    } else newValues.push(val);
    jobDetails.setValue(newValues);
}

export function addOrRemoveDetail(
    jobDetails: FormFieldProps<JobDetailValueWithOption[]>,
    detail: JobDetail,
    optionId?: number,
) {
    const newValues = Array.from(jobDetails.value);
    const existingOptionIndex = newValues.findIndex(
        (val) => val.detail === detail.id && val.option_id && val.option_id === optionId,
    );
    if (existingOptionIndex >= 0) newValues.splice(existingOptionIndex, 1);
    else
        newValues.push({
            detail: detail.id,
            option_id: optionId,
            option: detail.options.find((o) => o.id === optionId),
        });
    jobDetails.setValue(newValues);
}
