import React from 'react';
import { Text } from 'library';
import { styled } from '@mui/material';

import { CredentialPill } from '../CredentialPill';
import { ExplanatoryCredentialPill } from '../ExplanatoryCredentialPill';

export function BaseCredentials() {
    return (
        <Row>
            <CredentialPill label="1176 Filed to Program ID" selected={true} />
            <CredentialPill label="Medical Form" selected={true} />
            <CredentialPill label="Verified HS Education" selected={true} />
            <CredentialPill label="Staff Orientation Training" selected={true} />
            <ExplanatoryCredentialPill
                label="Other Trainings According to DCY Timeline"
                title={
                    <Hover>
                        <Text variant="caption" bold>
                            Day 30
                        </Text>
                        <Text variant="caption">- Ohio&apos;s Overview of Child Development</Text>
                        <Text variant="caption">- Ohio&apos;s Approach to Quality</Text>
                        <Text variant="caption" bold>
                            Day 60
                        </Text>
                        <Text variant="caption">- ODJFS Child Abuse Training</Text>
                        <Text variant="caption" bold>
                            Day 90
                        </Text>
                        <Text variant="caption">- First Aid/CPR</Text>
                    </Hover>
                }
            />
        </Row>
    );
}

const Row = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
});

const Hover = styled('div')({
    padding: 5,
});
