import { Search } from '@mui/icons-material';
import { ChatwootUrl } from 'environmentVariables';
import { IconButton, Colors } from 'library';
import React from 'react';

export default function SearchHelpCenter() {
    return (
        <div style={{ position: 'fixed', width: 40, height: 40, right: 180, bottom: 26 }}>
            <IconButton
                icon={Search}
                size={40}
                backgroundColor={Colors.darkNavy}
                color={Colors.white}
                onClick={() => window.open(`${ChatwootUrl}/hc/child-care-center-help-center/en`)}
            />
        </div>
    );
}
