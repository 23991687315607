import React from 'react';
import { Grid } from '@material-ui/core';
import { Message } from 'models/Message';
import { Colors, Paper, SmallIcon, Text } from 'library';
import { Thread } from './Thread';
import { ThreadHeader } from './ThreadHeader/ThreadHeader';
import { IMessageForm } from './models';
import { getMaxHeight } from './messageUtils';
import { useWidth } from 'reusableComponents/useWidth';
import { EditOff } from '@mui/icons-material';
import { styled } from '@mui/material';

export default function WebMessageForm({
    providerIdObject,
    messageToSend,
    messages,
    locked,
    providerName,
    messagesEndRef,
    bottomOfThread,
    user,
    MessageInput,
    providerImage,
}: IMessageForm) {
    const screenSize = useWidth();
    return (
        <Paper padding={0} key="web-message">
            <Grid xs={12}>
                <ThreadHeader providerIdObject={providerIdObject} providerName={providerName ?? ''} />
                <Grid xs={12}>
                    <Grid
                        item
                        xs={12}
                        style={{
                            maxHeight: getMaxHeight(false, screenSize),
                            overflowY: 'scroll',
                            padding: 30,
                            paddingBottom: messageToSend.trim().length === 0 ? 0 : 30,
                        }}
                    >
                        {messages &&
                            messages.map((message: Message, i: number) => (
                                <div key={i}>
                                    <Thread message={message} user={user} providerImage={providerImage} />
                                </div>
                            ))}
                        <Grid ref={bottomOfThread} />
                    </Grid>

                    <>
                        <hr
                            style={{
                                color: Colors.darkGrey,
                                width: '100%',
                                padding: 0,
                            }}
                        />
                        <Input>
                            {locked ? (
                                <ThreadLockedSection container direction="row">
                                    <SmallIcon
                                        backgroundColor={Colors.darkNavy}
                                        color={Colors.white}
                                        icon={<EditOff />}
                                    />
                                    <Text bold>This chat is from a previous shift and is read only.</Text>
                                </ThreadLockedSection>
                            ) : (
                                MessageInput
                            )}
                        </Input>
                    </>
                </Grid>
            </Grid>
            <Grid ref={messagesEndRef} />
        </Paper>
    );
}

const Input = styled(Grid)({
    padding: 30,
    paddingTop: 0,
});

const ThreadLockedSection = styled(Grid)({
    alignItems: 'center',
    gap: 10,
});
