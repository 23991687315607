import React, { useState } from 'react';
import theme from 'theme';
import { Colors, Text } from 'library';
import { InputAdornment, TextField, styled, useMediaQuery } from '@mui/material';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';

import { useRequirementsAndPayContext } from '../context';
import { minumumWageForState } from '../utils/problems';
import { updateScale } from '../utils/scale';
import { AdditionalPills } from './AdditionalPills';
import { CredentialPill } from '../components/CredentialPill';
import { gatherDisplayPills } from '../utils/pills';

import type { GenericChoice, GenericQualificationChoices, IManagedScale, Pills, Qualification } from '../types';

export function BasePayRow({
    scale,
    qualificationChoices,
}: {
    scale: IManagedScale;
    qualificationChoices: GenericQualificationChoices;
}) {
    const small = useMediaQuery(theme.breakpoints.down('md'));
    const { goToPrevious } = useJobFormContext();

    const { problems: allProblems, state, updateScales } = useRequirementsAndPayContext();
    const problems = allProblems[scale.id];

    const initialRate = isNaN(scale.rate) ? '0' : parseFloat(scale.rate.toFixed(2));

    const [rate, setRate] = useState<string>(initialRate.toString());

    const pills: Pills = gatherDisplayPills(scale, qualificationChoices, state);

    function update(
        value: number | number[],
        choices: GenericChoice[],
        qualification: Qualification | null,
        category: 'education' | 'experience' | 'multiple' | 'single' | 'boolean' | 'rate',
    ) {
        const newScale = updateScale(scale, value, choices, qualification, category);

        updateScales(newScale);
    }

    function coerceRate(rate: string): number {
        const parsedRate = parseFloat(rate);
        if (!isNaN(parsedRate)) {
            const coercedRate = parsedRate.toFixed(2);

            setRate(coercedRate);
            return parseFloat(coercedRate);
        }

        setRate(rate);
        return parsedRate;
    }

    return (
        <Row small={small.toString()}>
            <InnerRow small={small.toString()}>
                <PayBox small={small.toString()}>
                    <TextField
                        value={rate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRate(e.target.value)}
                        onBlur={() => update(coerceRate(rate), [], null, 'rate')}
                        style={{ backgroundColor: Colors.white }}
                        defaultValue="USD"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Text>$</Text>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Text>/hr</Text>
                                </InputAdornment>
                            ),
                            style: { fontFamily: 'inherit', color: Colors.darkNavy },
                        }}
                    />
                </PayBox>
                <SideBox small={small.toString()}>
                    <PillBox>
                        <Text bold>Minimum Requirements</Text>
                        <InnerPillBox>
                            {pills.education?.value && <CredentialPill label={pills.education.value} selected={true} />}
                            {pills.experience?.value && (
                                <CredentialPill label={pills.experience.value} selected={true} />
                            )}
                            <AdditionalPills pills={pills} />
                        </InnerPillBox>
                    </PillBox>
                    <EditSide>
                        <Text
                            variant="caption"
                            color={Colors.darkNavy}
                            bold
                            onClick={goToPrevious}
                            textStyle={{ cursor: 'pointer' }}
                        >
                            Edit
                        </Text>
                    </EditSide>
                </SideBox>
            </InnerRow>
            {!!problems?.rate && problems?.rate === 'toolow' && (
                <Text variant="caption" color={Colors.error} textStyle={{ paddingTop: 10 }}>
                    The lowest pay allowed is ${minumumWageForState(state)}
                </Text>
            )}
            {!!problems?.rate && problems?.rate === 'bad' && (
                <Text variant="caption" color={Colors.error} textStyle={{ paddingTop: 10 }}>
                    Please input a valid rate
                </Text>
            )}
        </Row>
    );
}

const Row = styled('div')(({ small }: { small: string }) => ({
    backgroundColor: Colors.lightTurq,
    borderRadius: 10,
    padding: small ? '20px 20px' : '10px 20px',
    marginBottom: 20,
}));

const InnerRow = styled('div')(({ small }: { small: string }) => ({
    display: small === 'true' ? 'block' : 'flex',
}));

const PayBox = styled('div')(({ small }: { small: string }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: small === 'true' ? 0 : 10,
}));

const SideBox = styled('div')(({ small }: { small: string }) => ({
    display: small === 'true' ? 'block' : 'flex',
    minWidth: small === 'true' ? '100%' : '70%',
    justifyContent: 'space-between',
}));

const PillBox = styled('div')({
    height: 'fit-content',
    paddingLeft: 10,
});

const InnerPillBox = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
});

const EditSide = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    textDecorationLine: 'underline',
    cursor: 'pointer',
});
