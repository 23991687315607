import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Text, PrimaryButton, BasicDialog } from 'library';
import useLoading from 'library/hooks/useLoading';
import { ReferenceFormHeading, ReferenceFormBody } from './components/index';
import { getAutomatedReference } from './ProvideWorkerReferenceApi';
import { useFormStyles } from './constants';
import { AutomatedReference } from './models';

export default function ProvideWorkerReference() {
    const { loading, setLoading, LoadingSpinner } = useLoading();
    const styles = useFormStyles();

    const [automatedReference, setAutomatedReference] = useState<AutomatedReference | null>();
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [formOpenLoading, setFormOpenLoading] = useState<boolean>(false);
    const [openThankYouDialog, setOpenThankYouDialog] = useState<boolean>(false);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);

    const path = window?.location?.pathname;
    const uuid = path?.split('/')?.pop();

    useEffect(() => {
        if (uuid) {
            retrieveReference(uuid);
        }
    }, []);

    function retrieveReference(uuid: string) {
        setLoading(true);
        getAutomatedReference(uuid)
            .then((res) => {
                if (res.length) {
                    if (res[0].reference_text && res[0].relationship_to_worker) {
                        setIsCompleted(true);
                    } else {
                        setAutomatedReference(res[0]);
                    }
                }
            })
            .finally(() => setLoading(false));
    }

    function handleCloseThankYouDialog() {
        setOpenThankYouDialog(false);
        setIsCompleted(true);
    }

    if (isCompleted) {
        return (
            <Grid style={styles.formGrid}>
                <Text variant="h2" textStyle={styles.welcomeText}>
                    Thank you for providing a character reference!
                </Text>
                <Text variant="h2" textStyle={styles.welcomeText}>
                    If you think there has been an issue please contact support@join-tandem.com
                </Text>
            </Grid>
        );
    }

    return (
        <Grid container direction="column" style={styles.pageGrid}>
            <Grid item container direction="row" justify="center" style={styles.pageTitleGrid}>
                <Text variant="h1" textStyle={styles.headerText}>
                    Tandem Worker Reference Form
                </Text>
            </Grid>
            {loading ? (
                <LoadingSpinner />
            ) : automatedReference ? (
                <>
                    <ReferenceFormHeading
                        formOpen={formOpen}
                        setFormOpen={setFormOpen}
                        formOpenLoading={formOpenLoading}
                        setFormOpenLoading={setFormOpenLoading}
                        automatedReference={automatedReference}
                    />
                    {formOpen && (
                        <ReferenceFormBody
                            automatedReference={automatedReference}
                            setOpenThankYouDialog={setOpenThankYouDialog}
                        />
                    )}
                    <BasicDialog
                        closeButton
                        isOpen={openThankYouDialog}
                        onClose={handleCloseThankYouDialog}
                        dialogTitle={'Thank you for providing a character reference!'}
                    >
                        <PrimaryButton buttonStyle={styles.button} onClick={handleCloseThankYouDialog}>
                            Finish
                        </PrimaryButton>
                    </BasicDialog>
                </>
            ) : (
                <Grid style={styles.formGrid}>
                    <Text variant="h2" textStyle={styles.welcomeText}>
                        No corresponding worker found
                    </Text>
                    <Text variant="h2" textStyle={styles.welcomeText}>
                        Please check your url and/or contact support@join-tandem.com
                    </Text>
                </Grid>
            )}
        </Grid>
    );
}
