import React, { useState } from 'react';
import { BasicDialog, OutlinedDropdown, OutlinedTextInput, PrimaryButton, Text } from 'library';

export default function SuspendReasonModal({
    isModalOpen,
    onSuspend,
    onClose,
}: {
    isModalOpen: boolean;
    onSuspend: (reason: string, details: string) => void;
    onClose: () => void;
}) {
    const [reason, setReason] = useState('');
    const [details, setDetails] = useState('');

    function onSuspendClick() {
        onSuspend(reason, details);
        setReason('');
        setDetails('');
    }

    function onModalClose() {
        setReason('');
        setDetails('');
        onClose();
    }

    return (
        <BasicDialog closeButton isOpen={isModalOpen} onClose={onModalClose}>
            <div>
                <Text>Why is the user being suspended?</Text>
                <OutlinedDropdown
                    value={reason}
                    fields={[
                        { key: '', value: 'Select one' },
                        { key: 'REPEAT_NO_SHOWS', value: 'Repeat No Shows' },
                        { key: 'INSTANCE_AT_SPECIFIC_CENTER', value: 'Instance at Specific Center' },
                        { key: 'NOT_DEPENDABLE', value: 'Not Dependable' },
                        { key: 'DISRESPECTFUL_TO_TANDEM', value: 'Disrespectful to Tandem' },
                        { key: 'DISRESPECTFUL_TO_CENTER', value: 'Disrespectful to Center' },
                        { key: 'BAD_REVIEWS', value: 'Bad Reviews' },
                        { key: 'WALK_OUT', value: 'Walk Out' },
                        { key: 'CALLED_STATE', value: 'Called State' },
                        { key: 'CLOCKED_FRAUDULENT_HOURS', value: 'Clocked Fraudulent Hours' },
                        { key: 'PHYSICAL_ALTERCATION_WITH_KID', value: 'Physical Altercation with Kid' },
                        { key: 'MULTIPLE_CENTERS_BANNED', value: 'Multiple Centers Banned' },
                        { key: 'OTHER', value: 'Other' },
                    ]}
                    onChange={(x) => setReason(x.target.value)}
                />
                <Text textStyle={{ marginTop: 10 }}>Include other details</Text>
                <OutlinedTextInput
                    multiline={true}
                    value={details}
                    onChange={(event) => setDetails(event.target.value)}
                    rows={3}
                    style={{ width: '100%' }}
                />
                <PrimaryButton disabled={!reason} onClick={onSuspendClick} buttonStyle={{ marginTop: 10 }}>
                    Suspend
                </PrimaryButton>
            </div>
        </BasicDialog>
    );
}
