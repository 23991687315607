import React from 'react';

import { styled } from '@mui/material';
import { Colors, RadioGroup, Text } from 'library';
import { ReportProblemRounded } from '@mui/icons-material';

export function DCFQuestion({ selected, onChange }: { selected: boolean; onChange: () => void }) {
    return (
        <Question error={selected.toString()}>
            <div>
                <Text bold>Will you accept someone who is still finishing their DCF classes/exams?</Text>
                <Text variant="caption">
                    Note: All Tandem workers must complete DCF traininng within a year to maintain compliance.
                </Text>
                <RadioGroup
                    value={selected ? 'no' : 'yes'}
                    setValue={onChange}
                    values={{ yes: 'Yes', no: 'No' }}
                    horizontal
                    style={{ marginTop: 10 }}
                />
            </div>
            {selected && <ReportProblemRounded sx={{ height: 36, width: 36, color: Colors.error }} />}
        </Question>
    );
}

const Question = styled('div')(({ error }: { error?: string }) => ({
    alignItems: 'center',
    backgroundColor: error === 'true' ? '#fff5f5' : Colors.white,
    borderRadius: 10,
    border: error === 'true' ? `1px solid ${Colors.error}` : 'none',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 20px',
}));
