import React from 'react';
import { BasicDialog } from 'library';
import { DocumentToVerify, FormDetails } from './models';
import { IInfoModalDoc } from 'models';
import VerifyModalContent from './VerifyModalContent';

export default function VerifyModal({
    verifyModalOpen,
    setVerifyModalOpen,
    form,
    refresh,
    formDetails,
    activeWorker,
}: {
    verifyModalOpen: boolean;
    setVerifyModalOpen: (x: boolean) => void;
    form: DocumentToVerify | IInfoModalDoc | undefined;
    refresh?: () => void;
    formDetails?: FormDetails; // NOTE: Currently only used for the document type
    activeWorker: number;
}) {
    function onVerify() {
        if (refresh) {
            setVerifyModalOpen(false);
            refresh();
        }
    }

    const selectedDocumentType = (form as DocumentToVerify)?.document_types?.find(
        (dt) => dt.document_type_id === formDetails?.document_type_id,
    );

    return (
        <BasicDialog closeButton isOpen={verifyModalOpen} onClose={() => setVerifyModalOpen(false)}>
            <VerifyModalContent
                activeWorker={activeWorker}
                form={form}
                formDetails={formDetails}
                onVerify={onVerify}
                selectedDocumentType={selectedDocumentType}
            />
        </BasicDialog>
    );
}
