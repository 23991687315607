import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import theme from '../../../theme';
import { Colors } from '../../theme';

import type { MenuProps } from '@material-ui/core';

interface StyleProps {
    marginLeft?: boolean;
    marginRight?: boolean;
    noMarginBottom?: boolean;
    error?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: (props: StyleProps) => ({
        marginBottom: props.noMarginBottom ? 0 : 15,
        marginLeft: props.marginLeft ? 10 : 0,
        marginRight: props.marginRight ? 10 : 0,
        width: '100%',
        '& label.Mui-focused': {
            color: !props.error ? theme.palette.primary.main : theme.palette.error.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: !props.error ? theme.palette.primary.main : theme.palette.error.main,
        },
        '&:hover': {
            borderBottomColor: !props.error ? theme.palette.primary.main : theme.palette.error.main,
        },
    }),
}));

const ErrorText = withStyles({
    root: {
        color: theme.palette.error.main,
    },
})(FormHelperText);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: '10%',
        },
    },
};

interface DropdownProps extends StyleProps {
    selected: any;
    name?: string;
    error?: boolean;
    errorMessage?: string;
    native?: boolean;
    shrink?: boolean;
    disabled?: boolean;
    title?: string;
    fields?: any[];
    placeholder?: string;
    wrapText?: boolean;
    disabledUnderline?: boolean;
    style?: React.CSSProperties;
    multiple?: boolean;
    MenuOverrideProps?: Partial<MenuProps>;
    onChange: (
        event: React.ChangeEvent<{
            name?: string;
            value: any;
        }>,
        child?: React.ReactNode,
    ) => void;
}

export default function DropDown({
    selected,
    name,
    fields = [],
    native = false,
    onChange = () => {},
    error,
    shrink,
    disabled,
    title,
    placeholder,
    errorMessage,
    wrapText,
    disabledUnderline = false,
    style = {},
    multiple = false,
    MenuOverrideProps,
    ...props
}: DropdownProps) {
    const classes = useStyles({ ...props, error });

    const renderValue =
        selected === undefined && placeholder !== undefined
            ? () => <div style={{ color: Colors.grey }}>{placeholder}</div>
            : undefined;

    return native ? (
        <FormControl className={classes.root}>
            <InputLabel error={error} htmlFor="outlined-age-native-simple" shrink={shrink}>
                {title}
            </InputLabel>
            <Select
                native
                style={style}
                value={selected}
                onChange={onChange}
                label={title}
                inputProps={{
                    name: selected,
                    id: 'outlined-age-native-simple',
                }}
                disabled={disabled}
                disableUnderline={disabledUnderline}
                multiple={multiple}
            >
                {fields.map((field, index) => {
                    return (
                        <option key={index} value={field.key ? field.key : field}>
                            {field.value ? field.value : field}
                        </option>
                    );
                })}
            </Select>
            <ErrorText id="component-helper-text">{errorMessage}</ErrorText>
        </FormControl>
    ) : (
        <FormControl className={classes.root}>
            <InputLabel error={error} htmlFor="outlined-age-native-simple">
                {title}
            </InputLabel>
            <Select
                style={{ width: '100%', ...style }}
                value={selected === undefined && placeholder !== undefined ? 'unset' : selected}
                onChange={(e, c) => onChange(e, c)}
                label={title}
                inputProps={{
                    name: name ? name : selected,
                    id: name ? `outlined-age-native-simple-${name}` : 'outlined-age-native-simple',
                }}
                MenuProps={MenuOverrideProps ?? MenuProps}
                disabled={disabled}
                renderValue={renderValue}
                disableUnderline={disabledUnderline}
                multiple={multiple}
            >
                {fields.length > 0 && fields[0].constructor == Object
                    ? fields.map((field, index) => {
                          return (
                              <MenuItem
                                  key={index}
                                  value={field.key}
                                  style={{ whiteSpace: wrapText ? 'normal' : 'nowrap' }}
                              >
                                  {field.value}
                              </MenuItem>
                          );
                      })
                    : fields.map((field) => {
                          return (
                              <MenuItem
                                  key={field}
                                  value={field}
                                  style={{ whiteSpace: wrapText ? 'normal' : 'nowrap' }}
                              >
                                  {field}
                              </MenuItem>
                          );
                      })}
            </Select>
            <ErrorText id="component-helper-text">{errorMessage}</ErrorText>
        </FormControl>
    );
}
