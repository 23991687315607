import {
    DEGREE,
    ECE_DEGREE,
    PLACEHOLDER_HIGH_SCHOOL_CHOICE,
    NO_EDUCATION_REQUIRED_CHOICE,
    CDA,
    YEARS_EXPERIENCE,
    NEW_TO_CHILDCARE,
    CLUMPED_YOE,
    BOOL,
    FLORIDA,
} from '../constants';

import type { GenericChoice, GenericQualificationChoices, Qualification, QualificationOption } from '../types';

function formatEducationChoices(qualification: Qualification, state: string): GenericChoice[] {
    const options = qualification.options.flatMap((option: QualificationOption) => ({
        key: option.id,
        value: qualification.name === ECE_DEGREE && option.value !== CDA ? `ECE Related ${option.value}` : option.value,
        order: option.order,
        qualification_type: qualification.value_type,
        pay_scale_qualification: {
            value: null,
            option_id: option.id,
            qualification_id: qualification.id,
            name: qualification.name,
        },
    }));

    const baseChoice =
        state === FLORIDA
            ? { ...NO_EDUCATION_REQUIRED_CHOICE, order: -1, pay_scale_qualification: null }
            : { ...PLACEHOLDER_HIGH_SCHOOL_CHOICE, order: -1, pay_scale_qualification: null };

    if (qualification.name === DEGREE) {
        return [...options, baseChoice as GenericChoice];
    }

    return options;
}

function formatYearsExperienceChoices(qualification: Qualification): GenericChoice[] {
    return CLUMPED_YOE.map((choice: { key: number; value: string }, index: number) => ({
        key: choice.key,
        value: choice.value,
        order: index,
        qualification_type: qualification.value_type,
        pay_scale_qualification:
            choice.key === NEW_TO_CHILDCARE.key
                ? null
                : {
                      value: choice.key.toString(),
                      option_id: null,
                      qualification_id: qualification.id,
                      name: qualification.name,
                  },
    }));
}

function formatNonBooleanQualifications(
    qualification: Qualification,
    single: boolean,
): {
    qualification: Qualification;
    choices: GenericChoice[];
} {
    const choices = qualification.options.map((option: QualificationOption) => ({
        key: option.id,
        value: option.value,
        order: null,
        qualification_type: qualification.value_type,
        pay_scale_qualification: {
            value: null,
            option_id: option.id,
            qualification_id: qualification.id,
            name: qualification.name,
        },
    }));

    return single
        ? { qualification, choices: [{ key: -1, value: 'None', order: -1 } as GenericChoice, ...choices] }
        : { qualification, choices };
}

export function formatQualificationChoices(
    qualifications: Qualification[],
    state: string,
): GenericQualificationChoices {
    const normal: GenericQualificationChoices = {
        education: [],
        experience: [],
        boolean: [],
        multi: [],
        single: [],
    };

    qualifications.forEach((qualification: Qualification) => {
        switch (qualification.name) {
            case DEGREE:
            case ECE_DEGREE:
                normal.education = [...normal.education, ...formatEducationChoices(qualification, state)];
                break;
            case YEARS_EXPERIENCE:
                normal.experience = formatYearsExperienceChoices(qualification);
                break;
            default:
                switch (qualification.value_type) {
                    case 'BOOLEAN':
                        normal.boolean = [
                            ...normal.boolean,
                            {
                                key: qualification.id,
                                value: qualification.name,
                                order: null,
                                qualification_type: BOOL,
                                pay_scale_qualification: {
                                    value: 'true',
                                    option_id: null,
                                    qualification_id: qualification.id,
                                    name: qualification.name,
                                },
                            },
                        ];
                        break;
                    case 'SINGLE_SELECT':
                        normal.single = [...normal.single, formatNonBooleanQualifications(qualification, true)];
                        break;
                    case 'MULTI_SELECT':
                        normal.multi = [...normal.multi, formatNonBooleanQualifications(qualification, false)];
                        break;
                    default:
                        break;
                }
        }
    });

    return normal;
}
