import React, { useRef, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Message, MessageResponse } from 'models/Message';
import { client, consoleLogInDev } from 'shared';
import AuthModal from 'parent-portal/login/AuthModal';
import { useHistory } from 'react-router-dom';
import { useUserContext } from 'UserContext';
import { useWidth } from 'reusableComponents/useWidth';
import MobileMessageForm from './MobileMessageForm';
import WebMessageForm from './WebMessageForm';
import PaymentNotSetup from './PaymentNotSetup';
import MessageInput from './MessageInput';
import { IProvider } from './models';
import { UserContextUser } from 'models';
import { RequirePaymentSetupToChat } from 'environmentVariables';

interface ISitterChatProps {
    providerIdObject: IProvider;
    onMessageSent?: () => void;
    providerName?: string;
    completedBackgroundCheck: boolean;
    providerImage?: string;
}

const ProviderMessenger = ({ providerIdObject, onMessageSent, providerName, providerImage }: ISitterChatProps) => {
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const bottomOfThread = useRef<null | HTMLDivElement>(null);
    const [messages, setMessages] = useState<Message[]>([]);
    const [locked, setLocked] = useState(false);
    const [messageToSend, setMessageToSend] = useState('');
    const [requirePaymentSetupToChat, setRequirePaymentSetupToChat] = useState(false);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [showCompleteProfileDialog, setShowCompleteProfileDialog] = useState(false);
    const breakpoint = useWidth();
    const smallScreen = ['xs', 'sm'].includes(breakpoint);
    const mounted = useRef(true);

    const { user } = useUserContext();
    const paymentSetup = user?.payment_information;
    const isPaymentSetup = user?.customer_id || (!!paymentSetup && paymentSetup.length > 0);

    const history = useHistory<{ message: string; navToScreen: string; navToScreenText: string }>();

    useEffect(() => {
        if (history.location?.state?.message) {
            setMessageToSend(history.location.state.message);
        }
    }, []);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (RequirePaymentSetupToChat === 'true') {
            setRequirePaymentSetupToChat(true);
        }

        load()
            .then(() => poll())
            .catch(consoleLogInDev);
    }, [providerIdObject?.user?.id]);

    const load = async () => {
        if (providerIdObject) {
            client(`api/messages/refresh-thread/?one=${providerIdObject.user.id}&limit=20`).then(
                (result: MessageResponse) => {
                    setMessages(result?.messages?.reverse());
                    setLocked(result?.locked);
                },
            );
        }
    };

    const poll = async () => {
        while (mounted.current) {
            await load();
            await new Promise((r) => setTimeout(r, 5000));
        }
    };

    useEffect(() => {
        if (messagesEndRef && messagesEndRef.current) {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }

        if (bottomOfThread && bottomOfThread.current) {
            bottomOfThread.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const sendMessage = () => {
        if (providerIdObject) {
            setIsSendingMessage(true);
            const body = {
                sender: user?.id,
                reciever: providerIdObject.user.id,
                message: messageToSend,
                message_type: providerIdObject.user.id === 240 ? 'PARENT_PORTAL' : 'DURING_SHIFT_CHAT_MESSAGE',
                image: '',
                navigation_screen: history.location?.state?.navToScreen || undefined,
                navigation_button_text: history.location?.state?.navToScreenText || undefined,
            };

            client('api/messages/message-user/', { body })
                .then(() => {
                    onMessageSent?.();
                })
                .catch((e) => {
                    consoleLogInDev(e);
                })
                .finally(() => {
                    setIsSendingMessage(false);
                    setMessageToSend('');
                    load();
                });
        }
    };

    function ChatContent() {
        const formProps = {
            providerIdObject,
            providerName,
            messages: messages,
            locked: locked,
            bottomOfThread,
            user: user as UserContextUser,
            messageToSend,
            messagesEndRef,
            MessageInput: (
                <MessageInput
                    {...{
                        setMessageToSend,
                        messageToSend,
                        sendMessage,
                        isSendingMessage,
                        messagesEndRef,
                    }}
                />
            ),
            providerImage,
        };

        if (requirePaymentSetupToChat && !isPaymentSetup) {
            return <PaymentNotSetup providerName={providerName} />;
        }

        return smallScreen ? <MobileMessageForm {...formProps} /> : <WebMessageForm {...formProps} />;
    }

    return (
        <Grid container>
            {/* eslint-disable-next-line no-comments/disallowComments*/}
            {/* DO NOT CALL THIS AS A COMPONENT IT WILL CAUSE A LOSS OF FOCUS IN MSG INPUT*/}
            {ChatContent()}
            <AuthModal
                location="Third Party Signup"
                open={showCompleteProfileDialog}
                onComplete={() => {
                    setShowCompleteProfileDialog(false);
                }}
                onClose={() => {
                    setShowCompleteProfileDialog(false);
                }}
                redirect={false}
                signup
            />
        </Grid>
    );
};

export default ProviderMessenger;
