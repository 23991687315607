import React, { Component, Suspense, lazy } from 'react';

import '@date-io/moment';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import { CookiesProvider } from 'react-cookie';
import { track } from './shared/Tracking';
import ApiClient from './shared/ApiClient';
import './fonts.css';

import { UserProvider, UserContext } from './UserContext';
import { FeatureFlagProvider } from 'shared/FeatureFlagContext';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';

const VerifyMfa = lazy(() => import('./parent-portal/login/VerifyMfa'));
import ChangePassword from './parent-portal/login/ChangePassword';
import VerificationCode from './parent-portal/login/VerificationCode';
import Home from './parent-portal/home/HomeDashboard';
import PublicFooter from './parent-portal/shared/PublicFooter';
import ChatAndHelpCenter from './parent-portal/shared/ChatAndHelpCenter';
import SearchHelpCenter from './parent-portal/shared/SearchHelpCenter';
import Profile from './parent-portal/profile/Profile';
import AbbreviatedSignup from './parent-portal/login/AbbreviatedSignup';
import Messages from './parent-portal/messages/Messages';
import UpcomingJobsPage from './parent-portal/jobs/upcoming/MyJobsPage';
import Payments from './parent-portal/payments/Payments';
import HttpsRedirect from 'react-https-redirect';
import Providers from './parent-portal/sitters/Providers';
import ProviderProfile from './parent-portal/provider-profile/ProviderProfile';
import BusinessProfile from './parent-portal/business-profile/BusinessProfile';
import SubAvailability from './parent-portal/subs/SubAvailability';
import NotificationPage from 'parent-portal/notifications/NotificationPage/NotificationPage';
import ReviewProvider from 'parent-portal/provider-reviews/ReviewProvider';
import ConfirmSubHours from 'parent-portal/subs/ConfirmSubHours';
import Onboarding from './parent-portal/home/onboarding/Onboarding';
import MarketingHome from './parent-portal/public/MarketingSite/home';
import ConfirmEmploymentHistory from './sitter-portal/confirm-employment-history/ConfirmEmploymentHistory';

const DownloadShiftsAttachments = lazy(() => import('parent-portal/jobs/upcoming/DownloadShiftsAttachments'));

import SitterLogin from './sitter-portal/login/Login';
import SitterDashboard from './sitter-portal/SitterDashboard';
import ProvideWorkerReference from './sitter-portal/provide-worker-reference/ProvideWorkerReference';

import NotFound from './internal/shared/NotFound';

import EmaiVerificationPage from './parent-portal/public/EmailVerificationPage';
import BizProviderPartialSignUp from './parent-portal/public/BizProviderPartialSignUp';
import UniversalLinkingPage from './parent-portal/public/UniversalLinkingPage';
import RedirectToAppJobDetails from './parent-portal/public/RedirectToAppJobDetails';

import ProviderRedirect from 'parent-portal/provider-profile/ProviderRedirect';
import OnDemandLandingPage from './parent-portal/public/on-demand/OnDemandLandingPage';
import { ModalsContextProvider } from 'ModalsContext';
import { NotificationContextProvider } from 'parent-portal/notifications/NotificationContext/NotificationContext';

import UnsupportedRegionPage from 'parent-portal/blockers/SupportedRegions/UnsupportedRegion/UnsupportedRegionPage';
import { Environment, GoogleAnalytics, GoogleTagManager1, GoogleTagManager2 } from 'environmentVariables';
import SendEmail from 'internal/outreach/email/SendEmail';
import AddUnsubscribes from 'internal/outreach/email/AddUnsubscribes';
import { AdminAppContextProvider, AdminAppContext } from 'internal/shared/AdminAppContext';
import ClockInQRCode from 'parent-portal/payments/Components/business/ClockInQRCode';
import BusinessLocationPage from 'parent-portal/BusinessLocation/BusinessLocationPage';
import BusinessJob from 'parent-portal/jobs/upcoming/BusinessJob/BusinessJob';
import PrivacyPolicy from 'parent-portal/shared/Footer/PrivacyPolicy';
import TermsOfUse from 'parent-portal/shared/Footer/TermsOfUse';
import ProviderTermsOfUse from 'parent-portal/shared/Footer/ProviderTermsOfUse';
import CenterTermsOfUse from 'parent-portal/shared/Footer/CenterTermsOfUse';
import FamilyTermsOfUse from 'parent-portal/shared/Footer/FamilyTermsOfUse';
import Fees from 'parent-portal/shared/Footer/Fees';
import IndeedJobListing from 'internal/outreach/indeed/IndeedJobListing';
import TrialRunLandingPage from 'parent-portal/public/MarketingSite/TrialRun/TrialRunLandingPage';
import { Public } from './shared/Public';
import { Private } from './shared/Private';
import AutobookHistoryPage from 'internal/booking/AutobookHistoryPage';
import HowToDeleteAcct from 'parent-portal/public/HowToDeleteAcct';
import AboutUs from 'parent-portal/public/MarketingSite/AboutUs';
import HowItWorks from 'parent-portal/public/MarketingSite/HowItWorks';
import { Substitutes as MarketingSubstitutes } from 'parent-portal/public/MarketingSite/substitutes';
import { Workers } from 'parent-portal/public/MarketingSite/workers';
import HotConversationNotifications from 'internal/texting/outreach/HotConversationNotifications';
import DraggablePhoneDialog from 'internal/business-locations/DraggablePhoneDialog';
import DraggableNotesDialog from 'internal/shared/tables/DraggableNotesDialog';

const AdminDashboard = lazy(() => import('./internal/home/AdminDashboard'));
const DailyApplicantFeed = lazy(() => import('./internal/applicants/DailyApplicantFeed'));
const SubTextingScreen = lazy(() => import('./internal/texting/SubTextingScreen'));
const Pairings = lazy(() => import('./internal/calendar/PairingForm'));
const AdminLogin = lazy(() => import('./internal/login/Login'));
const SubPairsScreen = lazy(() => import('./internal/calendar/SubPairsScreen'));
const PushNotificationScreen = lazy(() => import('./internal/pushnotification/PushNotificationScreen'));
const MassTextScreen = lazy(() => import('./internal/mass-text/MassTextScreen'));
const MassEmailScreen = lazy(() => import('./internal/mass-email/MassEmailScreen'));
const Impersonate = lazy(() => import('./internal/impersonate/Impersonate'));
const SubSitterTextingScreen = lazy(() => import('./internal/texting/SubSitterTextingScreen'));
const Calendar = lazy(() => import('./internal/calendar/Calendar'));
const AdminPaymentsDashboard = lazy(() => import('./internal/payments/Dashboard'));
const HoursReported = lazy(() => import('./internal/payments/HoursReported'));
const Customer = lazy(() => import('./internal/payments/Customer'));
const Customers = lazy(() => import('./internal/payments/Customers'));
const Invoice = lazy(() => import('./internal/payments/Invoice'));
const Invoices = lazy(() => import('./internal/payments/Invoices'));
const PayStub = lazy(() => import('./internal/payments/PayStub'));
const PayStubs = lazy(() => import('./internal/payments/PayStubs'));
const GroupPays = lazy(() => import('./internal/payments/GroupPays'));
const PQWebhooks = lazy(() => import('./internal/payments/PQWebhooks'));
const TPMigrateActions = lazy(() => import('./internal/payments/TPMigrateActions'));
const Upload1099Users = lazy(() => import('./internal/payments/Upload1099Users'));
const PairingTexting = lazy(() => import('./internal/texting/PairingTexting'));
const AdminMessageTable = lazy(() => import('./internal/texting/Messages'));
const BusinessMessageTable = lazy(() => import('./internal/texting/BusinessMessageTable'));
const NewOutreachView = lazy(() => import('./internal/texting/NewOutreachView'));
const AdminMessenger = lazy(() => import('./internal/messenger'));
const OutreachAdd = lazy(() => import('./internal/texting/OutreachAdd'));
const Advances = lazy(() => import('./internal/payments/Advances'));
const AdvanceDetails = lazy(() => import('./internal/payments/AdvanceDetails'));
const ApproveHours = lazy(() => import('./internal/payments/ApproveHours'));
const PayProvider = lazy(() => import('./internal/payments/PayProvider'));
const EmailOutreach = lazy(() => import('./internal/outreach/email/EmailOutreach'));
const AdminMessages = lazy(() => import('./internal/texting/AdminMessages'));
const BlockedPayStubs = lazy(() => import('./internal/payments/BlockedPayStubs'));
const BlockedInvoices = lazy(() => import('./internal/payments/BlockedInvoices'));
const StripeReviews = lazy(() => import('./internal/payments/StripeReviews'));
const StripeReviewDetails = lazy(() => import('./internal/payments/StripeReviewDetails'));
const FlagReview = lazy(() => import('./internal/flags/FlagReview'));
const ApplicantApproval = lazy(() => import('./internal/applicants/ApplicantApproval'));
const ShareProvider = lazy(() => import('./internal/providers/ShareProvider'));
const ManageScheduledJobs = lazy(() => import('./internal/providers/ManageScheduledJobs'));
const ProviderDocumentUpload = lazy(() => import('./internal/texting/outreach/documents/ProviderDocumentUpload'));
const Businesses = lazy(() => import('./internal/business/Businesses'));
const BusinessDownload = lazy(() => import('./internal/business/BusinessDownload'));
const RegionMap = lazy(() => import('./internal/business/RegionMap'));
const BusinessMap = lazy(() => import('./internal/business/BusinessMap'));
const ProviderMap = lazy(() => import('./internal/business/ProviderMap'));
const AdminProviders = lazy(() => import('./internal/providers/Providers'));
const VettingPipeline = lazy(() => import('./internal/vetting-pipeline/VettingPipeline'));
const HighlightJobs = lazy(() => import('internal/business/HighlightJobs'));
const SalesLeads = lazy(() => import('internal/salesLeads/SalesLeads'));
const Substitutes = lazy(() => import('internal/substitutes/Substitutes'));
const VerifyDocuments = lazy(() => import('internal/document-verify/VerifyDocuments'));
const VerifyBackgroundChecks = lazy(() => import('internal/document-verify/VerifyBackgroundChecks'));
const ConfirmSesameAppts = lazy(() => import('internal/sesame/ConfirmSesameAppts'));
const IndeedJobPost = lazy(() => import('internal/outreach/indeed/IndeedJobPost'));
const LabelLossReasons = lazy(() => import('internal/label-loss-reasons/LabelLossReasons'));
const Disputes = lazy(() => import('internal/disputes/Disputes'));
const RideShares = lazy(() => import('internal/rideshares'));
const Demo = lazy(() => import('internal/demo/Demo'));
const CollectDocumentInfo = lazy(() => import('internal/compliance/collect_document_info/CollectDocumentInfo'));
const SuspensionFlags = lazy(() => import('internal/suspension-flags/SuspensionFlags'));
const Provider = lazy(() => import('internal/providers/Provider'));
const BusinessLocation = lazy(() => import('internal/business-location/BusinessLocation'));
const BusinessLocations = lazy(() => import('internal/business-locations/BusinessLocations'));
const CombineLocations = lazy(() => import('internal/combine-locations/CombineLocations'));
const GmailAuth = lazy(() => import('internal/gmail-auth/GmailAuth'));
const TasksPage = lazy(() => import('internal/TaskList/TasksPage'));
const ViewQualifications = lazy(() => import('internal/qualification/ViewQualifications'));
const ChatwootContactPage = lazy(() => import('internal/support/ChatwootContactPage'));
const ChatwootContactLookup = lazy(() => import('internal/support/ChatwootContactLookup'));
const HourReports = lazy(() => import('internal/payments/HourReports'));
const HourReport = lazy(() => import('internal/payments/HourReport'));
const JobHistoryPage = lazy(() => import('internal/job-history/JobHistoryPage'));
const WorkerMatching = lazy(() => import('internal/substitutes/Matching/ReverseMatching/WorkerMatching'));
const FileBackgroundCheck = lazy(() => import('internal/background-check/FileBackgroundCheck'));
const HealthCheckPage = lazy(() => import('internal/health-check/HealthCheckPage'));
const Dependability = lazy(() => import('internal/dependability/Dependability'));

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getDerivedStateFromError(_error) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <NotFound error={true} />;
        }
        return this.props.children;
    }
}

const Authorization = (allowedRoles) => (WrappedComponent) =>
    class WithAuthorization extends React.Component {
        render() {
            return (
                <UserContext.Consumer>
                    {(context) => {
                        const role = context.role;
                        if (role) {
                            if (allowedRoles.includes(role)) {
                                if (role === 'admin') {
                                    return (
                                        <AdminAppContextProvider>
                                            <AdminAppContext.Consumer>
                                                {(adminContext) => {
                                                    const { phoneDialogProps, notesDialogProps } = adminContext;
                                                    return (
                                                        <>
                                                            <DraggableNotesDialog {...notesDialogProps} />
                                                            <DraggablePhoneDialog {...phoneDialogProps} />
                                                            <WrappedComponent {...this.props} />
                                                            <HotConversationNotifications />
                                                        </>
                                                    );
                                                }}
                                            </AdminAppContext.Consumer>
                                        </AdminAppContextProvider>
                                    );
                                } else if (role === 'family' || role === 'business' || role === 'business_active') {
                                    return (
                                        <NotificationContextProvider>
                                            <WrappedComponent {...this.props} />
                                            <ChatAndHelpCenter />
                                            <SearchHelpCenter />
                                            <PublicFooter />
                                        </NotificationContextProvider>
                                    );
                                } else if (role === 'marketer' || role === 'sitter') {
                                    return <WrappedComponent {...this.props} />;
                                } else {
                                    if (role === 'admin') {
                                        return <Redirect to="/adminperson/business" />;
                                    } else if (role === 'marketer') {
                                        return <Redirect to="/adminperson/outreach/directory/add" />;
                                    }
                                    return <NotFound error={false} />;
                                }
                            } else {
                                return null;
                            }
                        }
                    }}
                </UserContext.Consumer>
            );
        }
    };

const Family = Authorization(['family', 'business', 'business_active']);
const Admin = Authorization(['admin']);
const Sitter = Authorization(['sitter', 'admin']);

function attemptOtpLogin(otp, path) {
    return ApiClient(`api/rest-auth/otp/?otp=${otp}&path=${path}`)
        .then((response) => {
            localStorage.setItem('token', response.key);
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
        });
}

class App extends Component {
    constructor(props) {
        super(props);
    }

    initializeGoogle = () => {
        if (Environment === 'production') {
            const tagManagerArgsGT1 = {
                gtmId: GoogleTagManager1,
            };
            if (tagManagerArgsGT1) {
                TagManager.initialize(tagManagerArgsGT1);
            }

            const tagManagerArgsGT2 = {
                gtmId: GoogleTagManager2,
            };

            if (tagManagerArgsGT2) {
                TagManager.initialize(tagManagerArgsGT2);
            }

            const ga = GoogleAnalytics;
            if (ga) {
                ReactGA.initialize(ga);
            }
        }
    };

    componentDidMount = async () => {
        this.initializeGoogle();
        this.token = localStorage.getItem('token');

        if (window.location.href.indexOf('/#/') > -1) {
            window.location = window.location.href.replace('/#/', '/');
            return;
        }

        this.originalLocation = this.props.history.location;
        const fromLocation = this.props.history.location?.state?.from;
        const queryParams = new URLSearchParams(this.originalLocation.search);
        if (!this.token && queryParams.has('otp')) {
            await attemptOtpLogin(queryParams.get('otp'), fromLocation?.pathname);
            this.token = localStorage.getItem('token');

            queryParams.delete('otp');
            if (fromLocation) {
                const queryStr = queryParams.toString();
                const newPath = queryStr ? `${fromLocation.pathname}?${queryStr}` : fromLocation.pathname;
                this.props.history.replace(newPath);
            } else {
                this.props.history.push(`${this.originalLocation.pathname}?${queryParams.toString()}`);
            }
            window.location.reload();
        }

        track('Visted Page', {
            pageName: this.props.history.location.pathname,
        });

        if (Environment === 'production') {
            ReactGA.send({ hitType: 'pageview', page: this.props.history.location.pathname });
        }

        let screenTimer = Date.now();
        let currentPage = this.props.history.location.pathname;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.unlisten = this.props.history.listen((location, _action) => {
            track('Visted Page', {
                pageName: location.pathname,
            });

            track('Time Spent On Page', {
                pageName: currentPage,
                duration: (Date.now() - screenTimer) / 1000,
            });

            currentPage = location.pathname;
            screenTimer = Date.now();
        });
    };

    componentWillUnmount() {
        this.unlisten();
    }

    everything() {
        return (
            <UserProvider>
                <FeatureFlagProvider>
                    <ModalsContextProvider>
                        <ThemeProvider theme={theme}>
                            <CookiesProvider>
                                <HttpsRedirect>
                                    <ErrorBoundary>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <Switch>
                                                <Private
                                                    exact
                                                    path="/adminperson/dashboard"
                                                    component={Admin(AdminDashboard)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/impersonate"
                                                    component={Admin(Impersonate)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/flag-review"
                                                    component={Admin(FlagReview)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/applicant-approval"
                                                    component={Admin(ApplicantApproval)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/daily-applicant-feed"
                                                    component={Admin(DailyApplicantFeed)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/share-provider"
                                                    component={Admin(ShareProvider)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/scheduled-jobs/:user_id"
                                                    component={Admin(ManageScheduledJobs)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/upload-documents/:user_id"
                                                    component={Admin(ProviderDocumentUpload)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/dashboard"
                                                    component={Admin(AdminPaymentsDashboard)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/hours-reported"
                                                    component={Admin(HoursReported)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/customers"
                                                    component={Admin(Customers)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/customers/:id"
                                                    component={Admin(Customer)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/invoices"
                                                    component={Admin(Invoices)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/invoices/:id"
                                                    component={Admin(Invoice)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/reviews"
                                                    component={Admin(StripeReviews)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/reviews/:id"
                                                    component={Admin(StripeReviewDetails)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/blocked/invoices"
                                                    component={Admin(BlockedInvoices)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/blocked/paystubs"
                                                    component={Admin(BlockedPayStubs)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/paystubs"
                                                    component={Admin(PayStubs)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/paystubs/:id"
                                                    component={Admin(PayStub)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/approve-hours"
                                                    component={Admin(ApproveHours)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/group-pays"
                                                    component={Admin(GroupPays)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/pq-hooks"
                                                    component={Admin(PQWebhooks)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/tp-migrate-actions"
                                                    component={Admin(TPMigrateActions)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/hour-reports"
                                                    component={Admin(HourReports)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/hour-reports/:id"
                                                    component={Admin(HourReport)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/upload-1099"
                                                    component={Admin(Upload1099Users)}
                                                />

                                                <Private
                                                    exact
                                                    path="/adminperson/pairings/:id"
                                                    component={Admin(SubPairsScreen)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/texting/:id"
                                                    component={Admin(SubTextingScreen)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/sitter-texting/:id"
                                                    component={Admin(SubSitterTextingScreen)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/messages"
                                                    component={Admin(AdminMessages)}
                                                />

                                                <Private
                                                    exact
                                                    path="/adminperson/pairings"
                                                    component={Admin(Pairings)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/label-loss-reasons"
                                                    component={Admin(LabelLossReasons)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/push-notifications"
                                                    component={Admin(PushNotificationScreen)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/mass-text"
                                                    component={Admin(MassTextScreen)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/mass-email"
                                                    component={Admin(MassEmailScreen)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/message-table"
                                                    component={Admin(AdminMessageTable)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/business-message-table"
                                                    component={Admin(BusinessMessageTable)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/outreach/email"
                                                    component={Admin(EmailOutreach)}
                                                />

                                                <Private
                                                    exact
                                                    path="/adminperson/outreach-new"
                                                    component={Admin(NewOutreachView)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/messenger"
                                                    component={Admin(AdminMessenger)}
                                                />
                                                <Private exact path="/adminperson/email" component={Admin(SendEmail)} />
                                                <Private
                                                    exact
                                                    path="/adminperson/unsubscribe"
                                                    component={Admin(AddUnsubscribes)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/outreachadd"
                                                    component={Admin(OutreachAdd)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/pairing-texts/:id"
                                                    component={Admin(PairingTexting)}
                                                />

                                                <Private
                                                    exact
                                                    path="/adminperson/payments/advances"
                                                    component={Admin(Advances)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/advances/:id"
                                                    component={Admin(AdvanceDetails)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/payments/pay-provider/"
                                                    component={Admin(PayProvider)}
                                                />
                                                <Public exact path="/adminperson/signin">
                                                    {localStorage.getItem('token') === null ? (
                                                        <AdminLogin />
                                                    ) : (
                                                        <Redirect to="/adminperson/businesses" />
                                                    )}
                                                </Public>
                                                <Private
                                                    exact
                                                    path="/adminperson/calendar"
                                                    component={Admin(Calendar)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/businesses"
                                                    component={Admin(Businesses)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/businesses/download"
                                                    component={Admin(BusinessDownload)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/documents-verify"
                                                    component={Admin(VerifyDocuments)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/background-check-verify"
                                                    component={Admin(VerifyBackgroundChecks)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/confirm-sesame-appts"
                                                    component={Admin(ConfirmSesameAppts)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/compliance/collect-document-info/:id"
                                                    component={Admin(CollectDocumentInfo)}
                                                />

                                                <Private
                                                    exact
                                                    path="/adminperson/region-map"
                                                    component={Admin(RegionMap)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/biz-map"
                                                    component={Admin(BusinessMap)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/provider-map"
                                                    component={Admin(ProviderMap)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/vetting-pipeline"
                                                    component={Admin(VettingPipeline)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/highlight-jobs"
                                                    component={Admin(HighlightJobs)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/sales-leads"
                                                    component={Admin(SalesLeads)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/substitutes"
                                                    component={Admin(Substitutes)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/disputes"
                                                    component={Admin(Disputes)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/flagged-for-suspension"
                                                    component={Admin(SuspensionFlags)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/rideshares"
                                                    component={Admin(RideShares)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/providers"
                                                    component={Admin(AdminProviders)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/cw-contact-lookup"
                                                    component={Admin(ChatwootContactLookup)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/outreach/indeed-post"
                                                    component={Admin(IndeedJobPost)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/autobook-history"
                                                    component={AutobookHistoryPage}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/provider/:id"
                                                    component={Admin(Provider)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/business-location/:id"
                                                    component={Admin(BusinessLocation)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/business-locations"
                                                    component={Admin(BusinessLocations)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/combine-locations"
                                                    component={Admin(CombineLocations)}
                                                />

                                                <Private
                                                    exact
                                                    path="/adminperson/gmail-auth"
                                                    component={Admin(GmailAuth)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/gsync/callback"
                                                    component={Admin(GmailAuth)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/qualifications"
                                                    component={Admin(ViewQualifications)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/chatwoot/contacts"
                                                    component={Admin(ChatwootContactPage)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/dependability"
                                                    component={Admin(Dependability)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/job-history/:id"
                                                    component={Admin(JobHistoryPage)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/worker-matching"
                                                    component={Admin(WorkerMatching)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/file-background-check"
                                                    component={FileBackgroundCheck}
                                                />
                                                <Private exact path="/adminperson/tasks" component={Admin(TasksPage)} />
                                                <Public exact path="/indeed-job/:id" component={IndeedJobListing} />
                                                <Private
                                                    exact
                                                    path="/family/:id/verify"
                                                    component={AbbreviatedSignup}
                                                />
                                                <Private exact path="/request">
                                                    {<Redirect to="/home" />}
                                                </Private>
                                                <Private exact path="/home" component={Family(Home)} />
                                                <Private exact path="/get-started" component={Family(Onboarding)} />
                                                <Private exact path="/profile" component={Family(Profile)} />
                                                <Private
                                                    exact
                                                    path="/profile/locations/:id"
                                                    component={Family(BusinessLocationPage)}
                                                />
                                                <Private
                                                    exact
                                                    path="/providers/view/:id"
                                                    component={Family(ProviderRedirect)}
                                                />
                                                <Private
                                                    exact
                                                    path="/providers/view/"
                                                    component={Family(ProviderRedirect)}
                                                />
                                                <Private
                                                    exact
                                                    path="/providers/review/:id"
                                                    component={Family(ReviewProvider)}
                                                />
                                                <Private
                                                    exact
                                                    path="/provider/:id"
                                                    component={Family(ProviderProfile)}
                                                />
                                                <Public exact path="/businesses/:id" component={BusinessProfile} />
                                                <Private exact path="/provider" component={Family(ProviderProfile)} />
                                                <Private exact path="/payments" component={Family(Payments)} />
                                                <Private exact path="/jobs" component={Family(UpcomingJobsPage)} />
                                                <Private exact path="/jobs/:id" component={Family(BusinessJob)} />

                                                <Private
                                                    exact
                                                    path="/download-shifts-attachments"
                                                    component={Family(DownloadShiftsAttachments)}
                                                />
                                                <Private exact path="/messages" component={Family(Messages)} />
                                                <Private exact path="/messages/:id" component={Family(Messages)} />
                                                <Private exact path="/providers" component={Family(Providers)} />
                                                <Private exact path="/providers/preview/:id?" component={Family()} />
                                                <Private
                                                    exact
                                                    path="/sub-availability"
                                                    component={Family(SubAvailability)}
                                                />
                                                <Private exact path="/clock-in" component={Family(ClockInQRCode)} />
                                                <Public
                                                    exact
                                                    path="/signin/change/password/verify"
                                                    component={VerificationCode}
                                                />
                                                <Public exact path="/trial-runs" component={TrialRunLandingPage} />
                                                <Public
                                                    exact
                                                    path="/signin/create/password/verify"
                                                    component={VerificationCode}
                                                />
                                                <Public exact path="/login/verify" component={VerifyMfa} />
                                                <Private
                                                    exact
                                                    path="/signin/change/password"
                                                    component={ChangePassword}
                                                />
                                                <Private
                                                    exact
                                                    path="/confirm-hours"
                                                    component={Family(ConfirmSubHours)}
                                                />
                                                <Private
                                                    exact
                                                    path="/sitter/home"
                                                    component={Sitter(SitterDashboard)}
                                                />
                                                <Private
                                                    exact
                                                    path="/notifications"
                                                    component={Family(NotificationPage)}
                                                />
                                                <Private
                                                    exact
                                                    path="/adminperson/health-check"
                                                    component={Admin(HealthCheckPage)}
                                                />
                                                <Public exact path="/sitter/signin">
                                                    {localStorage.getItem('token') === null ? (
                                                        <SitterLogin />
                                                    ) : (
                                                        <Redirect to="/sitter/payment" />
                                                    )}
                                                </Public>
                                                <Public exact path="/sitter">
                                                    {localStorage.getItem('token') === null ? (
                                                        <SitterLogin />
                                                    ) : (
                                                        <Redirect to="/sitter/payment" />
                                                    )}
                                                </Public>
                                                <Public exact path="/business" component={OnDemandLandingPage} />
                                                <Public
                                                    exact
                                                    path="/verify-email/:id"
                                                    component={EmaiVerificationPage}
                                                />
                                                <Public
                                                    exact
                                                    path="/providers/business-signup"
                                                    component={BizProviderPartialSignUp}
                                                />
                                                <Public
                                                    exact
                                                    path="/providers/home"
                                                    component={BizProviderPartialSignUp}
                                                />
                                                <Public
                                                    exact
                                                    path="/provide-reference/:id"
                                                    component={ProvideWorkerReference}
                                                />
                                                <Public
                                                    exact
                                                    path="/confirm-employment/:id"
                                                    component={ConfirmEmploymentHistory}
                                                />
                                                <Public exact path="/gotandem/" component={UniversalLinkingPage} />
                                                <Public
                                                    exact
                                                    path="/gotandem/:id"
                                                    component={RedirectToAppJobDetails}
                                                />

                                                <Public exact path="/tandem-staffing">
                                                    {function AboutUs() {
                                                        window.location.assign(
                                                            'https://info.join-tandem.com/tandem-staffing',
                                                        );
                                                        return null;
                                                    }}
                                                </Public>

                                                <Public
                                                    exact
                                                    path="/tandemblog/:id"
                                                    component={() => {
                                                        const current_url = window.location.href;
                                                        window.location.assign(
                                                            'https://info.join-tandem.com/tandemblog/' +
                                                                current_url.split('/').pop(),
                                                        );
                                                        return null;
                                                    }}
                                                />

                                                <Public exact path="/find-child-care-center-jobs">
                                                    {() => {
                                                        window.location.assign(
                                                            'https://info.join-tandem.com/find-child-care-center-jobs',
                                                        );
                                                        return null;
                                                    }}
                                                </Public>
                                                <Public exact path="/tandem-pay">
                                                    {() => {
                                                        window.location.assign(
                                                            'https://info.join-tandem.com/tandem-pay',
                                                        );
                                                        return null;
                                                    }}
                                                </Public>
                                                <Public exact path="/find-staff-permanent">
                                                    {() => {
                                                        window.location.assign(
                                                            'https://info.join-tandem.com/find-staff-permanent',
                                                        );
                                                        return null;
                                                    }}
                                                </Public>
                                                <Public exact path="/meet-the-team">
                                                    {() => {
                                                        window.location.assign(
                                                            'https://info.join-tandem.com/meet-the-team',
                                                        );
                                                        return null;
                                                    }}
                                                </Public>
                                                <Public exact path="/privacy-policy" component={PrivacyPolicy} />
                                                <Public exact path="/tandem-terms-of-use" component={TermsOfUse} />
                                                <Public
                                                    exact
                                                    path="/tandem-terms-of-use/provider"
                                                    component={ProviderTermsOfUse}
                                                />
                                                <Public
                                                    exact
                                                    path="/tandem-terms-of-use/center"
                                                    component={CenterTermsOfUse}
                                                />
                                                <Public
                                                    exact
                                                    path="/tandem-terms-of-use/family"
                                                    component={FamilyTermsOfUse}
                                                />
                                                <Public exact path="/tandem-pay/fees" component={Fees} />
                                                <Public exact path="/childcare-center-jobs/faq">
                                                    {() => {
                                                        window.location.assign(
                                                            'https://info.join-tandem.com/childcare-center-jobs/faq',
                                                        );
                                                        return null;
                                                    }}
                                                </Public>
                                                <Public exact path="/how-it-works" component={HowItWorks} />
                                                <Public exact path="/substitutes" component={MarketingSubstitutes} />
                                                <Public exact path="/about-us" component={AboutUs} />
                                                <Public exact path="/workers" component={Workers} />

                                                <Public exact path="/delete-account" component={HowToDeleteAcct} />
                                                <Public
                                                    exact
                                                    path="/unsupportedregion"
                                                    component={UnsupportedRegionPage}
                                                />
                                                {localStorage.getItem('token') === null ? (
                                                    <Public exact path="/" component={MarketingHome} />
                                                ) : (
                                                    <Public exact path="/" component={<></>}>
                                                        <Redirect to="/home" />
                                                    </Public>
                                                )}
                                                <Public exact path="/adminperson">
                                                    {localStorage.getItem('token') === null ? (
                                                        <Redirect to="/adminperson/signin" />
                                                    ) : (
                                                        <Redirect to="/adminperson/businesses" />
                                                    )}
                                                </Public>
                                                <Private exact path="/adminperson/demo" component={Admin(Demo)} />
                                                <Public component={() => <NotFound error={false} />} />
                                            </Switch>
                                        </Suspense>
                                    </ErrorBoundary>
                                </HttpsRedirect>
                            </CookiesProvider>
                        </ThemeProvider>
                    </ModalsContextProvider>
                </FeatureFlagProvider>
            </UserProvider>
        );
    }

    render() {
        return <>{this.everything()}</>;
    }
}

export default withRouter(App);
