import React from 'react';
import { Colors } from 'library';
import { Grid } from '@material-ui/core';
import { useWidth } from 'reusableComponents/useWidth';
import { IRole } from 'models';
import { ProviderInfo } from './components/ProviderInfo';
interface IProvider {
    user: {
        id: number;
    };
}

interface IProps {
    providerIdObject: IProvider;
    providerName: string;
    role?: IRole;
}

export const ThreadHeader = ({ providerIdObject, providerName }: IProps) => {
    const breakpoint = useWidth();
    const smallScreen = ['xs', 'sm'].includes(breakpoint);

    return (
        <Grid container>
            <Grid
                style={{
                    backgroundColor: Colors.mediumTurq,
                    borderTopLeftRadius: smallScreen ? '0px' : '18px',
                    borderTopRightRadius: smallScreen ? '0px' : '18px',
                    padding: 15,
                }}
                container
                alignItems="center"
                justify="space-between"
            >
                <ProviderInfo providerIdObject={providerIdObject} providerName={providerName} />
            </Grid>
        </Grid>
    );
};
