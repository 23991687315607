import React, { useEffect } from 'react';
import theme from 'theme';
import useForm from 'parent-portal/forms/components/FormHook';
import { useMediaQuery } from '@mui/material';
import { SizeableRoundedDialog } from 'library';
import { RequirementsAndPayProvider } from 'parent-portal/forms/components/pages/RequirementsAndPayPage/context';

import InnerEditPayModal from './InnerEditPayModal';
import type { IPayFormFields, IPayScale } from 'parent-portal/forms/JobRequestTypes';

import type { BusinessJobResponse } from './models';

export default function EditPayModal({
    open,
    onClose,
    businessJobResponse,
    onSaveComplete,
}: {
    open: boolean;
    onClose: () => void;
    businessJobResponse: BusinessJobResponse;
    onSaveComplete: () => void;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const form = useForm<{
        pay: IPayFormFields;
        payScales: IPayScale[];
    }>({
        pay: initPayValues(),
        payScales: businessJobResponse.job_details.pay_scales,
    });

    function initPayValues() {
        return {
            payFixed: businessJobResponse.job_details.pay || null,
            payMax: businessJobResponse.job_details.rate_max || 0,
            payMin: businessJobResponse.job_details.rate_min || 0,
            isFixedPay: businessJobResponse.job_details.pay_is_fixed || null,
        };
    }

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 700, paddingLeft: 40, paddingRight: 40 }}
        >
            <RequirementsAndPayProvider
                fieldStates={form.fieldStates}
                defaultPayScales={businessJobResponse.job_details.pay_scales}
            >
                <InnerEditPayModal
                    id={businessJobResponse?.job_details?.id}
                    onSaveComplete={onSaveComplete}
                    onClose={onClose}
                />
            </RequirementsAndPayProvider>
        </SizeableRoundedDialog>
    );
}
