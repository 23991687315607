import { JobDetailValueWithOption } from 'models';
import { BusinessJobTypes, IPayScale, JobFaq, ProviderSelection } from 'parent-portal/forms/JobRequestTypes';

interface JobSchedule {
    id: number;
    start_time: string;
    end_time: string;
    day: number;
    slots: number;
}
export interface BusinessJobTemplateListDTO {
    id: number;
    headline: string;
    business_job_type: BusinessJobTypes;
    start_date: string;
    end_date: string;
    pay: number | null;
    rate_min: number | null;
    rate_max: number | null;
    daily_schedules: JobSchedule[];
    requested_workers: string[];
}

export interface BusinessJobTemplateDetailsDTO extends Omit<BusinessJobTemplateListDTO, 'requested_workers'> {
    address: number;
    business_location: number;
    details: JobDetailValueWithOption[];
    last_hire_offset_minutes: number;
    pay_scales: IPayScale[];
    description: string;
    requested_workers: ProviderSelection[];
    faqs: JobFaq[];
    require_minimum_qualifications: boolean;
    break_required_every_n_minutes?: number;
    break_length?: number;
    trial_run_benefits?: string;
    timezone: string;
}

export const isoWeekDayAbbreviations = {
    1: 'M',
    2: 'Tu',
    3: 'W',
    4: 'Th',
    5: 'F',
    6: 'Sa',
    7: 'Su',
} as { [key: number]: string };

export const isoWeekDays = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
} as { [key: number]: string };

export const DayToIsoWeekDay = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
} as { [key: string]: number };
