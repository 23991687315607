import React from 'react';
import { Colors, Text } from 'library';
import { Tooltip, styled } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';

import { QuestionMark } from '@mui/icons-material';

import type { StyledComponentProps, TooltipProps } from '@mui/material';

export function ExplanatoryCredentialPill({
    label,
    title,
    style,
}: {
    label: string;
    title: React.ReactNode;
    style?: StyledComponentProps;
}) {
    return (
        <LightTooltip title={title} placement="bottom-end">
            <Pill styles={style ?? {}}>
                <StyledQuestionMark>
                    <QuestionMark sx={{ color: Colors.white, height: 10, width: 10 }} />
                </StyledQuestionMark>
                <Text bold textStyle={{ fontSize: 13, textDecorationLine: 'underline', cursor: 'default' }}>
                    {label}
                </Text>
            </Pill>
        </LightTooltip>
    );
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: Colors.white,
        boxShadow: theme.shadows[1],
    },
}));

const Pill = styled('div')(({ styles }: { styles: StyledComponentProps }) => ({
    alignItems: 'center',
    backgroundColor: Colors.lightGrey,
    border: `1px solid ${Colors.darkNavy}`,
    borderRadius: 30,
    display: 'flex',
    marginRight: 10,
    marginTop: 8,
    padding: '3px 10px',
    width: 'fit-content',
    ...styles,
}));

const StyledQuestionMark = styled('div')({
    alignItems: 'center',
    backgroundColor: Colors.darkNavy,
    borderRadius: 20,
    display: 'flex',
    height: 14,
    justifyContent: 'center',
    marginRight: 5,
    width: 14,
});
