import React, { useState } from 'react';
import { AdminProvider } from 'models';
import { IconButton, Text, Colors, BasicDialog, PrimaryButton, LoadingIndicator } from 'library';
import { client } from 'shared';
import { Refresh } from '@mui/icons-material';

const RegistryAccountStatus = ({
    provider,
    refreshProvider,
}: {
    provider: AdminProvider;
    refreshProvider: () => void;
}) => {
    const [running, setRunning] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const [modalOpen, setModalOpen] = useState(false);

    const displayComponent = provider.user.compliance_state === 'Illinois';
    if (!displayComponent) {
        return <></>;
    }

    const resetAccount = () => {
        setError(undefined);
        setRunning(true);
        client('api/illinois-info-collect/reset-inccrra-account/', {
            method: 'POST',
            body: {
                provider_id: provider.id,
            },
        })
            .then(() => {
                refreshProvider();
            })
            .catch(() => {
                setError('Error reseting credentials for provider');
            })
            .finally(() => {
                setRunning(false);
                setModalOpen(false);
            });
    };

    const attemptToCompleteRegistration = () => {
        setError(undefined);
        setRunning(true);
        client('api/illinois-info-collect/gateways-check/', {
            method: 'POST',
            body: {
                user: provider.user.id,
            },
        })
            .then(() => {
                refreshProvider();
            })
            .catch(() => {
                setError('Could not complete registration for provider');
            })
            .finally(() => {
                setRunning(false);
                setModalOpen(false);
            });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center' }}>
            <Text variant="body1" bold textStyle={{ margin: 10 }}>
                Registry Account Status:
            </Text>
            {error ? (
                <Text textStyle={{ color: Colors.error }}>{error}</Text>
            ) : (
                <Text textStyle={{ fontSize: 12, color: Colors.grey }}>{provider.registry_account_status}</Text>
            )}
            <IconButton onClick={() => setModalOpen(true)} icon={Refresh} disabled={running} />
            <BasicDialog closeButton isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                {running ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <PrimaryButton buttonStyle={{ marginTop: 20, marginBottom: 20 }} onClick={resetAccount}>
                            Reset Account Connect Step
                        </PrimaryButton>
                        <PrimaryButton
                            buttonStyle={{ marginTop: 20, marginBottom: 20 }}
                            onClick={attemptToCompleteRegistration}
                        >
                            Attempt to Complete Registration
                        </PrimaryButton>
                    </>
                )}
            </BasicDialog>
        </div>
    );
};

export default RegistryAccountStatus;
