import { JobDetailValueWithOption } from 'models';
import moment from 'moment';
import { ISpotlightWorker } from 'parent-portal/home/Models/DashboardData';

export interface JobOptions {
    daysOfTheWeek: DayOfWeekSelections;
    selectedPreferredWorkers: ProviderSelection[];
    selectedPastWorkers: ProviderSelection[];
    faqs: JobFaq[];
    [key: string]: any;
}

export interface DayOfWeek {
    selected: boolean;
    start: moment.Moment | null;
    end: moment.Moment | null;
    slots: number;
    day: number;
}

export interface WorkingHoursForDay {
    [key: string]: {
        start: string;
        end: string;
        timezone?: string;
        slots: number;
        day?: number;
    };
}

export interface DayOfWeekMapping<ValueType> {
    Sunday: ValueType;
    Monday: ValueType;
    Tuesday: ValueType;
    Wednesday: ValueType;
    Thursday: ValueType;
    Friday: ValueType;
    Saturday: ValueType;
}

export type DayOfWeekSelections = DayOfWeekMapping<DayOfWeek>;

export interface ProviderSelection {
    name: string;
    id: number;
}

export type RequestType = '' | 'onetime' | 'recurring';

export enum TemplateOption {
    NEW = 'new',
    TEMPLATE = 'template',
}

export interface JobFaq {
    question: {
        id: number;
    };
    answer: string;
}

export interface IPayFormFields {
    payMin: number;
    payMax: number;
}

interface OngoingFormFields {
    daysOfTheWeek: DayOfWeekSelections;
    sameTimes: boolean;
    multipleProviders: boolean;
}

interface OneTimeFormFields {
    jobTimes: JobTimes;
}

export interface JobTimes {
    start: moment.Moment | null;
    end: moment.Moment | null;
    slots: number;
    day?: number;
    timezone?: string;
}

export interface IPayScaleQualification {
    value: string | null;
    option_id: number | null;
    qualification_id: number;
    name: string;
}

export interface ExtendedPayScaleQualification extends IPayScaleQualification {
    id: number;
    option: string | null;
}

export interface IPayScale {
    rate: number;
    description: string;
    qualifications: IPayScaleQualification[];
}

export interface ExtendedPayScale extends IPayScale {
    id: number;
    qualifications: ExtendedPayScaleQualification[];
}

export interface JobLocation {
    id: number;
    businessLocationId?: number;
    timezone?: string;
}

interface CommonFormFields {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
    lastHireOffsetMinutes: number;
    preferredWorkers: ProviderSelection[];
    pastWorkers: ProviderSelection[];
    comments: string;
    headline: string;
    pay: IPayFormFields;
    requestType: RequestType;
    payType: JobPaymentTypes | undefined;
    businessJobType: BusinessJobTypes | undefined;
    address: JobLocation[];
    jobDetails: JobDetailValueWithOption[];
    payScales: IPayScale[];
    breakRequired: boolean;
    breakLength: number | undefined;
    useTemplate: TemplateOption;
    templateJobId: number | undefined;
    trialRunCoverage: boolean;
    faqs: JobFaq[];
    addMinimumBreak: boolean;
    breakEveryNHours: number | null;
    trialRunBenefits: string | undefined;
    selectedSpotlightWorkers: ISpotlightWorker[];
    allSpotlightWorkers: ISpotlightWorker[];
}

export interface PostBodyType {
    family: number;
    start_date: string | null;
    end_date: string | null;
    last_hire_offset_minutes: number;
    pay: number | null;
    rate_min: number | null;
    rate_max: number | null;
    multiple_providers: false;
    status: string;
    family_comments: string;
    days_of_week: string[] | null;
    preferred_sitters: {
        preferred_sitters: {
            sitter: number;
        }[];
    };
    times_of_day: WorkingHoursForDay[];
    pay_window: string;
    ongoing: boolean;
    headline: string;
    pay_type: JobPaymentTypes;
    business_job_type: BusinessJobTypes;
    pay_range_info: string;
    pay_scales: IPayScale[];
    break_every_n_minutes?: number;
}
export type JobPaymentTypes = 'PERCENTAGE' | null;
export type BusinessJobTypes = 'TRIAL_RUN' | 'SUBSTITUTE' | null;

export interface JobFormFields extends CommonFormFields, OneTimeFormFields, OngoingFormFields {}
