import React from 'react';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';

import JobFormPage from '../JobFormPage';
import { useRequirementsAndPayContext } from './context';
import { InnerPay } from './payComponents/InnerPay';

interface PayPageProps {
    name: string;
    editing?: boolean;
}

export function Pay({ name, editing = false }: PayPageProps) {
    const { goToNext } = useJobFormContext();

    const { disabled, onClick } = useRequirementsAndPayContext();

    return (
        <JobFormPage
            id={name}
            onNextClicked={() => onClick(() => goToNext('job-faq-page'))}
            nextDisabled={disabled}
            nextText={'Next'}
        >
            <InnerPay editing={editing} />
        </JobFormPage>
    );
}
