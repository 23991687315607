import { Star, Check } from '@mui/icons-material';
import { ChildcareCenterExperienceQualification, Provider } from 'models';
import { ProviderQualifications } from 'parent-portal/provider-profile/providerProfileModels';
import ProviderSummaryDetailRow from 'parent-portal/provider-profile/ProviderSummaryDetailRow';
import React, { useEffect, useState } from 'react';
import { Text, Colors } from 'library';

export default function BusinessCredentials({
    provider,
    qualifications,
    childcareCenterQualifications,
}: {
    provider: Provider;
    qualifications: ProviderQualifications;
    childcareCenterQualifications: ChildcareCenterExperienceQualification | null;
}) {
    const [occrraTrainings, setOccrraTrainings] = useState<string[]>([]);

    useEffect(() => {
        const trainingsFromOccrra = qualifications.professional_development_courses
            .filter((course) => course.verification_status === 'Verified')
            .map((course) => course.title);
        setOccrraTrainings(trainingsFromOccrra || childcareCenterQualifications?.occrra_training_description || []);
    }, [qualifications.professional_development_courses, childcareCenterQualifications]);

    return (
        <>
            {provider.ready_to_work ||
            (qualifications.introductory_training_requirements?.length &&
                qualifications.introductory_training_requirements?.length > 0) ? (
                <div style={{ borderRadius: 25, backgroundColor: Colors.lightTurq }}>
                    <div style={{ padding: 20 }}>
                        <Text
                            color={Colors.turquoise}
                            variant="body1"
                            bold
                            textStyle={{ letterSpacing: 3.2, textTransform: 'uppercase' }}
                        >
                            Compliance
                        </Text>
                        <div style={{ marginTop: '2%' }}>
                            {provider.ready_to_work && (
                                <ProviderSummaryDetailRow
                                    icon={<Check />}
                                    detail={`State Required Background Check On File`}
                                />
                            )}
                        </div>

                        {qualifications.introductory_training_requirements?.map((e) => (
                            <div style={{ marginTop: '2%' }} key={e}>
                                <ProviderSummaryDetailRow icon={<Check />} detail={e} />
                            </div>
                        ))}

                        {qualifications.introductory_training_requirements_complete && (
                            <div style={{ marginTop: '2%' }}>
                                <ProviderSummaryDetailRow
                                    icon={<Check />}
                                    detail={'Introductory Training Requirements Complete'}
                                />
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
            <div style={{ marginTop: '3%', borderRadius: 25, backgroundColor: Colors.lightTurq }}>
                <div style={{ padding: 20 }}>
                    <Text
                        color={Colors.turquoise}
                        variant="body1"
                        bold
                        textStyle={{ letterSpacing: 3.2, textTransform: 'uppercase' }}
                    >
                        Education
                    </Text>
                    {qualifications.education && qualifications.education.length > 0 ? (
                        qualifications.education.map((e) => (
                            <div style={{ marginTop: '2%' }} key={`${e.institution_name}-${e.major_name}`}>
                                {e.major_name && <Text variant="body1">{e.major_name}</Text>}
                                <Text variant="body1" textStyle={{ marginTop: e.major_name ? 6 : 0 }}>
                                    {e.award_name}
                                </Text>
                                <Text textStyle={{ marginTop: 6 }}>{e.institution_name}</Text>
                            </div>
                        ))
                    ) : (
                        <Text>No education on file.</Text>
                    )}
                    {childcareCenterQualifications?.cdc_certification && (
                        <div style={{ marginTop: '2%' }}>
                            <ProviderSummaryDetailRow icon={<Star />} detail="CDA Certificate On File" />
                        </div>
                    )}
                </div>
            </div>
            <div style={{ marginTop: '3%', borderRadius: 25, backgroundColor: Colors.lightTurq }}>
                <div style={{ padding: 20 }}>
                    <Text
                        color={Colors.turquoise}
                        variant="body1"
                        bold
                        textStyle={{ letterSpacing: 3.2, textTransform: 'uppercase' }}
                    >
                        Additional Credentials
                    </Text>
                    <div style={{ marginTop: '2%' }}>
                        {qualifications.cpl_level && (
                            <ProviderSummaryDetailRow
                                icon={<Star />}
                                detail={`CPL Level ${qualifications.cpl_level}`}
                            />
                        )}

                        {childcareCenterQualifications?.cpr_certification && (
                            <ProviderSummaryDetailRow icon={<Star />} detail="CPR Certified" />
                        )}
                        {childcareCenterQualifications?.first_aid_certification && (
                            <ProviderSummaryDetailRow icon={<Star />} detail="First Aid Certified" />
                        )}
                        {childcareCenterQualifications?.covid_vaccinated && (
                            <ProviderSummaryDetailRow icon={<Star />} detail="COVID Vaccinated" />
                        )}

                        {qualifications.child_care_training_transcript && (
                            <div style={{ marginTop: '2%' }}>
                                <a
                                    href={qualifications.child_care_training_transcript}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ textDecoration: 'underline' }}
                                >
                                    <ProviderSummaryDetailRow icon={<Star />} detail="See Full Transcript" />
                                </a>
                            </div>
                        )}

                        <div>
                            {occrraTrainings.length ? (
                                <>
                                    <ProviderSummaryDetailRow
                                        icon={<Star />}
                                        detail="OCCRRA Trainings"
                                        subDetail={''}
                                    />
                                    {occrraTrainings.map((training: string) => (
                                        <Text key={training} variant="caption">
                                            • {training}
                                        </Text>
                                    ))}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
