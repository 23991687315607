import React, { useState } from 'react';
import { Grid, styled } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { Colors, PrimaryButton, Text, TextButton } from 'library';
import { format12HourTime, formatShortWeekdayMonthDay } from 'shared/Dates';
import { IBusinessJobDetailsModel } from '../../BusinessJob/models';
import { CalendarColors } from '../HiredShift';
import ProviderDisplay from './ProviderDisplay';
import CardInfo from './CardInfo';
import HourReportInfo from './HourReportInfo';
import ShiftCardMenu from './ShiftCardMenu';
import { isAfter, startOfTomorrow, isSameDay, addMinutes } from 'date-fns';
import RemoveProviderFromShfit from './RemoveProviderFromShfit';
import ActionRequired from './ActionRequired';
import { useHistory } from 'react-router';
import BanProviderAction from './BanProviderAction';
import CancelShiftModalTrigger from './CancelShiftModalTrigger';
import { BusinessSlot, ShiftJob, ShiftProviderUser } from 'models';
import MarkNoShowButton from './MarkNoShowButton';
import ClockInButton from './ClockInButton';
import CardRequestedTimeChange from './CardRequestedTimeChange';
import { getShiftTimes } from './shiftTimesUtils';

const Container = styled(Grid)({
    padding: 10,
});

export default function ShiftBaseCard({
    provider,
    pay,
    status,
    provider_id,
    ongoing_request,
    slot,
    refresh,
    provider_action_required,
    review_score,
    needs_center_action,
}: {
    provider?: ShiftProviderUser;
    pay?: number | null;
    status: string;
    provider_id: number;
    slot: BusinessSlot;
    ongoing_request: IBusinessJobDetailsModel | ShiftJob;
    provider_action_required?: boolean;
    refresh: () => void;
    review_score?: number;
    needs_center_action?: boolean;
}) {
    const history = useHistory();
    const shift = slot.appointments.length ? slot.appointments[0] : undefined;
    const shiftInFlorida = slot.ongoing_request.address.state === 'Florida';
    const hourReport = shift?.hour_reports?.find((r) => r.provider.id === provider_id);
    const [banModal, setBanModal] = useState(false);
    const { shiftStart, shiftEnd, infoText } = getShiftTimes(slot);

    const clockedIn = hourReport && hourReport.start && !hourReport.end;

    const clockedInStyle = clockedIn ? { border: 'solid', borderWidth: 2, borderColor: Colors.darkNavy } : {};

    const showRemoveProvider =
        ['APPROVED', 'ON_STAFF'].includes(status) && isAfter(new Date(slot.start_date), startOfTomorrow());

    const completed = status === 'COMPLETED';

    const shouldRenderCancelShiftTrigger =
        (showRemoveProvider || isSameDay(new Date(slot.start_date), new Date())) && !hourReport?.start;

    function renderManageShiftSection() {
        const showManageShiftActions =
            !hourReport?.start &&
            isAfter(new Date(), new Date(slot.start_date)) &&
            isSameDay(new Date(slot.start_date), new Date());

        if (!showManageShiftActions) {
            return;
        }

        const shiftStart = new Date(slot.start_date);
        const startPlusThirty = addMinutes(shiftStart, 30);
        const isThirtyMinAfterStart = isAfter(new Date(), startPlusThirty);

        return (
            <Container container direction="row">
                {isThirtyMinAfterStart && shift ? (
                    <MarkNoShowButton shift={shift} provider={provider} onComplete={refresh} providerId={provider_id} />
                ) : null}
                {shift && (
                    <ClockInButton
                        appointmentId={shift.id}
                        startDate={shift.start_date}
                        timezone={ongoing_request.timezone}
                        providerName={provider?.first_name || 'Worker'}
                        providerUserId={provider?.id || 0}
                        onComplete={refresh}
                    />
                )}
            </Container>
        );
    }

    function PostShiftHeader() {
        const hasClockOut = !!hourReport?.end || true;
        const showClearingHouseReminder = needs_center_action && shiftInFlorida && hasClockOut;
        if (!completed && !hasClockOut) {
            return null;
        }

        const openClearingHouseLink = () => {
            const chLink = 'https://crw.flclearinghouse.com/';
            window.open(chLink, '_blank');
        };

        return (
            <Grid item container xs={12} style={{ padding: 5, gap: 10 }}>
                <ReviewAction />
                {showClearingHouseReminder && (
                    <TextButton onClick={openClearingHouseLink}>
                        Please remember to add this worker to Clearinghouse
                    </TextButton>
                )}
            </Grid>
        );
    }

    function ReviewAction() {
        if (!completed) {
            return null;
        }

        return review_score ? (
            <Rating value={review_score} disabled={true} />
        ) : (
            <PrimaryButton
                buttonStyle={{ width: undefined }}
                onClick={() => history.push(`/providers/review/${provider?.id}`)}
            >
                Review
            </PrimaryButton>
        );
    }

    return (
        <Grid item container direction="column" alignContent="center">
            <Grid
                item
                container
                style={{
                    borderRadius: 18,
                    backgroundColor: completed
                        ? Colors.lightGrey
                        : CalendarColors[ongoing_request.business_job_type ?? ''] ?? Colors.lightTurq,
                    padding: 10,
                    boxShadow: '0px 3px 6px 0px #00000029',
                    ...clockedInStyle,
                    marginBottom: 10,
                }}
            >
                <Grid
                    container
                    item
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    style={{
                        borderBottom: 'solid',
                        borderBottomWidth: 1,
                        borderBottomColor: Colors.disabledGrey,
                        paddingRight: 10,
                        paddingLeft: 10,
                        paddingBottom: 10,
                    }}
                >
                    <Grid
                        xs={10}
                        container
                        item
                        direction="column"
                        justify="center"
                        alignContent="flex-start"
                        spacing={2}
                    >
                        {provider ? (
                            <>
                                <Grid item direction="row">
                                    <ProviderDisplay provider={provider} containerStyles={{ display: 'flex' }} />
                                </Grid>
                                {showRemoveProvider && provider && shift ? (
                                    <Grid item direction="row">
                                        <RemoveProviderFromShfit
                                            shift={shift}
                                            provider={provider}
                                            provider_id={provider_id}
                                            setBanModal={setBanModal}
                                        />
                                        <BanProviderAction
                                            provider={provider}
                                            open={banModal}
                                            onClose={() => {
                                                setBanModal(false);
                                                refresh();
                                            }}
                                        />
                                    </Grid>
                                ) : null}

                                {shouldRenderCancelShiftTrigger && (
                                    <Grid item direction="row">
                                        <CancelShiftModalTrigger
                                            providerId={provider_id}
                                            provider={provider}
                                            slot={slot}
                                            refresh={refresh}
                                            textStyle={{ textDecoration: 'none' }}
                                            buttonStyle={{ justifyContent: 'flex-start' }}
                                        />
                                    </Grid>
                                )}
                            </>
                        ) : (
                            <Text bold>{formatShortWeekdayMonthDay(slot.start_date)}</Text>
                        )}
                    </Grid>
                    <Grid
                        xs={2}
                        container
                        item
                        direction="row"
                        alignItems="center"
                        justify="flex-end"
                        style={{ paddingRight: 10 }}
                    >
                        <ShiftCardMenu
                            provider_id={provider_id}
                            provider={provider}
                            slot={slot}
                            ongoing_request={ongoing_request}
                            status={status}
                            refresh={refresh}
                            hourReport={hourReport}
                        />
                    </Grid>
                    <PostShiftHeader />
                </Grid>
                <Grid container item style={{ paddingLeft: 8, gap: 8 }}>
                    <CardInfo
                        title="Schedule:"
                        content={`${format12HourTime(shiftStart, ongoing_request.timezone)} - ${format12HourTime(shiftEnd, ongoing_request.timezone, true)}`}
                        infoText={infoText}
                    />
                    {shift?.center_initiated_appointment_time_change_request && (
                        <CardRequestedTimeChange
                            type="center"
                            timeChangeRequest={shift.center_initiated_appointment_time_change_request}
                            refresh={refresh}
                            timezone={ongoing_request.timezone}
                        />
                    )}
                    {shift?.worker_initiated_appointment_time_change_request && (
                        <CardRequestedTimeChange
                            type="worker"
                            timeChangeRequest={shift.worker_initiated_appointment_time_change_request}
                            refresh={refresh}
                            timezone={ongoing_request.timezone}
                        />
                    )}
                    <Grid container item direction="row">
                        <Grid item xs={6}>
                            {pay ? <CardInfo title="Rate:" content={`$${pay}/hr`} /> : null}
                        </Grid>
                        <Grid item xs={6}>
                            <CardInfo title="Job Title:" content={ongoing_request.headline} />
                        </Grid>
                    </Grid>
                    {hourReport && provider ? (
                        <HourReportInfo hourReport={hourReport} timezone={ongoing_request.timezone} />
                    ) : null}
                    {status === 'COMPLETED' ? (
                        <Text variant="caption" bold>
                            Completed
                        </Text>
                    ) : null}
                </Grid>
                {provider_action_required ? <ActionRequired /> : null}
                {renderManageShiftSection()}
            </Grid>
        </Grid>
    );
}
