import React, { createContext, useContext, useEffect, useState } from 'react';
import { isEmpty, sortBy } from 'lodash';
import { useClient } from 'shared';

import { OHIO } from './constants';
import { updateScales, deleteScale } from './utils/manage';
import { loadInitialScales, detectState } from './utils/initialData';
import { formatQualificationChoices } from './utils/qualifications';
import { checkForDuplicates, findProblems } from './utils/problems';

import type {
    FieldStates,
    GenericQualificationChoices,
    IManagedScale,
    IPayFormFields,
    IPayScale,
    Problems,
    Qualification,
} from './types';

export interface RequirementsAndPayContextType {
    scales: IManagedScale[];
    state: string;
    qualifications: GenericQualificationChoices;
    loading: boolean;
    initialScale: IManagedScale;
    disabled: boolean;
    updateScales: (newScale: IManagedScale) => void;
    deleteScale: (scaleId: string) => void;
    replaceAllScales: (newScales: IManagedScale[]) => void;
    onClick: (nextAction?: () => void) => void;
    problems: { [key: string]: Problems };
}

export const RequirementsAndPayContext = createContext<RequirementsAndPayContextType>({
    scales: [],
    state: OHIO,
    disabled: true,
    qualifications: {
        education: [],
        experience: [],
        boolean: [],
        single: [],
        multi: [],
    },
    loading: true,
    initialScale: {} as IManagedScale,
    updateScales: () => {},
    deleteScale: () => {},
    replaceAllScales: () => {},
    onClick: () => {},
    problems: {
        '': {
            education: false,
            experience: false,
            single: {},
            multi: {},
            boolean: {},
            rate: null,
            duplicate: false,
            noQualifications: false,
        },
    },
});

export function RequirementsAndPayProvider({
    children,
    fieldStates,
    defaultPayScales,
}: {
    children: React.ReactNode;
    fieldStates: FieldStates<{ pay: IPayFormFields; payScales: IPayScale[] }>;
    defaultPayScales: IPayScale[];
}) {
    const { loading, data } = useClient<Qualification[]>({ url: 'api/qualification/' });
    const intialScales = loadInitialScales(defaultPayScales, fieldStates.payScales.value);

    const [scales, setScales] = useState<IManagedScale[]>(intialScales);
    const [initialScale, setInitialScale] = useState<IManagedScale>(sortBy(intialScales, 'rate')[0]);
    const [state, setState] = useState<string>(detectState(data ?? []));
    const [problems, setProblems] = useState<{ [key: string]: Problems }>({
        [intialScales[0].id]: findProblems(intialScales[0], state),
    });

    function iterateAndFindProblems(newScales: IManagedScale[] = scales, initial: IManagedScale = initialScale) {
        const newProblems: { [key: string]: Problems } = {};
        const duplicates = checkForDuplicates(newScales);

        newScales.forEach((scale: IManagedScale, index: number) => {
            const previousRate = index === 0 ? null : newScales[index - 1]?.rate ?? null;
            const scaleProblem = findProblems(scale, state, false, initial.id);

            if (!!previousRate && scale.rate < previousRate) {
                scaleProblem.rate = 'lower';
            }

            newProblems[`${scale.id}`] = scaleProblem;
            if (duplicates[`${scale.id}`]) {
                newProblems[`${scale.id}`].duplicate = true;
            }
        });
        setProblems(newProblems);
    }

    useEffect(() => {
        if (intialScales.length === 0 || scales.length > 0) {
            return;
        }

        setScales(intialScales);
        setInitialScale(intialScales[0]);
        iterateAndFindProblems();
    }, [intialScales]);

    useEffect(() => {
        const detectedState = detectState(data ?? []);
        setState(detectedState);
    }, [data]);

    useEffect(() => {
        iterateAndFindProblems();
    }, [state]);

    function updateAllScales(newScale: IManagedScale) {
        const newScales = updateScales(newScale, scales, setScales, setInitialScale);
        iterateAndFindProblems(newScales);
    }

    function deleteAndUpdate(scaleId: string) {
        const newScales = deleteScale(scaleId, scales, setScales, setInitialScale);

        iterateAndFindProblems(newScales, newScales[0]);
    }

    function replaceAllScales(newScales: IManagedScale[]) {
        const sortedScales = sortBy(newScales, 'rate');

        setScales(sortedScales);
        setInitialScale(sortedScales[0]);
        iterateAndFindProblems(sortedScales, sortedScales[0]);
    }

    const disabled = Object.values(problems).some((problem: object) => {
        return Object.values(problem).some((value: boolean | object | string | null) =>
            typeof value === 'object' ? !isEmpty(value) : !!value,
        );
    });

    function onClick(nextAction?: () => void) {
        if (!disabled) {
            const sortedScales = sortBy(scales, 'rate');

            fieldStates.payScales.setValue(sortedScales);
            fieldStates.payScales.setValid(true);

            fieldStates.pay.setValue({
                payMin: sortedScales[0].rate,
                payMax: sortedScales.at(-1)?.rate ?? sortedScales[0].rate,
            });
            nextAction?.();
        }
    }

    const context = {
        scales,
        state,
        disabled,
        qualifications: formatQualificationChoices(data ?? [], state),
        loading,
        initialScale,
        updateScales: updateAllScales,
        deleteScale: deleteAndUpdate,
        replaceAllScales,
        problems,
        onClick,
    };

    return <RequirementsAndPayContext.Provider value={context}>{children}</RequirementsAndPayContext.Provider>;
}

export function useRequirementsAndPayContext() {
    return useContext(RequirementsAndPayContext);
}
