import { FormDetails, PendingDocumentFilters } from 'internal/document-verify/models';
import { ComplianceDocumentInfoCollect } from 'models';
import { client, consoleLogInDev, queryStringifyObject } from 'shared';
export const SIGNED_MED_FORM = 'Medical Statement';
export const FIRST_AID_CPR = 'First Aid/CPR trainings';
export const DCF_TRANSCRIPT = 'DCF Training Transcript';

export const verifyForm = (
    id: number | undefined,
    text: string | undefined,
    refresh?: () => void,
    formDetails?: FormDetails,
) => {
    if (id && text) {
        client(`api/documents/verify`, {
            body: {
                id: id,
                form_name: text,
                ...formDetails,
                satisfied_document_requirements: formDetails?.satisfied_document_requirements?.length
                    ? formDetails?.satisfied_document_requirements
                    : null,
            },
            method: 'PATCH',
        })
            .then(() => {
                if (refresh) {
                    refresh();
                } else {
                    alert('Success!');
                }
            })
            .catch(consoleLogInDev);
    }
};

interface IFormDetailsToSubmit {
    satisfied_document_requirements: string[];
    document_type_id?: number;
    document_type?: string;
    expiration_date?: Date;
    signed_at?: Date;
    is_sesame_document?: boolean;
}

export const uploadAndVerifyForm = (
    file: File | undefined,
    providerUserId: number,
    documentTypeId: number,
    setLoading: (loading: boolean) => void,
    formDetailsToSubmit?: IFormDetailsToSubmit,
) => {
    if (file) {
        const formData = new FormData();
        formData.append('document', file, file.name);
        formData.append('document_type_id', documentTypeId.toString());

        if (formDetailsToSubmit) {
            Object.entries(formDetailsToSubmit).forEach(([key, value]) => {
                formData.append(key, value as string);
            });
        }

        client(
            `api/admin-providers/${providerUserId}/upload-documents/`,
            {
                method: 'POST',
                body: formData,
            },
            false,
        )
            .then(() => {
                alert('Document uploaded successfully');
            })
            .catch(() => {
                alert('Something went wrong');
            })
            .finally(() => {
                setLoading(false);
            });
    }
};

export const deleteForm = (id: number | undefined, text: string | '', refresh?: () => void) => {
    if (!id) {
        return;
    }

    const endpoint = `api/documents/${id}/remove/`;
    const method = 'POST';

    client(endpoint, { method: method })
        .then(() => {
            if (refresh) {
                refresh();
            }
        })
        .catch(consoleLogInDev);
};

export const getPendingForms = (filters?: PendingDocumentFilters) => {
    const query = queryStringifyObject(filters ?? {});
    const linkBase = 'api/documents/pending-review/';
    const link = filters ? `${linkBase}?${query}` : linkBase;
    return client(link);
};

export const ignoreForm = (id: number | undefined, text: string | '', refresh: () => void, ignoreId?: number) => {
    client(`api/documents/ignore`, { method: 'PATCH', body: { id: id, form_name: text, document_ignore_id: ignoreId } })
        .then(refresh)
        .catch(consoleLogInDev);
};

export const getRequiredDocumentInfo = (userId: number): Promise<ComplianceDocumentInfoCollect> => {
    return client(`api/illinois-compliance-documents/collect-document-info/?user_id=${userId}`);
};

export const updateRequiredDocumentInfo = (id: number, documentInfoDetails: { [key: string]: string }) => {
    return client(`api/illinois-compliance-documents/${id}`, {
        body: documentInfoDetails,
        method: 'PATCH',
    });
};

export const getDocumentTypeIgnoreMessages = (documentTypeId: number | undefined) => {
    return client(`api/documents/ignore-reasons?document_type_id=${documentTypeId}`);
};
