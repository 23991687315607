import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import Past from './Past';
import PayMethods from './Components/PayMethods';
import FailedPayments from './Components/FailedPayments';
import SendReceipt from './Components/SendReceipt';
import theme from 'theme';
import { IPastPaymentState } from './Models/PastPayment';

interface FamilyPaymentsProps {
    isLoadingPastPayments: boolean;
    pastPayments?: IPastPaymentState;
    getPageNumber: () => number;
    onPageChange: (newPage: number) => void;
}

export default function FamilyPayments({
    isLoadingPastPayments,
    pastPayments,
    getPageNumber,
    onPageChange,
}: FamilyPaymentsProps) {
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <Grid container justify="center" alignContent="center" direction="row">
            <Grid
                container
                item
                direction="column"
                lg={6}
                md={7}
                sm={9}
                xs={12}
                style={{ paddingRight: mdUp ? 20 : 0 }}
            >
                <Grid container item>
                    <FailedPayments />
                    <Grid item xs={12}>
                        <Past
                            isLoading={isLoadingPastPayments}
                            pastPayments={pastPayments}
                            currentPage={getPageNumber()}
                            onPageChange={onPageChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item lg={4} md={5} sm={9} xs={12} alignContent="flex-start">
                <Grid container item xs={12} direction="row" justify="center" alignItems="center">
                    <SendReceipt />
                </Grid>
                <Grid item xs={12}>
                    <PayMethods />
                </Grid>
            </Grid>
        </Grid>
    );
}
