import {
    NO_EDUCATION_REQUIRED_CHOICE,
    NEW_TO_CHILDCARE,
    CLUMPED_YOE,
    FLORIDA,
    PLACEHOLDER_HIGH_SCHOOL_CHOICE,
} from '../constants';
import type {
    GenericChoice,
    GenericQualificationChoices,
    IManagedScale,
    IPayScaleQualification,
    Qualification,
} from '../types';

export interface Pills {
    education: Partial<GenericChoice> | null;
    experience: Partial<GenericChoice> | null;
    boolean: GenericChoice[];
    single: { qualification: Qualification; choice: GenericChoice }[];
    multi: { qualification: Qualification; choices: GenericChoice[] }[];
}

export function gatherDisplayPills(
    scale: IManagedScale,
    allChoices: GenericQualificationChoices,
    state: string,
): Pills {
    const pills: Pills = {
        education: null,
        experience: null,
        boolean: [],
        single: [],
        multi: [],
    };

    allChoices.education.forEach((choice: GenericChoice) => {
        const education = scale.qualifications.find((x: IPayScaleQualification) => x.option_id === choice.key);

        if (education) {
            pills.education = choice;
        }
    });

    if (!pills.education) {
        pills.education = state === FLORIDA ? NO_EDUCATION_REQUIRED_CHOICE : PLACEHOLDER_HIGH_SCHOOL_CHOICE;
    }

    CLUMPED_YOE.forEach((choice: { key: number }) => {
        const experience = scale.qualifications.find(
            (x: { value: string | null }) => x.value === choice.key.toString(),
        );

        if (experience) {
            pills.experience = choice;
        }
    });

    pills.experience = pills.experience ?? NEW_TO_CHILDCARE;

    allChoices.boolean.forEach((choice: GenericChoice) => {
        const boolean = scale.qualifications.find((x: IPayScaleQualification) => x.qualification_id === choice.key);

        if (boolean) {
            pills.boolean.push(choice);
        }
    });

    allChoices.single.forEach((choice: { qualification: Qualification; choices: GenericChoice[] }) => {
        const single = scale.qualifications.find(
            (x: IPayScaleQualification) => x.qualification_id === choice.qualification.id,
        );

        if (single && choice?.qualification?.id) {
            const option = choice.choices.find((x: GenericChoice) => x.key === single.option_id);

            if (option) {
                pills.single.push({ qualification: choice.qualification, choice: option });
            }
        }
    });

    allChoices.multi.forEach((choice: { qualification: Qualification; choices: GenericChoice[] }) => {
        const multi = scale.qualifications.filter(
            (x: IPayScaleQualification) => x.qualification_id === choice.qualification.id,
        );

        if (multi) {
            const options = choice.choices.filter((x: GenericChoice) =>
                multi.some((y: IPayScaleQualification) => y.option_id === x.key),
            );

            if (options.length) {
                pills.multi.push({ qualification: choice.qualification, choices: options });
            }
        }
    });

    return pills;
}
