import React from 'react';

import { PayHeader } from './PayHeader';
import { PayBody } from './PayBody';

interface PayPageProps {
    editing?: boolean;
}

export function InnerPay({ editing }: PayPageProps) {
    return (
        <div style={{ width: '100%', paddingBottom: editing ? 20 : 0 }}>
            <PayHeader />
            <PayBody editing={!!editing} />
        </div>
    );
}
