import { DEGREE, ECE_DEGREE, NO_EDUCATION_REQUIRED_CHOICE, YEARS_EXPERIENCE, NEW_TO_CHILDCARE } from '../constants';

import type { GenericChoice, IManagedScale, IPayScaleQualification, Qualification } from '../types';

function updateEducationQualification(scale: IManagedScale, chosen: number, choices: GenericChoice[]): IManagedScale {
    const updatedPayScaleQualifications = scale.qualifications.filter(
        (x: IPayScaleQualification) => ![DEGREE, ECE_DEGREE].includes(x.name),
    );

    const qualificationChoice = choices.find((choice: GenericChoice) => choice.key === chosen)?.pay_scale_qualification;

    const newEducationQualification =
        chosen === NO_EDUCATION_REQUIRED_CHOICE.key || !qualificationChoice ? [] : [qualificationChoice];
    const qualifications = [...updatedPayScaleQualifications, ...newEducationQualification];

    return { ...scale, qualifications };
}

function updateExperienceQualification(scale: IManagedScale, chosen: number, choices: GenericChoice[]): IManagedScale {
    const updatedPayScaleQualifications = scale.qualifications.filter(
        (x: IPayScaleQualification) => x.name !== YEARS_EXPERIENCE,
    );

    const experienceChoice = choices.find((choice: GenericChoice) => choice.key === chosen)?.pay_scale_qualification;

    const newExperienceQualification = chosen === NEW_TO_CHILDCARE.key || !experienceChoice ? [] : [experienceChoice];
    const qualifications = [...updatedPayScaleQualifications, ...newExperienceQualification];

    return { ...scale, qualifications };
}

function updateSingleQualification(
    scale: IManagedScale,
    chosen: number,
    qualification: Qualification,
    choices: GenericChoice[],
): IManagedScale {
    const updatedPayScaleQualifications = scale.qualifications.filter(
        (x: IPayScaleQualification) => x.qualification_id !== qualification.id,
    );

    const singleChoice = choices.find((choice: GenericChoice) => choice.key === chosen)?.pay_scale_qualification;

    const newSingleQualification = [null, -1].includes(chosen)
        ? []
        : [
              {
                  value: null,
                  option_id: chosen,
                  qualification_id: singleChoice?.qualification_id ?? 0,
                  name: singleChoice?.value ?? '',
              },
          ];

    const qualifications = [...updatedPayScaleQualifications, ...newSingleQualification];

    return { ...scale, qualifications };
}

function updateMultipleQualifications(
    scale: IManagedScale,
    chosen: number[],
    qualification: Qualification,
    choices: GenericChoice[],
): IManagedScale {
    const updatedPayScaleQualifications = scale.qualifications.filter(
        (x: IPayScaleQualification) => x.qualification_id !== qualification.id,
    );

    const multipleChoices = choices.filter((choice: GenericChoice) => chosen.includes(choice.key));

    const newMultipleQualifications =
        chosen === null
            ? []
            : multipleChoices.map((choice: GenericChoice) => ({
                  value: null,
                  option_id: choice.key,
                  qualification_id: choice?.pay_scale_qualification?.qualification_id ?? 0,
                  name: choice?.value ?? '',
              }));

    const qualifications = [...updatedPayScaleQualifications, ...newMultipleQualifications];

    return { ...scale, qualifications };
}

function updateBooleanQualification(scale: IManagedScale, choice: GenericChoice): IManagedScale {
    const hasBoolean = scale.qualifications.some((x: IPayScaleQualification) => x.qualification_id === choice.key);

    if (hasBoolean) {
        const updatedPayScaleQualifications = scale.qualifications.filter(
            (x: IPayScaleQualification) => x.qualification_id !== choice.key,
        );

        return { ...scale, qualifications: updatedPayScaleQualifications };
    } else {
        const booleanChoice = choice.pay_scale_qualification;

        const newBooleanQualification = [
            {
                value: 'true',
                option_id: null,
                qualification_id: booleanChoice?.qualification_id ?? 0,
                name: booleanChoice?.value ?? '',
            },
        ];

        const qualifications = [...scale.qualifications, ...newBooleanQualification];

        return { ...scale, qualifications };
    }
}

export function updateScale(
    scale: IManagedScale,
    chosen: number | number[],
    choices: GenericChoice[],
    qualification: Qualification | null,
    category: 'education' | 'experience' | 'single' | 'multiple' | 'boolean' | 'rate',
): IManagedScale {
    switch (category) {
        case 'education':
            return updateEducationQualification(scale, chosen as number, choices);
        case 'experience':
            return updateExperienceQualification(scale, chosen as number, choices);
        case 'single':
            return updateSingleQualification(scale, chosen as number, qualification as Qualification, choices);
        case 'multiple':
            return updateMultipleQualifications(scale, chosen as number[], qualification as Qualification, choices);
        case 'boolean':
            return updateBooleanQualification(scale, choices[0]);
        case 'rate':
            return { ...scale, rate: chosen as number };
        default:
            return scale;
    }
}
