import React from 'react';
import { Colors, Text } from 'library';
import { styled } from '@mui/material';

import { ReportProblemRounded } from '@mui/icons-material';

import type { StyledComponentProps } from '@mui/material';

export function ErrorBox({ text, style }: { text: string; style?: React.CSSProperties }) {
    return (
        <Box style={style ?? {}}>
            <Text
                textStyle={{
                    color: Colors.error,
                    cursor: 'default',
                    paddingRight: 10,
                }}
                variant="body2"
                bold
            >
                {text}
            </Text>
            <ReportProblemRounded sx={{ height: 28, width: 28 }} />
        </Box>
    );
}

const Box = styled('div')(({ style }: { style: StyledComponentProps }) => ({
    alignItems: 'center',
    backgroundColor: Colors.white,
    border: `${Colors.error} 1px solid`,
    borderRadius: 8,
    display: 'flex',
    color: Colors.error,
    padding: 10,
    ...style,
}));
