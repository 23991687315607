import moment from 'moment';
import { IPayFormFields, JobPaymentTypes, ProviderSelection, RequestType, TemplateOption } from './JobRequestTypes';

const dayField = {
    selected: false,
    start: null as moment.Moment | null,
    end: null as moment.Moment | null,
    slots: 1,
};

const ongoingFormFields = {
    daysOfTheWeek: {
        Monday: { ...dayField, day: 1 },
        Tuesday: { ...dayField, day: 2 },
        Wednesday: { ...dayField, day: 3 },
        Thursday: { ...dayField, day: 4 },
        Friday: { ...dayField, day: 5 },
        Saturday: { ...dayField, day: 6 },
        Sunday: { ...dayField, day: 7 },
    },
    sameTimes: true,
    multipleProviders: false,
};

const oneTimeFormFields = {
    jobTimes: {
        start: null as moment.Moment | null,
        end: null as moment.Moment | null,
        slots: 1,
    },
};

const commonFormFields = (isBusiness: boolean) => {
    const payMin = isBusiness ? 13 : 19;
    const payMax = isBusiness ? 15 : 24;

    return {
        startDate: moment() as moment.Moment | null,
        endDate: moment().add(28, 'days') as moment.Moment | null,
        lastHireOffsetMinutes: 240,
        comments: '',
        headline: '',
        requestType: '' as RequestType,
        pay: {
            payMin,
            payMax,
        } as IPayFormFields,
        preferredWorkers: [] as ProviderSelection[],
        pastWorkers: [] as ProviderSelection[],
        providerType: { sitter: true, tutor: false, teacher: false, nanny: false },
        businessJobType: undefined,
        payType: isBusiness ? ('PERCENTAGE' as JobPaymentTypes) : null,
        address: [],
        jobDetails: [],
        payScales: Array.from(Array.from({ length: payMax - payMin + 1 }), (x, i) => {
            return { rate: i + payMin, description: '', qualifications: [] };
        }),
        breakRequired: false,
        breakLength: undefined,
        useTemplate: TemplateOption.NEW,
        templateJobId: undefined,
        trialRunCoverage: false,
        faqs: [],
        addMinimumBreak: false,
        breakEveryNHours: null,
        trialRunBenefits: undefined,
        selectedSpotlightWorkers: [],
        allSpotlightWorkers: [],
    };
};

export const initialForm = (isBusiness: boolean) => ({
    ...commonFormFields(isBusiness),
    ...oneTimeFormFields,
    ...ongoingFormFields,
});
