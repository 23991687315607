import { OH_SINGLE_QUALIFICATION_PROBLEM_CHOICE_IDS } from '../../constants';

import type { IPayScaleQualification } from 'parent-portal/forms/JobRequestTypes';

export function singleProblem(payScaleQualifications: IPayScaleQualification[]): { [key: string]: boolean } {
    const problems = {} as { [key: string]: boolean };
    payScaleQualifications.forEach((qualification: IPayScaleQualification | null) => {
        if (
            qualification?.qualification_id &&
            OH_SINGLE_QUALIFICATION_PROBLEM_CHOICE_IDS.includes(qualification.qualification_id)
        ) {
            problems[`${qualification.qualification_id}`] = true;
        }
    });

    return problems;
}

export function booleanProblem(payScaleQualifications: IPayScaleQualification[]): { [key: string]: boolean } {
    const problems = {} as { [key: string]: boolean };

    payScaleQualifications.forEach((qualification: IPayScaleQualification) => {
        problems[`${qualification.qualification_id}`] = true;
    });

    return problems;
}
