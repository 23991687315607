import React from 'react';
import { Text } from 'library';

export function PayHeader() {
    return (
        <>
            <Text variant="display">Tell us how much you&apos;d pay for these credentials and beyond.</Text>
            <Text bold textStyle={{ paddingTop: 20, paddingBottom: 20 }}>
                Set your pay scale below.
            </Text>
        </>
    );
}
