import { DEGREE, ECE_DEGREE, NO_EDUCATION_REQUIRED_CHOICE, YEARS_EXPERIENCE, NEW_TO_CHILDCARE } from '../constants';
import type { GenericChoice, IManagedScale, IPayScaleQualification, Qualification } from '../types';

export function educationChoice(scale: IManagedScale, choices: GenericChoice[]): number | null {
    const education = scale.qualifications.find((x: IPayScaleQualification) => [DEGREE, ECE_DEGREE].includes(x.name));
    const no_education_choice = choices.find(
        (choice: GenericChoice) => choice.key === NO_EDUCATION_REQUIRED_CHOICE.key,
    );

    if (!education) return no_education_choice?.key ?? null;

    return choices.find((choice: GenericChoice) => choice.key === education.option_id)?.key ?? null;
}

export function experienceChoice(scale: IManagedScale, choices: GenericChoice[]): number | null {
    const experience = scale.qualifications.find((x: IPayScaleQualification) => x.name === YEARS_EXPERIENCE);

    if (!experience) return NEW_TO_CHILDCARE.key;

    const experiences = choices.filter((choice: GenericChoice) => choice.key <= parseInt(experience.value ?? '0'));

    return experiences.at(-1)?.key ?? NEW_TO_CHILDCARE.key;
}

export function singleChoice(
    scale: IManagedScale,
    qualification: Qualification,
    choices: GenericChoice[],
): number | null {
    const single = scale.qualifications.find((x: IPayScaleQualification) => x.qualification_id === qualification.id);

    if (!single) return null;

    return choices.find((choice: GenericChoice) => choice.key === single.option_id)?.key ?? null;
}

export function multiChoice(
    scale: IManagedScale,
    qualification: Qualification,
    choices: GenericChoice[],
): number[] | null {
    const multi = scale.qualifications.filter((x: IPayScaleQualification) => x.qualification_id === qualification.id);

    if (!multi) return [];

    const multiChoices = multi.map((x: IPayScaleQualification) => x.option_id);

    return choices
        .filter((choice: GenericChoice) => multiChoices.includes(choice.key))
        .map((x: GenericChoice) => x.key);
}

export function booleanChoice(scale: IManagedScale, choice: GenericChoice): boolean {
    const boolean = scale.qualifications.find(
        (x: IPayScaleQualification) => x.qualification_id === choice.pay_scale_qualification?.qualification_id,
    );

    return !!boolean;
}
