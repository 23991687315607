export const BOOL = 'BOOLEAN';
export const SINGLE = 'SINGLE_SELECT';
export const MULTI = 'MULTI_SELECT';

export const DEGREE = 'Degree';
export const ECE_DEGREE = 'ECE-Related Degree';
export const PLACEHOLDER_HIGH_SCHOOL_CHOICE = { key: -1, value: 'High School' };
export const CDA = 'Child Development Associate (CDA) Credential';
export const YEARS_EXPERIENCE = 'Years experience in a childcare center';
export const NEW_TO_CHILDCARE = { key: -1, value: 'New to Childcare (0-1 year)' };
export const NO_EDUCATION_REQUIRED_CHOICE = { key: -1, value: 'No Education Required' };

export const CLUMPED_YOE = [
    NEW_TO_CHILDCARE,
    { key: 1, value: 'Intermediate Experience (1-5 years)' },
    { key: 5, value: 'Very Experienced (5+ years)' },
];

export const OHIO = 'Ohio';
export const FLORIDA = 'Florida';
export const ILLINOIS = 'Illinois';

export const OHIO_MINIMUM_WAGE = 11;
export const FLORIDA_MINIMUM_WAGE = 13;
export const ILLINOIS_MINIMUM_WAGE = 15;

export const FLORIDA_45_HOURS_ID = 49;
export const FLORIDA_VPK_CREDENTIAL_ID = 51;
export const FLORIDA_STAFF_CREDENTIAL_ID = 52;
export const FLORIDA_DIRECTOR_CREDENTIAL_ID = 53;
export const FL_BOOLEAN_QUALIFICATION_PROBLEM_CHOICE_IDS = [
    FLORIDA_45_HOURS_ID,
    FLORIDA_VPK_CREDENTIAL_ID,
    FLORIDA_STAFF_CREDENTIAL_ID,
    FLORIDA_DIRECTOR_CREDENTIAL_ID,
];

// NOTE: first aid/cpr
export const IL_BOOLEAN_QUALIFICATION_PROBLEM_CHOICE_IDS = [84];

// NOTE: cpl level
export const OH_SINGLE_QUALIFICATION_PROBLEM_CHOICE_IDS = [4];
