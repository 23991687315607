import { Provider } from 'models';
import { useHistory } from 'react-router-dom';

export default function useGoToProfile() {
    const history = useHistory();
    function getProfilePath(id: number) {
        return `/provider/${id}`;
    }
    const goToProfile = (provider: Provider) => {
        history.push(getProfilePath(provider.user.id));
    };

    return { goToProfile, getProfilePath };
}
