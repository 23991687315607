import React from 'react';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';

import JobFormPage from '../JobFormPage';
import { useRequirementsAndPayContext } from './context';
import { ensureRatesExist } from './utils/manage';
import { RequirementsHeader } from './requirementComponents/RequirementsHeader';
import { RequirementsQuestions } from './requirementComponents/RequirementsQuestions';

interface PayPageProps {
    name: string;
}

export function Requirements({ name }: PayPageProps) {
    const { goToNext } = useJobFormContext();

    const { scales, replaceAllScales } = useRequirementsAndPayContext();

    function validateScales() {
        const newScales = ensureRatesExist(scales);
        replaceAllScales(newScales);
    }

    function onNext() {
        validateScales();
        goToNext('pay-page');
    }

    return (
        <JobFormPage id={name} onNextClicked={onNext} nextDisabled={false} nextText={'Next'}>
            <div style={{ width: '100%' }}>
                <RequirementsHeader />
                <RequirementsQuestions />
            </div>
        </JobFormPage>
    );
}
