import React, { createContext, useContext, useState } from 'react';
import { track } from 'shared';
import { JobFormPresets } from 'parent-portal/forms/JobFormPresets';

export const ModalsContext = createContext({
    businessJobFormOpen: false,

    openJobForm: (location: string, presets?: JobFormPresets, onClose?: () => void) => {},
    closeBusinessJobForm: (onClose?: () => void) => {},

    jobFormPresets: {},
});

export function ModalsContextProvider({ children }: { children?: React.ReactNode }) {
    const [businessJobFormOpen, setBusinessJobFormOpen] = useState(false);
    const [jobFormPresets, setJobFormPresets] = useState({});
    const [jobFormOnClose, setJobFormOnClose] = useState<() => void>();

    const [location, setLocation] = useState('');

    function openJobForm(location: string, presets?: JobFormPresets, onClose?: () => void) {
        track('Opened job form modal', { location: location });
        setLocation(location);
        if (onClose) setJobFormOnClose(() => onClose);
        const multipleProviders = true;
        presets && setJobFormPresets({ ...presets, multipleProviders });
        setBusinessJobFormOpen(true);
    }

    function closeBusinessJobForm() {
        track('Closed job form modal', { location: location, businessForm: true });
        setBusinessJobFormOpen(false);
        jobFormOnClose && jobFormOnClose();
        setLocation('');
        setJobFormPresets({});
        setJobFormOnClose(undefined);
    }

    const contextValue = {
        businessJobFormOpen,
        openJobForm,
        closeBusinessJobForm,
        jobFormPresets,
    };

    return <ModalsContext.Provider value={contextValue}>{children}</ModalsContext.Provider>;
}

export function useModalsContext() {
    return useContext(ModalsContext);
}
