import React from 'react';
import theme from 'theme';
import { DropDown } from 'library';
import { styled, useMediaQuery } from '@mui/material';

import { CredentialPill } from '../components/CredentialPill';
import { multiChoice, singleChoice, booleanChoice } from '../utils/choices';
import { updateScale } from '../utils/scale';
import { useRequirementsAndPayContext } from '../context';

import type { IManagedScale, GenericChoice, Qualification } from '../types';

export function AdditionalQualificationsSection({ scale }: { scale: IManagedScale }) {
    const small = useMediaQuery(theme.breakpoints.down('md'));

    const {
        qualifications: { single, multi, boolean },
        updateScales,
    } = useRequirementsAndPayContext();

    function update(
        value: number | number[],
        choices: GenericChoice[],
        qualification: Qualification | null,
        category: 'education' | 'experience' | 'multiple' | 'single' | 'boolean',
    ) {
        const newScale = updateScale(scale, value, choices, qualification, category);

        updateScales(newScale);
    }

    return (
        <div>
            <Section small={small.toString()}>
                {multi?.map((multi: { qualification: Qualification; choices: GenericChoice[] }) => (
                    <DropDownBox key={multi.qualification.id} small={small.toString()}>
                        <DropDown
                            title={multi.qualification.name}
                            name={multi.qualification.name}
                            selected={multiChoice(scale, multi.qualification, multi.choices)}
                            fields={multi.choices}
                            multiple={true}
                            onChange={({ target: { value } }: { target: { value: number[] } }) =>
                                update(value, multi.choices, multi.qualification, 'multiple')
                            }
                            MenuOverrideProps={{ getContentAnchorEl: () => null as unknown as Element }}
                        />
                    </DropDownBox>
                ))}
                {single?.map((single: { qualification: Qualification; choices: GenericChoice[] }) => (
                    <DropDownBox key={single.qualification.id} small={small.toString()}>
                        <DropDown
                            title={single.qualification.name}
                            name={single.qualification.name}
                            selected={singleChoice(scale, single.qualification, single.choices)}
                            fields={single.choices}
                            onChange={({ target: { value } }: { target: { value: number } }) =>
                                update(value, single.choices, single.qualification, 'single')
                            }
                            MenuOverrideProps={{ getContentAnchorEl: () => null as unknown as Element }}
                        />
                    </DropDownBox>
                ))}
            </Section>
            <Section small={small.toString()}>
                {boolean?.map((bool: GenericChoice) => (
                    <CredentialPill
                        key={bool.key}
                        label={bool.value}
                        tooHigh={false}
                        onClick={() => update(bool.key, [bool], null, 'boolean')}
                        selected={booleanChoice(scale, bool)}
                    />
                ))}
            </Section>
        </div>
    );
}

const DropDownBox = styled('div')(({ small }: { small?: string }) => ({
    width: small == 'true' ? '100%' : '40%',
    paddingRight: 20,
}));

const Section = styled('div')(({ small }: { small?: string }) => ({
    display: small === 'true' ? 'block' : 'flex',
    flexWrap: 'wrap',
}));
