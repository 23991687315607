import { EmploymentHistory } from 'models';
import { client } from 'shared';

export const getProvider = async (providerId: number | string, jobParam?: string) => {
    return await client(`api/providers/${providerId}/${jobParam}`);
};

export const getExperience = async (userId: number): Promise<EmploymentHistory[]> => {
    return await client(`api/employment-history?user=${userId}`);
};

export const getChildcareCenterInformation = async (providerId: number) => {
    return await client(`api/childcare-center-qualification/?provider=${providerId}`);
};

export const getJob = async (jobId: number | string) => {
    return await client(`api/ongoing/${jobId}`);
};

export const getProviderQualifications = async (providerUserId: number | string) => {
    return await client(`api/providers/${providerUserId}/qualifications-preview`);
};
