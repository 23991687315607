import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Text } from 'library';

const useStyles = (theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
});

class ProfileHeader extends Component {
    render() {
        const { primaryUser, classes } = this.props;

        return (
            <div style={{ marginTop: 30 }}>
                <Grid container justify="center">
                    <Grid
                        container
                        direction="row"
                        xs={12}
                        md={8}
                        lg={6}
                        spacing={2}
                        alignItems="center"
                        className={classes.root}
                    >
                        <Grid xs={12} md={12} lg={6} container direction="row" className={classes.root}>
                            <Grid
                                container
                                direction="column"
                                className={classes.root}
                                xs={12}
                                style={{ marginBottom: 5 }}
                            >
                                <Text variant="h1" bold>
                                    {primaryUser?.first_name} {primaryUser?.last_name}
                                </Text>
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                justify="flex-end"
                                xs={12}
                                style={{ marginBottom: 5, maxWidth: 200 }}
                            ></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

ProfileHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(ProfileHeader);
