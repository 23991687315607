import React from 'react';
import { Checkbox, Text } from 'library';
import { Grid } from '@material-ui/core';
import { BusinessFilterValues } from './BusinessLocationFilters';
import { formatMonthDay, getThisWeeksMonday } from 'shared/Dates';
import { addDays } from 'date-fns';

export default function BusinessLocationJobFilters({
    filters,
    setFilters,
}: {
    filters: BusinessFilterValues;
    setFilters: (vals: BusinessFilterValues) => void;
}) {
    const thisWeeksMonday = getThisWeeksMonday();
    const nextWeeksMonday = addDays(thisWeeksMonday, 7);
    const thisWeekLabel = `${formatMonthDay(thisWeeksMonday)} - ${formatMonthDay(addDays(thisWeeksMonday, 6))}`;
    const nextWeekLabel = `${formatMonthDay(nextWeeksMonday)} - ${formatMonthDay(addDays(nextWeeksMonday, 6))}`;

    return (
        <Grid>
            <Text bold>Filter centers with:</Text>
            <Checkbox
                onChange={(val) => setFilters({ open_jobs: val })}
                label="Open Jobs"
                checked={filters?.open_jobs || false}
            />
            <Checkbox
                onChange={(val) => setFilters({ jobs_posted: val })}
                label="Jobs Posted"
                checked={filters?.jobs_posted || false}
            />
            <Checkbox
                onChange={(val) => setFilters({ no_jobs_posted: val })}
                label="No Jobs Posted"
                checked={filters?.no_jobs_posted || false}
            />
            <Checkbox
                onChange={(val) => setFilters({ posted_never_transacted: val })}
                label="Posted but Never Transacted"
                checked={filters?.posted_never_transacted || false}
            />
            <Checkbox
                onChange={(val) => setFilters({ jobs_this_week: val })}
                label="Jobs This Week"
                checked={filters?.jobs_this_week || false}
                labelTitle={thisWeekLabel}
            />
            <Checkbox
                onChange={(val) => setFilters({ jobs_next_week: val })}
                label="Jobs Next Week"
                checked={filters?.jobs_next_week || false}
                labelTitle={nextWeekLabel}
            />
            <Checkbox
                onChange={(val) => setFilters({ none_this_week: val })}
                label="No Jobs This Week"
                checked={filters?.none_this_week || false}
                labelTitle={thisWeekLabel}
            />
            <Checkbox
                onChange={(val) => setFilters({ none_next_week: val })}
                label="No Jobs Next Week"
                checked={filters?.none_next_week || false}
                labelTitle={nextWeekLabel}
            />
            <Checkbox
                onChange={(val) => setFilters({ overdue_invoices: val })}
                label="Overdue Invoice"
                checked={filters?.overdue_invoices || false}
            />
            <Checkbox
                onChange={(val) => setFilters({ include_snoozed: val })}
                label="Include Hidden"
                checked={filters?.include_snoozed || false}
            />
        </Grid>
    );
}
