import {
    FormInformation,
    IComplianceRequirement,
    IRequirementOptions,
} from 'internal/texting/outreach/documents/models';
import React, { useEffect, useState } from 'react';
import { useClient } from 'shared';
import { DocumentToVerify, DocumentType, DocumentUploadOption, FormDetails } from './models';
import { LoadingSpinner, PrimaryButton } from 'library';
import { DOCUMENT_TYPE_REQUIREMENTS } from './DocTypeRequirements';
import { IInfoModalDoc } from 'models';
import { uploadAndVerifyForm, verifyForm } from 'api/AdminDocumentApi';
import GeneralDocumentVerify from 'internal/texting/outreach/documents/VerificationPages/GeneralDocumentVerify';

export default function VerifyModalContent({
    activeWorker,
    form,
    formDetails,
    onVerify,
    isFileUpload,
    selectedDocumentType,
}: {
    activeWorker: number | string;
    form: DocumentToVerify | IInfoModalDoc | undefined | File;
    formDetails?: FormDetails;
    onVerify: () => void;
    isFileUpload?: boolean;
    selectedDocumentType?: DocumentType | DocumentUploadOption;
}) {
    const [requirementOptions, setRequirementOptions] = useState<IRequirementOptions[]>([]);
    const [formInformation, setFormInformation] = useState<FormInformation>({});
    const [loading, setLoading] = useState(false);

    function updateFormInformation<K extends keyof FormInformation>(key: K, value: FormInformation[K]) {
        setFormInformation((prev) => ({ ...prev, [key]: value }));
    }

    const {
        data: complianceRequirements,
        send: fetchComplianceRequirements,
        loading: complianceRequirementsLoading,
    } = useClient<IComplianceRequirement[]>({
        url: `api/compliance-requirements/?user_id=${activeWorker}`,
        options: { sendImmediately: false },
    });

    useEffect(() => {
        fetchComplianceRequirements();
    }, []);

    useEffect(() => {
        if (complianceRequirements) {
            const newRequirementOptions = complianceRequirements
                .filter((req) => req.document_type === selectedDocumentType?.document_type_id)
                .map((req) => ({
                    requirement_type: req.requirement.name,
                    completed: false,
                    disabled: false,
                    alreadySatisfied: req.completed,
                    expirationDate: req.expiration_date,
                }));
            setRequirementOptions(newRequirementOptions);
        } else {
            setRequirementOptions([]);
        }
    }, [complianceRequirements, selectedDocumentType]);

    function updateRequirementOptions(key: string, value: boolean) {
        const newValues = requirementOptions.map((req) => {
            if (req.requirement_type === key) {
                return { ...req, completed: value };
            }
            return req;
        });
        setRequirementOptions(newValues);
    }

    function getFromDetailsToSubmit() {
        return {
            ...formDetails,
            document_type: selectedDocumentType?.document_type,
            ...Object.fromEntries(
                Object.entries(formInformation).map(([key, value]) => [
                    key,
                    value instanceof Date ? value.toISOString() : value,
                ]),
            ),
            satisfied_document_requirements: requirementOptions
                .filter((req) => req.completed && !req.disabled)
                .map((req) => req.requirement_type),
        };
    }

    const requiredFormInformation =
        selectedDocumentType?.document_type && selectedDocumentType.document_type in DOCUMENT_TYPE_REQUIREMENTS
            ? DOCUMENT_TYPE_REQUIREMENTS[selectedDocumentType.document_type as keyof typeof DOCUMENT_TYPE_REQUIREMENTS]
            : [];

    const hasAllRequiredFormInformation = requiredFormInformation.every(
        (req) => formInformation[req as keyof FormInformation],
    );

    const disabledVerify =
        !form ||
        !hasAllRequiredFormInformation ||
        (requirementOptions.length > 0 &&
            !requirementOptions.filter((req) => !req.disabled).some((req) => req.completed));

    if (complianceRequirementsLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <GeneralDocumentVerify
                documentType={selectedDocumentType?.document_type ?? ''}
                updateRequirementOptions={updateRequirementOptions}
                requirementOptions={requirementOptions}
                formInformation={formInformation}
                updateFormInformation={updateFormInformation}
            />
            <PrimaryButton
                buttonStyle={{ marginTop: 20, marginBottom: 20 }}
                disabled={disabledVerify}
                loading={loading}
                onClick={() => {
                    if (isFileUpload) {
                        onVerify();
                        uploadAndVerifyForm(
                            form as File,
                            activeWorker as number,
                            selectedDocumentType?.document_type_id ?? 0,
                            setLoading,
                            getFromDetailsToSubmit(),
                        );
                    } else {
                        const formToVerify = form as DocumentToVerify | IInfoModalDoc | undefined;
                        verifyForm(formToVerify?.id, formToVerify?.name, onVerify, getFromDetailsToSubmit());
                    }
                }}
            >
                {isFileUpload ? 'Upload' : 'Verify'}
            </PrimaryButton>
        </>
    );
}
