import React, { useEffect, useState } from 'react';
import { Checkbox, Colors, LoadingSpinner, OutlinedDropdown, Text } from 'library';
import { Grid, styled } from '@mui/material';
import { getMondayForThisWeek } from 'internal/substitutes/weekdayConstants';

import { useBusinessLocationJobs } from '../api';
import { formatDateFromDateObject } from 'shared/Dates';
import BusinessLocationJobTable from './BusinessLocationJobTable';
import { startOfWeek } from 'date-fns';

export default function BusinessLocationJobs({ businessLocationId }: { businessLocationId: number }) {
    const [includeClosed, setIncludeClosed] = useState(false);
    const { status, error, data } = useBusinessLocationJobs(businessLocationId, includeClosed);

    const weeks = data?.active_weeks?.map((it) => new Date(`${it}T00:00`));
    const monday = getMondayForThisWeek();
    const nextActiveWeek = weeks?.filter((it) => it >= monday)?.slice(-1)?.[0];
    const defaultWeek = nextActiveWeek ?? weeks?.[0];
    const startOfDefaultWeek = defaultWeek ? startOfWeek(defaultWeek, { weekStartsOn: 1 }) : undefined;

    const [selectedWeek, setSelectedWeek] = useState(startOfDefaultWeek);
    useEffect(() => {
        if (!selectedWeek) {
            setSelectedWeek(startOfDefaultWeek);
        }
    }, [startOfDefaultWeek, selectedWeek]);

    if (status === 'loading') return <LoadingSpinner />;
    if (status === 'error') return <Text>{error}</Text>;

    return (
        <Container container item gap={1}>
            {data?.business_location?.name ? <Text bold>Jobs for {data?.business_location?.name}</Text> : null}
            {data?.business_location?.has_posted_legacy_job ? (
                <Text bold textStyle={{ color: Colors.terraCotta }}>
                    Has Posted Legacy Job
                </Text>
            ) : null}
            <Checkbox
                label="Include closed"
                checked={includeClosed}
                onChange={(checked) => setIncludeClosed(checked)}
            />
            {selectedWeek && weeks?.length ? (
                <Grid container direction="column">
                    <WeekSelect container direction="row">
                        <Text variant="body2" bold textStyle={{ marginRight: 8 }}>
                            Week:
                        </Text>
                        <OutlinedDropdown
                            value={formatDateFromDateObject(selectedWeek)}
                            onChange={(e) => setSelectedWeek(new Date(e.target.value + 'T00:00:00'))}
                            fields={weeks.map((it) => ({
                                key: formatDateFromDateObject(it),
                                value: formatDateFromDateObject(it),
                            }))}
                        />
                    </WeekSelect>
                    <BusinessLocationJobTable
                        businessLocationId={businessLocationId}
                        week={selectedWeek}
                        includeClosed={includeClosed}
                    />
                </Grid>
            ) : (
                <Text>No jobs for this location.</Text>
            )}
        </Container>
    );
}

const Container = styled(Grid)({
    padding: 20,
});

const WeekSelect = styled(Grid)({
    alignItems: 'center',
    justifyContent: 'flex-end',
});
