import React, { useEffect, useState } from 'react';
import theme from 'theme';
import { client, consoleLogInDev } from 'shared';
import { Grid, useMediaQuery } from '@material-ui/core';
import TadaIcon from 'assets/icons/tandempay/tada-circle.svg';
import PageHeader from '../PageHeader';
import ModalPage from '../ModalPage';
import PageContent from '../PageContent';
import { Text } from 'library';
import PayMethod from '../../PayMethod';
import { IPayMethod } from 'models';

export default function SuccessPage() {
    const [payMethods, setPayMethods] = useState<IPayMethod[]>([]);
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        getPayments();
    }, []);

    const getPayments = async () => {
        try {
            const apiPayInfo = await client('api/get-payment-setup/');
            setPayMethods(apiPayInfo);
        } catch (error) {
            consoleLogInDev(error);
        }
    };

    return (
        <ModalPage>
            <PageHeader
                iconSource={<TadaIcon />}
                headerText="You're all set!"
                subtitleText="Your payment information has successfully been added to your profile."
            />
            <PageContent>
                {payMethods.map((payMethod) => (
                    <Grid key={payMethod.id} container style={{ marginLeft: small ? 12 : 42 }}>
                        <Text variant="h2" bold>
                            Payment Information
                        </Text>
                        <PayMethod payMethod={payMethod} />
                    </Grid>
                ))}
            </PageContent>
        </ModalPage>
    );
}
