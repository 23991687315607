import { AccessTimeFilled } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Colors } from 'library/theme';
import React from 'react';
import { newMuiTheme } from 'theme';
// eslint-disable-next-line import/named
import { TimePicker as MuiTimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment-timezone';

export default function TimePicker(props: TimePickerProps<moment.Moment>) {
    return (
        <ThemeProvider theme={newMuiTheme}>
            <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
                <MuiTimePicker
                    sx={{ width: '100%', '& .MuiInputLabel-root': { color: Colors.darkNavy } }}
                    slots={{ openPickerIcon: AccessTimeFilled }}
                    slotProps={{
                        textField: { variant: 'standard' },
                        openPickerButton: { color: 'primary' },
                    }}
                    {...props}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
}
