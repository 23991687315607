import React from 'react';
import { styled } from '@mui/material';

import { CredentialPill } from '../CredentialPill';

export function BaseCredentials() {
    return (
        <Row>
            <CredentialPill label="Level 2 Background Check" selected={true} />
            <CredentialPill label="45 Hours Started" selected={true} />
            <CredentialPill label="Affidavit of Good Moral Character" selected={true} />
            <CredentialPill label="Mandated Reporter Document" selected={true} />
        </Row>
    );
}

const Row = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
});
