import React from 'react';

import { uuid } from 'uuidv4';
import { Colors, Text } from 'library';
import { Add } from '@mui/icons-material';
import { styled } from '@mui/material';
import { client } from 'shared';

import { BasePayRow } from './BasePayRow';
import { PayRow } from './PayRow';
import { useRequirementsAndPayContext } from '../context';

import type { IManagedScale } from '../types';

export function PayBody({ editing }: { editing: boolean }) {
    const { scales, initialScale, qualifications, updateScales, replaceAllScales } = useRequirementsAndPayContext();

    function onClick() {
        const newScale = {
            id: uuid(),
            rate: (scales?.at(-1)?.rate ?? 14) + 1,
            description: `${(scales?.at(-1)?.rate ?? 14) + 1}`,
            qualifications: [],
        };
        updateScales(newScale);
    }

    function onDefaultChange() {
        client('api/pay-scale/recommended/').then((response: IManagedScale[]) => {
            replaceAllScales(response);
        });
    }

    return (
        <div>
            {!editing && <BasePayRow scale={initialScale} qualificationChoices={qualifications} />}
            {scales.map(
                (scale: IManagedScale) =>
                    (scale.id !== initialScale.id || editing) && (
                        <PayRow key={scale.id} scale={scale} editing={editing} />
                    ),
            )}
            <AddRow>
                <Add
                    style={{
                        backgroundColor: Colors.darkNavy,
                        borderRadius: 50,
                        color: Colors.white,
                        cursor: 'pointer',
                    }}
                    onClick={onClick}
                />
                <AddText>
                    <Text
                        textStyle={{ textDecorationLine: 'underline', paddingBottom: 10, cursor: 'pointer' }}
                        onClick={onClick}
                    >
                        Add another pay rate
                    </Text>
                    {scales?.length < 3 && (
                        <Text variant="body2" color={Colors.error}>
                            We suggest doing this as teachers who have more than the minimum requirements may not be
                            interested in the position.
                        </Text>
                    )}
                </AddText>
            </AddRow>
            <RecommendedBanner>
                <Text variant="body1" bold textStyle={{ display: 'flex', paddingBottom: 5 }}>
                    NEED HELP?{' '}
                    <Text
                        variant="body1"
                        bold
                        textStyle={{ paddingLeft: 5, textDecorationLine: 'underline', cursor: 'pointer' }}
                        onClick={onDefaultChange}
                    >
                        Use Tandem&apos;s recommended pay scale. Click here.
                    </Text>
                </Text>
                <Text variant="body2">This will overwrite your minimum requirements.</Text>
            </RecommendedBanner>
        </div>
    );
}

const AddRow = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '10px 0',
});

const AddText = styled('div')({
    paddingLeft: 10,
});

const RecommendedBanner = styled('div')({
    backgroundColor: Colors.lightGreen,
    border: `1px solid ${Colors.darkNavy}`,
    borderRadius: 10,
    marginTop: 20,
    padding: 15,
});
