import React, { useEffect, useState } from 'react';
import { FormInformation } from '../models';
import { Checkbox, Text } from 'library';
import { addYears, format, subYears } from 'date-fns';
import { DOCUMENT_TYPE_OPTIONS } from 'internal/document-verify/DocTypeRequirements';

export default function RequiredFormInfoForDocType({
    documentType,
    updateFormInformation,
    formInformation,
}: {
    documentType: string;
    formInformation: FormInformation;
    updateFormInformation: <K extends keyof FormInformation>(key: K, value: FormInformation[K]) => void;
}) {
    const [selectedDate, setSelectedDate] = useState<string>();
    const [selectedSignedDate, setSelectedSignedDate] = useState<string>();
    const stringifyDate = (date: Date) => format(date, 'yyyy-MM-dd');
    const threeYearsAgo = subYears(new Date(), 3);
    const twoYearsFromNow = addYears(new Date(), 2);
    const today = new Date();
    const requiredInputs = DOCUMENT_TYPE_OPTIONS[documentType as keyof typeof DOCUMENT_TYPE_OPTIONS] || [];

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDate(event.target.value);
        updateFormInformation('expiration_date', new Date(event.target.value));
    };

    const handleSignedDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedSignedDate(event.target.value);
        updateFormInformation('signed_at', new Date(event.target.value));
    };

    useEffect(() => {
        if (requiredInputs.includes('expiration_date')) {
            setSelectedDate(stringifyDate(twoYearsFromNow));
            updateFormInformation('expiration_date', twoYearsFromNow);
        }
        if (requiredInputs.includes('signed_at')) {
            setSelectedSignedDate(stringifyDate(today));
            updateFormInformation('signed_at', today);
        }
    }, [requiredInputs]);

    return (
        <>
            {requiredInputs.includes('is_sesame_document') && (
                <Checkbox
                    label="Is this a Sesame document?"
                    onChange={(e) => updateFormInformation('is_sesame_document', e)}
                    checked={formInformation.is_sesame_document ?? false}
                />
            )}

            {requiredInputs.includes('expiration_date') && (
                <>
                    <Text bold textStyle={{ margin: 15 }}>
                        Select Date Form Expires
                    </Text>
                    <input
                        type="date"
                        onChange={handleDateChange}
                        min={stringifyDate(today)}
                        value={selectedDate}
                        defaultValue={stringifyDate(twoYearsFromNow)}
                    />
                </>
            )}
            {requiredInputs.includes('signed_at') && (
                <>
                    <Text bold textStyle={{ margin: 15 }}>
                        Select Issue/Signed Date For Form
                    </Text>
                    <input
                        type="date"
                        onChange={handleSignedDateChange}
                        min={stringifyDate(threeYearsAgo)}
                        value={selectedSignedDate}
                        defaultValue={stringifyDate(today)}
                    />
                </>
            )}
        </>
    );
}
