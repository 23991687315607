import React, { useEffect, useState } from 'react';
import { makeStyles, Tab } from '@material-ui/core';
import { Colors } from 'library';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import SwipeableViews from 'react-swipeable-views';
import ProviderProfileBusinessSummaryTab from './businessSummary/ProviderProfileBusinessSummaryTab';
import ProviderProfileBusinessCredentialsTab from './businessCredentials/ProviderProfileBusinessCredentialsTab';
import ProviderProfileBusinessExperienceTab from './businessExperience/ProviderProfileBusinessExperienceTab';
import BusinessDocumentsTab from './documents/BusinessDocumentsTab';

const useStyles = makeStyles(() => ({
    tabs: {
        '&.MuiButtonBase-root.MuiTab-root': {
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'none',
            outline: 'none',
        },
        '&.MuiTab-textColorInherit': {
            color: Colors.darkNavy,
        },
        '&.Mui-selected': {
            color: Colors.turquoise,
        },
    },
    panels: {
        '&.MuiTabPanel-root': {
            padding: '20px 0px 20px 0px',
        },
    },
}));

export default function ProviderProfileTabs() {
    const [selectedTab, setSelectedTab] = useState('0');
    const classes = useStyles();

    const setTab = (_e: React.ChangeEvent<object>, value: string) => {
        setSelectedTab(value);
    };

    const tabs = [
        {
            label: 'Summary',
            component: <ProviderProfileBusinessSummaryTab />,
        },
        {
            label: 'Experience',
            component: <ProviderProfileBusinessExperienceTab />,
        },
        {
            label: 'Credentials',
            component: <ProviderProfileBusinessCredentialsTab />,
        },
        {
            label: 'Documents',
            component: <BusinessDocumentsTab />,
        },
    ];

    useEffect(() => {
        if (parseInt(selectedTab) >= tabs.length) setSelectedTab('0');
    }, [tabs]);

    return (
        <TabContext value={selectedTab}>
            <TabList
                onChange={setTab}
                variant="scrollable"
                allowScrollButtonsMobile
                textColor="inherit"
                TabIndicatorProps={{ style: { backgroundColor: Colors.turquoise, height: 3 } }}
            >
                {tabs.map((tab, index) => (
                    <Tab key={tab.label} label={tab.label} value={index.toString()} className={classes.tabs} />
                ))}
            </TabList>
            <SwipeableViews
                index={parseInt(selectedTab)}
                onChangeIndex={(index: number) => setSelectedTab(index.toString())}
            >
                {tabs.map((tab, index) => (
                    <TabPanel key={tab.label} value={index.toString()} className={classes.panels}>
                        {tab.component}
                    </TabPanel>
                ))}
            </SwipeableViews>
        </TabContext>
    );
}
