import React, { useState } from 'react';
import { Text, Colors, PrimaryButton } from 'library';
import { Grid } from '@material-ui/core';
import JobCardList from './JobCardList';
import { useUserContext } from 'UserContext';
import ConnectPaymentModal from 'parent-portal/payments/Components/ConnectPaymentModal';
import { useWidth } from 'reusableComponents/useWidth';

const UpcomingJobsPage = () => {
    const [paymentSetupModalOpen, setPaymentSetupModalOpen] = useState(false);
    const { user, role } = useUserContext();
    const width = useWidth();
    const smallScreen = ['xs', 'sm'].includes(width);

    if (role !== 'business_active') return null;

    const openSetupModal = () => {
        setPaymentSetupModalOpen(true);
    };

    const paymentSetupNeeded =
        user?.family.length &&
        ((!!user?.payment_information && user?.payment_information.length <= 0) || user?.customer_id === null);

    return (
        <Grid container justify="center" style={{ paddingTop: 30, paddingBottom: 30, overflow: 'scroll', flex: 1 }}>
            <Grid xs={11}>
                <Grid container direction="row">
                    <Text variant="display">Jobs</Text>
                    {paymentSetupNeeded ? (
                        <>
                            <PrimaryButton
                                buttonStyle={{
                                    backgroundColor: Colors.turquoise,
                                    width: 'fit-content',
                                    marginLeft: smallScreen ? 0 : 10,
                                    marginTop: smallScreen ? 10 : 0,
                                }}
                                onClick={openSetupModal}
                            >
                                Set up TandemPay
                            </PrimaryButton>
                            <ConnectPaymentModal
                                isOpen={paymentSetupModalOpen}
                                onClose={() => setPaymentSetupModalOpen(false)}
                                fromRoute="DashboardUpcomingJobsButton"
                            />
                        </>
                    ) : null}
                </Grid>
            </Grid>
            <Grid xs={11} md={11} style={{ marginTop: 10 }} justify="flex-start">
                <JobCardList />
            </Grid>
        </Grid>
    );
};

export default UpcomingJobsPage;
