import React, { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';
import { Colors, LoadingIndicator, PrimaryButton, SecondaryButton, Text, TextArea } from 'library';
import { useCategories } from '../hooks';
import { NoShowData } from './noShowModels';

interface IRevertNoShowFormProps {
    revertId?: number;
    revertNoShowData: NoShowData;
    setRevertNoShowData: (val: NoShowData) => void;
    onSubmit: () => void;
    error?: string;
}

export default function RevertNoShowForm({
    revertId,
    revertNoShowData,
    setRevertNoShowData,
    onSubmit,
    error,
}: IRevertNoShowFormProps) {
    const [page, setPage] = useState(1);
    const { data: categoriesData, loading: categoriesLoading } = useCategories();

    useEffect(() => {
        setPage(1);
    }, [revertId]);

    if (categoriesLoading || !categoriesData) {
        return <LoadingIndicator />;
    }

    function onNext() {
        setPage(2);
    }

    const selectedCategory = categoriesData?.categories?.find(
        (category) => category.name === revertNoShowData?.category,
    );
    const commentError = selectedCategory?.require_comment && !revertNoShowData?.comment;

    function pageOne() {
        return (
            <StyledForm>
                <Text>Select a no show revert reason</Text>
                <RadioGroup
                    name="Select a no show revert reason"
                    value={revertNoShowData?.category}
                    onChange={(e) =>
                        setRevertNoShowData({
                            ...revertNoShowData,
                            category: e.target.value,
                        })
                    }
                    style={{ flexDirection: 'column' }}
                >
                    {categoriesData?.categories?.map((category: { name: string; value: string }) => (
                        <FormControlLabel
                            key={category.name}
                            value={category.name}
                            control={
                                <Radio
                                    checked={revertNoShowData?.category === category.name}
                                    color="primary"
                                    disableRipple
                                    disableFocusRipple
                                />
                            }
                            label={<Text variant="body2">{category.value.capitalize()}</Text>}
                        />
                    ))}
                </RadioGroup>
                <Text>Comment</Text>
                <TextArea
                    onChange={(e) =>
                        setRevertNoShowData({
                            ...revertNoShowData,
                            comment: e.target.value,
                        })
                    }
                    value={revertNoShowData?.comment}
                    error={commentError}
                />
                <StyledButtonContainer>
                    <PrimaryButton
                        onClick={onNext}
                        buttonStyle={{
                            width: 'fit-content',
                        }}
                        disabled={commentError || !revertNoShowData?.category}
                    >
                        Next
                    </PrimaryButton>
                </StyledButtonContainer>
            </StyledForm>
        );
    }

    function pageTwo() {
        return (
            <StyledForm>
                <Text>Select additional follow up action:</Text>
                <RadioGroup
                    name="Select a no show revert reason"
                    value={revertNoShowData?.category}
                    onChange={(e) =>
                        setRevertNoShowData({
                            ...revertNoShowData,
                            additional_action: e.target.value,
                        })
                    }
                    style={{ flexDirection: 'column' }}
                >
                    {categoriesData?.additional_actions.map((category) => (
                        <FormControlLabel
                            key={category.name}
                            value={category.name}
                            control={
                                <Radio
                                    checked={revertNoShowData?.additional_action === category.name}
                                    color="primary"
                                    disableRipple
                                    disableFocusRipple
                                />
                            }
                            label={<Text variant="body2">{category.value.capitalize()}</Text>}
                        />
                    ))}
                </RadioGroup>
                <Text variant="caption" textStyle={{ paddingTop: 5 }}>
                    Note: User will automatically get their points back once you revert the no show
                </Text>
                <StyledButtonContainer>
                    <SecondaryButton
                        buttonStyle={{
                            width: 'fit-content',
                            marginRight: 15,
                        }}
                        onClick={() => setPage(1)}
                    >
                        Back
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={onSubmit}
                        buttonStyle={{
                            width: 'fit-content',
                        }}
                        disabled={commentError || !revertNoShowData?.additional_action}
                    >
                        Submit
                    </PrimaryButton>
                </StyledButtonContainer>
                <Text variant="caption" textStyle={{ color: Colors.error, paddingTop: 5 }}>
                    {error}
                </Text>
            </StyledForm>
        );
    }

    function getContent() {
        if (page === 1) {
            return pageOne();
        }

        return pageTwo();
    }

    return (
        <div>
            <StyledForm>{getContent()}</StyledForm>
        </div>
    );
}

const StyledForm = styled('div')({
    backgroundColor: Colors.white,
    borderRadius: 8,
    marginTop: 10,
    padding: 10,
});

const StyledButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 10,
});
