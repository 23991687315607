import { isEqual } from 'lodash';

import { booleanProblem as ohioBooleanProblem, singleProblem as ohioSingleProblem } from '../components/ohio/utils';
import { booleanProblem as illinoisBooleanProblem } from '../components/illinois/utils';
import { booleanProblem as floridaBooleanProblem } from '../components/florida/utils';

import {
    DEGREE,
    ECE_DEGREE,
    NO_EDUCATION_REQUIRED_CHOICE,
    NEW_TO_CHILDCARE,
    FLORIDA,
    ILLINOIS,
    OHIO,
    FLORIDA_MINIMUM_WAGE,
    ILLINOIS_MINIMUM_WAGE,
    OHIO_MINIMUM_WAGE,
} from '../constants';

import type {
    GenericChoice,
    GenericQualificationChoices,
    IManagedScale,
    IPayScaleQualification,
    Pills,
    Problems,
    Qualification,
} from '../types';

export function checkForDuplicates(scales: IManagedScale[]): { [key: string]: boolean } {
    const qualificationsMap: { [key: string]: object } = {};

    scales.forEach((x: IManagedScale) => {
        const qualificationMap = {} as { [key: string]: number | string | null };
        x.qualifications.map((y: IPayScaleQualification) => {
            qualificationMap[`${y.qualification_id}`] = y.option_id ?? y.value;
        });
        qualificationsMap[`${x.id}`] = qualificationMap;
    });

    const duplicateMap: { [key: string]: boolean } = {};

    scales.forEach((x: IManagedScale, index: number) => {
        const qualificationMap = qualificationsMap[`${x.id}`];

        scales.slice(index + 1).forEach((y: IManagedScale) => {
            const otherQualificationMap = qualificationsMap[`${y.id}`];

            if (isEqual(qualificationMap, otherQualificationMap)) {
                duplicateMap[`${x.id}`] = true;
                duplicateMap[`${y.id}`] = true;
            }
        });
    });

    return duplicateMap;
}

function rateProblem(scale: IManagedScale, state: string): 'toolow' | 'bad' | null {
    if (isNaN(scale.rate)) {
        return 'bad';
    }

    if (state === FLORIDA && scale.rate < FLORIDA_MINIMUM_WAGE) {
        return 'toolow';
    }

    if (state === ILLINOIS && scale.rate < ILLINOIS_MINIMUM_WAGE) {
        return 'toolow';
    }

    if (state === OHIO && scale.rate < OHIO_MINIMUM_WAGE) {
        return 'toolow';
    }

    return null;
}

export function baseEducationProblem(scale: IManagedScale): boolean {
    const education = scale.qualifications.find((x: IPayScaleQualification) => [DEGREE, ECE_DEGREE].includes(x.name));
    return ![-1, 0, 22, null].includes(education?.option_id ?? null);
}

export function findProblems(
    scale: IManagedScale,
    state: string,
    initialScale: boolean = false,
    initialScaleId: string = '',
): Problems {
    const problems = {
        education: false,
        experience: false,
        single: [],
        multi: [],
        boolean: {},
        rate: null,
        noQualifications: false,
        duplicate: false,
    } as Problems;

    problems.education = initialScale ? baseEducationProblem(scale) : false;
    problems.experience = false;
    problems.noQualifications = !initialScale && scale.id !== initialScaleId && scale.qualifications.length < 1;

    const booleans = scale.qualifications.filter(
        (x: IPayScaleQualification) => !x.option_id && x.value?.toString() === 'true',
    );

    switch (state) {
        case OHIO:
            problems.single = initialScale ? ohioSingleProblem(scale.qualifications) : [];
            problems.multi = {};
            problems.boolean = initialScale ? ohioBooleanProblem(booleans) : {};
            problems.rate = rateProblem(scale, state);
            break;
        case FLORIDA:
            problems.single = {};
            problems.multi = {};
            problems.boolean = initialScale ? floridaBooleanProblem(booleans) : {};
            problems.rate = rateProblem(scale, state);
            break;
        case ILLINOIS:
            problems.single = {};
            problems.multi = {};
            problems.boolean = initialScale ? illinoisBooleanProblem(booleans) : {};
            problems.rate = rateProblem(scale, state);
            break;
        default:
            problems.single = {};
            problems.multi = {};
            problems.boolean = {};
            problems.rate = null;
    }

    return problems;
}

export function updateProblems(
    scale: IManagedScale,
    problems: { [key: string]: Problems },
    setProblems: (problems: { [key: string]: Problems }) => void,
    state: string,
): void {
    const newProblems = findProblems(scale, state);

    setProblems({ ...problems, [scale.id]: newProblems });
}

export function gatherDisplayPills(scale: IManagedScale, allChoices: GenericQualificationChoices): Pills {
    const pills: Pills = {
        education: null,
        experience: null,
        boolean: [],
        single: [],
        multi: [],
    };

    allChoices.education.forEach((choice: GenericChoice) => {
        const education = scale.qualifications.find((x: IPayScaleQualification) => x.option_id === choice.key);

        if (education) {
            pills.education = choice;
        }
    });

    pills.education = pills.education ?? NO_EDUCATION_REQUIRED_CHOICE;

    allChoices.experience.forEach((choice: GenericChoice) => {
        const experience = scale.qualifications.find((x: IPayScaleQualification) => x.option_id === choice.key);

        if (experience) {
            pills.experience = choice;
        }
    });

    pills.experience = pills.experience ?? NEW_TO_CHILDCARE;

    allChoices.boolean.forEach((choice: GenericChoice) => {
        const boolean = scale.qualifications.find((x: IPayScaleQualification) => x.qualification_id === choice.key);

        if (boolean) {
            pills.boolean.push(choice);
        }
    });

    allChoices.single.forEach((choice: { qualification: Qualification; choices: GenericChoice[] }) => {
        const single = scale.qualifications.find(
            (x: IPayScaleQualification) => x.qualification_id === choice.qualification.id,
        );

        if (single && choice?.qualification?.id) {
            const option = choice.choices.find((x: GenericChoice) => x.key === single.option_id);

            if (option) {
                pills.single.push({ qualification: choice.qualification, choice: option });
            }
        }
    });

    allChoices.multi.forEach((choice: { qualification: Qualification; choices: GenericChoice[] }) => {
        const multi = scale.qualifications.filter(
            (x: IPayScaleQualification) => x.qualification_id === choice.qualification.id,
        );

        if (multi) {
            const options = choice.choices.filter((x: GenericChoice) =>
                multi.some((y: IPayScaleQualification) => y.option_id === x.key),
            );

            if (options.length) {
                pills.multi.push({ qualification: choice.qualification, choices: options });
            }
        }
    });

    return pills;
}

export function minumumWageForState(state: string): number {
    switch (state) {
        case FLORIDA:
            return FLORIDA_MINIMUM_WAGE;
        case ILLINOIS:
            return ILLINOIS_MINIMUM_WAGE;
        case OHIO:
            return OHIO_MINIMUM_WAGE;
        default:
            return 0;
    }
}
