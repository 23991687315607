import React from 'react';
import { Text } from 'library';
import { styled } from '@mui/material';

import { CredentialPill } from '../CredentialPill';
import { ExplanatoryCredentialPill } from '../ExplanatoryCredentialPill';

export function BaseCredentials() {
    return (
        <Row>
            <CredentialPill label="Cleared Fingerprints" selected={true} />
            <CredentialPill label="3 Character References" selected={true} />
            <CredentialPill label="Verified HS Education" selected={true} />
            <CredentialPill label="Medical Form" selected={true} />
            <CredentialPill label="Gateways Registry Card" selected={true} />
            <ExplanatoryCredentialPill
                label="Other Trainings According to DCFS Timeline"
                title={
                    <Hover>
                        <Text variant="caption" bold>
                            Day 30
                        </Text>
                        <Text variant="caption">- SBS</Text>
                        <Text variant="caption">- Sids</Text>
                        <Text variant="caption">- Mandated Reporter Training and Acknowledgement</Text>
                        <Text variant="caption" bold>
                            Optional
                        </Text>
                        <Text variant="caption">- Food Handlers</Text>
                        <Text variant="caption">- First Aid/CPR</Text>
                    </Hover>
                }
            />
        </Row>
    );
}

const Row = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
});

const Hover = styled('div')({
    padding: 5,
});
