import { FL_BOOLEAN_QUALIFICATION_PROBLEM_CHOICE_IDS } from '../../constants';

import type { IPayScaleQualification } from 'parent-portal/forms/JobRequestTypes';

export function booleanProblem(payScaleQualifications: IPayScaleQualification[]) {
    const problems = {} as { [key: string]: boolean };

    payScaleQualifications.forEach((qualification: IPayScaleQualification) => {
        if (FL_BOOLEAN_QUALIFICATION_PROBLEM_CHOICE_IDS.includes(qualification.qualification_id)) {
            problems[`${qualification.qualification_id}`] = true;
        }
    });

    return problems;
}
