import React from 'react';
import { Colors, Text } from 'library';
import { styled } from '@mui/material';

import { ErrorBox } from './ErrorBox';
import { BaseCredentials as OhioBaseCredentials } from '../components/ohio/BaseCredentials';
import { BaseCredentials as FloridaBaseCredentials } from '../components/florida/BaseCredentials';
import { BaseCredentials as IllinoisBaseCredentials } from '../components/illinois/BaseCredentials';
import { useRequirementsAndPayContext } from '../context';

import { FLORIDA, ILLINOIS, OHIO } from '../constants';

export function RequirementsHeader() {
    const { state } = useRequirementsAndPayContext();

    const BaseCredentials = (() => {
        switch (state) {
            case OHIO:
                return <OhioBaseCredentials />;
            case FLORIDA:
                return <FloridaBaseCredentials />;
            case ILLINOIS:
                return <IllinoisBaseCredentials />;
            default:
                return (
                    <ErrorBox
                        text="Sorry, we are having a hard time detecting your state. Please go back and ensure an address/location is
                                    selected."
                        style={{ marginTop: 10 }}
                    />
                );
        }
    })();

    return (
        <>
            <Text variant="display" textStyle={{ paddingLeft: 20, paddingRight: 20 }}>
                What are the <u>base requirements</u> for the position?
            </Text>
            <InnerSection>
                <Text bold>All Tandem teachers have:</Text>
                {BaseCredentials}
            </InnerSection>
        </>
    );
}

const InnerSection = styled('div')({
    backgroundColor: Colors.lightTurq,
    marginTop: 10,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 15,
});
