import React, { useState } from 'react';
import { Colors, PrimaryButton, Text } from 'library';
import { Grid } from '@material-ui/core';
import { useModalsContext } from 'ModalsContext';
import moment from 'moment';
import { isBefore, isToday } from 'date-fns';
import { useFeatureFlag, FeatureFlag } from 'shared';

import { AutoHireWorkerDialog } from './AutoHireWorker/AutoHireWorkerDialog';

export default function PostAShiftCard({
    d,
    addressId,
    businessLocationId,
    timezone,
}: {
    d: Date;
    addressId?: number;
    businessLocationId?: number;
    timezone?: string;
}) {
    const { openJobForm } = useModalsContext();
    const { featureActive: active } = useFeatureFlag(FeatureFlag.AUTOSCHEDULE_WORKER);
    const [open, setOpen] = useState(false);

    if (isBefore(d, new Date()) && !isToday(d)) {
        return <></>;
    }

    return (
        <Grid
            item
            key={d.toLocaleDateString() + 'job'}
            container
            direction="column"
            style={{ padding: '20px 20px 0px 20px' }}
            alignContent="center"
        >
            <Grid
                item
                container
                direction="column"
                style={{
                    borderRadius: 18,
                    backgroundColor: Colors.lightTurq,
                    padding: 10,
                    boxShadow: '0px 3px 6px 0px #00000029',
                }}
            >
                <div>
                    <Text variant="body1" textStyle={{ marginBottom: 20 }}>
                        Post a shift for {d.toLocaleDateString()}.
                    </Text>
                    <PrimaryButton
                        buttonStyle={{ width: 'unset' }}
                        onClick={() =>
                            openJobForm(`Post a job card (Business Calendar)`, {
                                startDate: moment(d),
                                endDate: moment(d),
                                address: addressId ? [{ id: addressId, businessLocationId, timezone }] : undefined,
                            })
                        }
                    >
                        Post Shift
                    </PrimaryButton>
                </div>
                {active && (
                    <div>
                        <Text variant="body2" textStyle={{ marginTop: 30 }} bold>
                            OR
                        </Text>
                        <Text variant="body1" textStyle={{ marginBottom: 20 }}>
                            Automatically add a previous worker to the schedule.
                            <Text variant="body1" textStyle={{ textDecorationLine: 'underline' }} bold>
                                They must have already agreed to the shift.
                            </Text>
                        </Text>
                        <PrimaryButton buttonStyle={{ width: 'unset' }} onClick={() => setOpen(true)}>
                            Add Worker
                        </PrimaryButton>
                        <AutoHireWorkerDialog open={open} setOpen={setOpen} addressId={addressId} defaultDay={d} />
                    </div>
                )}
            </Grid>
        </Grid>
    );
}
