import React from 'react';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';

import EditDialog from './EditDialog';
import { InnerPay } from '../../RequirementsAndPayPage/payComponents/InnerPay';
import { useRequirementsAndPayContext } from '../../RequirementsAndPayPage/context';

export default function EditPayScalesModal({ open, onClose }: { open: boolean; onClose: () => void }) {
    const { form } = useJobFormContext();

    const { disabled, onClick } = useRequirementsAndPayContext();

    return (
        <EditDialog
            title={`Edit ${form.fieldStates.headline.value} Pay`}
            open={open}
            onClose={() => onClick(() => onClose())}
            isValid={!disabled}
        >
            <InnerPay editing={true} />
        </EditDialog>
    );
}
