import { Paper, Text, Colors, TextProps } from 'library';
import React from 'react';

export default function FamilyLetter() {
    function P(props: TextProps) {
        return <Text textStyle={{ marginBottom: 10 }} {...props} />;
    }

    return (
        <Paper style={{ marginBottom: 30, backgroundColor: Colors.lightTurq }}>
            <P variant="h2" bold>
                Dear Tandem Family,
            </P>
            <P>
                We want to thank you for the opportunity to serve you and your household over the last 3+ years. It has
                been an honor to be entrusted with helping you find care for those you love best, and we are so proud of
                the many relationships between care providers and families that have flourished as a result of
                connections made on Tandem.
            </P>
            <P>
                As of January 1, 2025, Tandem has fully transitioned to supporting childcare centers in their staffing
                needs. We will no longer be supporting families in finding care through our platform or in paying
                providers with whom they have worked in the past.
            </P>
            <P>
                We are excited to be focused on an area where help is so desperately needed, and we thank you for being
                a part of our journey. We wish you all the best in your future care needs.
            </P>
            <P>With gratitude,</P>
            <P variant="display" textStyle={{ fontSize: 18 }}>
                The Tandem Team
            </P>
        </Paper>
    );
}
