import React from 'react';
import theme from 'theme';
import moment from 'moment';
import useForm from 'parent-portal/forms/components/FormHook';
import useLoading from 'library/hooks/useLoading';
import { Checkbox, PrimaryButton, SizeableRoundedDialog, Text } from 'library';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
import { Grid, useMediaQuery } from '@mui/material';

import JobTimePicker from '../../../../forms/components/pages/DateAndTimePage/JobTimePicker';
import { createCenterInitiatedAppointmentTimeChangeRequest, updateBusinessAppointment } from './businessAppointmentApi';

import type { IShift } from 'models';
import type { ILeanShift } from '../../BusinessJob/models';
import type { JobTimes } from '../../../../forms/JobRequestTypes';

export default function EditModal({
    open,
    onClose,
    shift,
    providerName,
    refresh,
    timezone,
}: {
    open: boolean;
    onClose: () => void;
    shift: IShift | ILeanShift;
    providerName?: string;
    refresh: () => void;
    timezone: string;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const { loading, setLoading } = useLoading();
    const form = useForm<{
        jobTimes: JobTimes;
        removeProvider: boolean;
    }>({
        jobTimes: { start: moment(shift.start_date), end: moment(shift.end_date), slots: 1, timezone },
        removeProvider: false,
    });

    const { jobTimes, removeProvider } = form.fieldStates;

    function submit() {
        setLoading(true);
        const originalStart = moment(shift.start_date);
        const originalEnd = moment(shift.end_date);

        const newStart = jobTimes.value.start;
        const newEnd = jobTimes.value.end;

        if (originalStart.isSame(newStart) && originalEnd.isSame(newEnd)) {
            setLoading(false);
            onClose();
        } else if (
            !providerName ||
            (newStart?.isSameOrAfter(originalStart) &&
                newEnd?.isSameOrBefore(originalEnd) &&
                newStart?.isBefore(originalEnd) &&
                newEnd?.isAfter(originalStart))
        ) {
            updateBusinessAppointment(shift.id, {
                start_date: form.fieldStates.jobTimes.value.start?.toISOString(),
                end_date: form.fieldStates.jobTimes.value.end?.toISOString(),
            })
                .then(refresh)
                .finally(() => {
                    setLoading(false);
                    onClose();
                });
        } else {
            createCenterInitiatedAppointmentTimeChangeRequest({
                appointmentId: shift.id,
                startTime: jobTimes.value.start,
                endTime: jobTimes.value.end,
                onDecline: removeProvider.value ? 'remove_provider' : 'keep_provider',
            })
                .then(refresh)
                .finally(() => {
                    setLoading(false);
                    onClose();
                });
        }
    }

    const mainText = providerName
        ? [
              'Any updates to shift times inside the originally accepted time block will be automatically accepted.',
              'If you need to change the shift times outside of the originally accepted time block,',
              `we will inform ${providerName} of updates to the shift times.`,
              'If there are any issues with this reschedule, we will update you as soon as possible.',
          ].join(' ')
        : [
              'We will attempt to fill this shift with the updated times.',
              'If you requested to book multiple providers for this shift, all unfilled shifts will be updated.',
          ].join(' ');

    const disclaimerText = providerName
        ? [
              `Note: if ${providerName} does not respond by the shift start time,`,
              `the times will be kept as the original shift times and ${providerName} will remain on the shift.`,
          ].join(' ')
        : false;

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                <Text variant="h1">Need to update the times for {formatShortWeekdayMonthDay(shift.start_date)}?</Text>
                <Text>{mainText}</Text>
                {disclaimerText && <Text variant="caption">{disclaimerText}</Text>}
                <JobTimePicker
                    jobTimes={form.fieldStates.jobTimes}
                    startDate={moment(shift.start_date)}
                    allowPastMidnight={false}
                />
                {!!providerName && (
                    <Checkbox
                        label={`Remove ${providerName} if they cannot work the new times.`}
                        checked={removeProvider.value}
                        onChange={() => {
                            removeProvider.setValue(!removeProvider.value);
                        }}
                    />
                )}
                <PrimaryButton
                    disabled={loading}
                    onClick={submit}
                    buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                >
                    Submit
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
