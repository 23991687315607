import React, { useState } from 'react';
import theme from 'theme';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/react';
import useLoading from 'library/hooks/useLoading';
import { updateHourReport } from './api';
import { useUserContext } from 'UserContext';
import { Grid, useMediaQuery } from '@mui/material';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
import { PrimaryButton, Text, SizeableRoundedDialog, TimePicker, ErrorText } from 'library';
import { clockIn, breakStart, breakEnd, breakText, createReport, buildRequestBody } from './AdjustTimesHelpers';
import { AdjustTimesModalProps, IBuildRequestBodyParams } from './types';

interface PartialTimes {
    clockTimes: IBuildRequestBodyParams['clockTimes'];
    breakTimes: IBuildRequestBodyParams['breakTimes'];
}

export default function AdjustTimesModal({
    open,
    onClose,
    provider,
    shift,
    hourReport,
    providerId,
    refresh,
    timezone,
}: AdjustTimesModalProps) {
    const { user } = useUserContext();
    const { loading, setLoading } = useLoading();
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    const defaultClockIn = moment(clockIn(hourReport) || shift.start_date);

    const [errorMessage, setErrorMessage] = useState('');

    const [allTimes, setAllTimes] = useState<PartialTimes>({
        clockTimes: {
            provider_clock_in: defaultClockIn,
            provider_clock_out: null,
            business_clock_in: defaultClockIn,
            business_clock_out: null,
        },
        breakTimes: {
            provider_break_start: breakStart(hourReport),
            provider_break_end: breakEnd(hourReport),
            business_break_start: breakStart(hourReport),
            business_break_end: breakEnd(hourReport),
        },
    });

    const validateAndSet = (times: PartialTimes) => {
        setErrorMessage('');
        if (
            times.breakTimes.provider_break_end &&
            moment(times.breakTimes.provider_break_end).isBefore(times.breakTimes.provider_break_start)
        ) {
            setErrorMessage('Break end is before break start.');
        }
        if (
            times.breakTimes.provider_break_end &&
            moment(times.breakTimes.provider_break_end).isAfter(times.clockTimes.provider_clock_out)
        ) {
            setErrorMessage('Break end is after clock out.');
        }
        if (
            times.breakTimes.provider_break_start &&
            moment(times.breakTimes.provider_break_start).isBefore(times.clockTimes.provider_clock_in)
        ) {
            setErrorMessage('Break start is before clock in.');
        }
        if (
            times.clockTimes.provider_clock_in &&
            moment(times.clockTimes.provider_clock_in).isAfter(times.clockTimes.provider_clock_out)
        ) {
            setErrorMessage('Clock in is after clock out.');
        }
        if (
            times.clockTimes.provider_clock_out &&
            moment(times.clockTimes.provider_clock_out).isBefore(times.breakTimes.provider_break_end)
        ) {
            setErrorMessage('Clock out is before break end.');
        }
        setAllTimes(times);
    };

    const submitChangeRequest = async () => {
        setLoading(true);

        try {
            const body = buildRequestBody({
                providerId,
                shiftId: shift.id,
                businessId: user?.businesses[0]?.id,
                breakTimes: allTimes.breakTimes,
                clockTimes: allTimes.clockTimes,
            });
            hourReport ? await updateHourReport(hourReport.id, body) : await createReport(body);
            refresh();
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                <Text variant="h1">
                    Change times for {provider?.first_name} {provider?.last_name}&apos;s hours for{' '}
                    {formatShortWeekdayMonthDay(shift.start_date)}?
                </Text>
                <TimePicker
                    value={allTimes.clockTimes.provider_clock_in}
                    sx={{ maxWidth: '45%' }}
                    timezone={timezone}
                    onChange={(date) => {
                        validateAndSet({
                            breakTimes: allTimes.breakTimes,
                            clockTimes: {
                                ...allTimes.clockTimes,
                                provider_clock_in: date,
                                business_clock_in: date,
                            },
                        });
                    }}
                    label="Start Time"
                />
                <TimePicker
                    value={allTimes.clockTimes.provider_clock_out}
                    sx={{ maxWidth: '45%' }}
                    timezone={timezone}
                    onChange={(date) => {
                        validateAndSet({
                            breakTimes: allTimes.breakTimes,
                            clockTimes: {
                                ...allTimes.clockTimes,
                                provider_clock_out: date,
                                business_clock_out: date,
                            },
                        });
                    }}
                    label="End Time"
                />
                <Text variant="body2">{breakText(hourReport)}</Text>
                <TimePicker
                    value={allTimes.breakTimes.provider_break_start}
                    sx={{ maxWidth: '45%' }}
                    timezone={timezone}
                    onChange={(date) => {
                        validateAndSet({
                            breakTimes: {
                                ...allTimes.breakTimes,
                                provider_break_start: date,
                                business_break_start: date,
                            },
                            clockTimes: allTimes.clockTimes,
                        });
                    }}
                    label="Break Start"
                />
                <TimePicker
                    value={allTimes.breakTimes.provider_break_end}
                    sx={{ maxWidth: '45%' }}
                    timezone={timezone}
                    onChange={(date) => {
                        validateAndSet({
                            clockTimes: allTimes.clockTimes,
                            breakTimes: {
                                ...allTimes.breakTimes,
                                provider_break_end: date,
                                business_break_end: date,
                            },
                        });
                    }}
                    label="Break End"
                />
                <ErrorText>{errorMessage}</ErrorText>
                <PrimaryButton
                    disabled={loading || !!errorMessage}
                    onClick={submitChangeRequest}
                    buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                >
                    Submit
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
