export const DOCUMENT_TYPE_REQUIREMENTS = {
    mandated_reporter_certificate_document: ['signed_at'],
    medical_statement_document: ['signed_at'],
    first_aid_cpr_documents: ['expiration_date'],
    cda_document: ['expiration_date'],
    ctec_document: ['expiration_date'],
    nac_document: ['expiration_date'],
    ccp_document: ['expiration_date'],
    food_handlers_document: ['expiration_date'],
    fccpc_document: ['expiration_date'],
    ccac_document: ['expiration_date'],
    ecpc_document: ['expiration_date'],
    necc_document: ['expiration_date'],
    director_credential_document: ['expiration_date'],
    staff_credential_document: ['expiration_date'],
    // NOTE: References are not enforced in compliance but are tracked by admins
    character_reference_0_document: ['expiration_date'],
    character_reference_1_document: ['expiration_date'],
    character_reference_2_document: ['expiration_date'],
};

export const DOCUMENT_TYPE_OPTIONS = {
    ...DOCUMENT_TYPE_REQUIREMENTS,
    medical_statement_document: ['signed_at', 'is_sesame_document'],
};
