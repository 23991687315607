import React from 'react';
import theme from 'theme';
import { sortBy } from 'lodash';
import { Colors, DropDown, LoadingSpinner, Text } from 'library';
import { styled, useMediaQuery } from '@mui/material';
import { ReportProblemRounded } from '@mui/icons-material';

import { DCFQuestion } from '../components/florida/DCFQuestion';
import { CredentialPill } from '../components/CredentialPill';
import { educationChoice, experienceChoice, singleChoice, multiChoice, booleanChoice } from '../utils/choices';
import { updateScale } from '../utils/scale';
import { findProblems } from '../utils/problems';
import { useRequirementsAndPayContext } from '../context';
import { FLORIDA_45_HOURS_ID } from '../constants';

import type { GenericChoice, Qualification } from '../types';

export function RequirementsQuestions() {
    const small = useMediaQuery(theme.breakpoints.down('md'));

    const { qualifications, state, loading, initialScale, updateScales } = useRequirementsAndPayContext();
    const { education, experience, multi, single, boolean } = qualifications;
    const problems = findProblems(initialScale, state, true);

    const anyProblem =
        problems?.education ||
        problems?.experience ||
        Object.values(problems?.boolean).includes(true) ||
        Object.values(problems?.single).includes(true) ||
        Object.values(problems?.multi).includes(true);

    function update(
        value: number | number[],
        choices: GenericChoice[],
        qualification: Qualification | null,
        category: 'education' | 'experience' | 'multiple' | 'single' | 'boolean',
    ) {
        if (!initialScale) return;

        const updatedScale = updateScale(initialScale, value, choices, qualification, category);
        updateScales(updatedScale);
    }

    const flCrendential = boolean?.find((bool: GenericChoice) => bool.key === FLORIDA_45_HOURS_ID);

    if (loading) return <LoadingSpinner />;

    if (!initialScale) return <Text>There was an error loading the requirements.</Text>;

    return (
        <div>
            <Question error={problems?.education?.toString()}>
                <div>
                    <Text bold>
                        What is the <u>mininum level of education</u> required to work this position?
                    </Text>
                    <DropDown
                        selected={educationChoice(initialScale, education)}
                        fields={sortBy(education, 'order')}
                        onChange={({ target: { value } }: { target: { value: number } }) =>
                            update(value, education, null, 'education')
                        }
                        style={{ width: small ? '100%' : '60%' }}
                        MenuOverrideProps={{ getContentAnchorEl: () => null as unknown as Element }}
                    />
                </div>
                {problems?.education && <ReportProblemRounded sx={{ height: 36, width: 36, color: Colors.error }} />}
            </Question>
            <Question>
                <div>
                    <Text bold>
                        What is the <u>mininum level of experience</u> required to work this position?
                    </Text>
                    <DropDown
                        selected={experienceChoice(initialScale, experience)}
                        fields={experience}
                        onChange={({ target: { value } }: { target: { value: number } }) =>
                            update(value, experience, null, 'experience')
                        }
                        style={{ width: small ? '100%' : '60%' }}
                        MenuOverrideProps={{ getContentAnchorEl: () => null as unknown as Element }}
                    />
                </div>
            </Question>
            {!!flCrendential && (
                <DCFQuestion
                    onChange={() => update(flCrendential.key, [flCrendential], null, 'boolean')}
                    selected={booleanChoice(initialScale, flCrendential)}
                />
            )}
            <Question>
                <div>
                    <Text bold> Any other credentials required? Select all that apply.</Text>
                    <Text variant="caption" textStyle={{ marginBottom: 10 }}>
                        Note: If you prefer a credential, but you&apos;re flexible, do not click it.
                    </Text>
                    <Section small={small.toString()}>
                        {multi?.map((multi: { qualification: Qualification; choices: GenericChoice[] }) => (
                            <DropDownBox key={multi.qualification.id} small={small.toString()}>
                                <DropDown
                                    title={multi.qualification.name}
                                    name={multi.qualification.name}
                                    selected={multiChoice(initialScale, multi.qualification, multi.choices)}
                                    fields={multi.choices}
                                    multiple={true}
                                    onChange={({ target: { value } }: { target: { value: number[] } }) =>
                                        update(value, multi.choices, multi.qualification, 'multiple')
                                    }
                                    MenuOverrideProps={{ getContentAnchorEl: () => null as unknown as Element }}
                                />
                            </DropDownBox>
                        ))}
                        {single?.map((single: { qualification: Qualification; choices: GenericChoice[] }) => (
                            <DropDownBox key={single.qualification.id} small={small.toString()}>
                                <DropDown
                                    title={single.qualification.name}
                                    name={single.qualification.name}
                                    selected={singleChoice(initialScale, single.qualification, single.choices)}
                                    fields={single.choices}
                                    onChange={({ target: { value } }: { target: { value: number } }) =>
                                        update(value, single.choices, single.qualification, 'single')
                                    }
                                    MenuOverrideProps={{ getContentAnchorEl: () => null as unknown as Element }}
                                />
                            </DropDownBox>
                        ))}
                    </Section>
                    <Section small={small.toString()}>
                        {boolean?.map((bool: GenericChoice) =>
                            bool.key === FLORIDA_45_HOURS_ID ? null : (
                                <CredentialPill
                                    key={bool.key}
                                    label={bool.value}
                                    tooHigh={problems?.boolean[`${bool.key}`] ?? false}
                                    onClick={() => update(bool.key, [bool], null, 'boolean')}
                                    selected={booleanChoice(initialScale, bool)}
                                />
                            ),
                        )}
                    </Section>
                </div>
            </Question>
            {anyProblem && (
                <Text
                    color={Colors.error}
                    bold
                    variant="body2"
                    textStyle={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}
                >
                    The requirements selected will significantly limit the worker pool available. Consider lowering the
                    requirements before continuing.
                </Text>
            )}
        </div>
    );
}

const Question = styled('div')(({ error }: { error?: string }) => ({
    alignItems: 'center',
    backgroundColor: error === 'true' ? '#fff5f5' : Colors.white,
    borderRadius: 10,
    border: error === 'true' ? `1px solid ${Colors.error}` : 'none',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    padding: '5px 20px',
}));

const DropDownBox = styled('div')(({ small }: { small?: string }) => ({
    width: small == 'true' ? '100%' : '40%',
    paddingRight: 20,
}));

const Section = styled('div')(({ small }: { small?: string }) => ({
    display: small === 'true' ? 'block' : 'flex',
    flexWrap: 'wrap',
}));
