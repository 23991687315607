import { BusinessSlot } from 'models';
import { format12HourTime } from 'shared/Dates';

export function getShiftTimes(slot: BusinessSlot) {
    const shift = slot.appointments.length ? slot.appointments[0] : undefined;
    const slotStart = new Date(slot.start_date);
    const slotEnd = new Date(slot.end_date);
    const shiftStart = shift ? new Date(shift.start_date) : slotStart;
    const shiftEnd = shift ? new Date(shift.end_date) : slotEnd;
    const timezone = slot.ongoing_request.timezone;
    const showTimesChanged = shiftStart.getTime() !== slotStart.getTime() || shiftEnd.getTime() !== slotEnd.getTime();
    const infoText = showTimesChanged
        ? `This shift was originally scheduled from ${format12HourTime(slotStart, timezone)} - ${format12HourTime(slotEnd, timezone)}.`
        : undefined;

    return {
        shiftStart,
        shiftEnd,
        infoText,
    };
}
