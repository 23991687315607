import React from 'react';
import { Text, SmallIconButton } from 'library';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import { ArrowBack } from '@material-ui/icons';
import { useWidth } from 'reusableComponents/useWidth';

export interface IProvider {
    user: {
        id: number;
    };
}

interface IProps {
    providerIdObject: IProvider;
    providerName: string;
}

export const ProviderInfo = ({ providerIdObject, providerName }: IProps) => {
    const history = useHistory();
    const breakpoint = useWidth();
    const smallScreen = ['xs', 'sm'].includes(breakpoint);

    return (
        <Grid container xs={6} alignItems="center" direction="row" style={{ alignSelf: 'flex-start' }}>
            {smallScreen ? <SmallIconButton shadow onClick={() => history.push('/messages')} icon={ArrowBack} /> : null}
            <Text
                variant="h2"
                bold
                textStyle={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => history.push(`/provider/${providerIdObject.user.id}`)}
            >
                {providerName}
            </Text>
        </Grid>
    );
};
